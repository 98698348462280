import React, { useState, useEffect } from 'react';
import WrapperHistorial from './WrapperHistorial';
import { actualizarEstadoTarea, descargarTareas, mostrarError, formatearFecha, nuevaTarea, sleepear, formatearFechaPHP, eliminarTarea, mostrarOK, actualizarTarea } from '../../../librerias/funciones';
import FullModal from '../../../componentes/FullModal/FullModal';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';
import { df_NuevaTarea, df_EditarTarea } from '../../../librerias/plantillasforms';
import ConfirmarBorrar from './ConfirmarBorrar';

const Titulo = (_props) => {
    return (
        <span style={{ verticalAlign: "middle", color: "rgb(167, 167, 167)", fontSize: _props.mini ? "0.75em" : "0.85em", marginRight: "0.5em" }}>{_props.texto}</span>
    );
}

const Texto = (_props) => {
    return (
        <span style={{ fontWeight: _props.bold ? 500 : 400, verticalAlign: "middle", color: "rgb(61, 62, 59)", fontSize: _props.mini ? "0.85em" : "1em", fontFamily: _props.swap ? "unset" : "Montserrat" }}>{_props.texto}</span>
    );
}

const Descripcion = (_props) => {
    return (
        <span>
            {_props.titulo == "" ? null : <Titulo estado={_props.estado} swap={_props.swap} mini={_props.mini} texto={_props.titulo} />}
            <Texto estado={_props.estado} bold={_props.bold} swap={_props.swap} mini={_props.mini} texto={_props.descripcion} />
        </span>
    );
}

const colorEstado = (_Estado) => {
    if (_Estado) return "rgb(26, 188, 156)";
    else return "rgb(112, 112, 112)";
}

const EntradaTarea = _props => {

    const [estado, setEstado] = useState(_props.estado);
    const [borrar, setBorrar] = useState(false);
    const [subiendo, setSubiendo] = useState(false)

    const toglear = () => {
        const nuevoEstado = !estado;
        setSubiendo(true);
        setEstado(nuevoEstado)
        _props.editarEstado(_props.id, nuevoEstado)
    }

    const editarTarea = () => {
        _props.abrirEditarTarea({
            id: _props.id,
            estado: _props.estado,
            tarea: _props.tarea,
            desde: _props.desde,
            hasta: _props.hasta,
            descripcion: _props.descripcion
        })
    }

    const toglearBorrar = () => {
        setBorrar(true)
    }

    const borrarTarea = (_Borrar) => {
        if (_Borrar) {
            _props.borrar(_props.id)
        }
        else {
            setBorrar(false)
        }
    }

    return (
        <div className="row microSombra" style={{ borderLeft: `0.5em solid ${colorEstado(estado)}`, marginLeft: 0, marginRight: 0, marginTop: "1.3em", marginBottom: "1.3em", width: "100%", backgroundColor: "white", borderRadius: "0.5em", padding: "1em" }}>
            {!borrar ?
                <>
                    {
                        subiendo ?
                            <div style={{ padding: 0 }} className="col s12">
                                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                                    <div className="spinear btn-flat" style={{ width: "fit-content", height: "fit-content", padding: 0, cursor: "unset", display: "flex", justifyContent: "center", alignItems: "center" }} >
                                        <ion-icon style={{ color: 'rgb(162, 162, 162)', fontSize: "1.4em" }} name="sync-outline" />
                                    </div>
                                </div>
                            </div>
                            :
                            <div style={{ padding: 0 }} className="col s12">
                                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <div style={{ padding: 0 }} className="col s12">
                                        <div style={{ padding: 0 }} className="col s12">
                                            <Descripcion titulo="" descripcion={_props.tarea} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div onClick={toglearBorrar} className="noHighLightWrapper botonFlatWrap">
                                            <ion-icon className="noHighLight" style={{ marginLeft: "0.5em", marginRight: "0.5em", cursor: "pointer", color: 'rgb(243, 91, 38)', fontSize: "1.5em" }} name="trash-outline" />
                                        </div>
                                        <div onClick={editarTarea} className="noHighLightWrapper botonFlatWrap">
                                            <ion-icon className="noHighLight" style={{ marginRight: "0.5em", cursor: "pointer", color: 'rgb(0, 153, 242)', fontSize: "1.5em" }} name="create-outline" />
                                        </div>
                                        <div className="noHighLightWrapper">
                                            {estado ?
                                                <ion-icon className="noHighLight" onClick={toglear} style={{ position: "relative", top: "0.06em", cursor: "pointer", color: 'rgb(26, 188, 156)', fontSize: "1.5em" }} name="checkmark-circle-outline" />
                                                :
                                                <ion-icon className="noHighLight" onClick={toglear} style={{ position: "relative", top: "0.06em", cursor: "pointer", color: 'rgb(162, 162, 162)', fontSize: "1.5em" }} name="ellipse-outline" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                    <div style={{ padding: 0 }} className="col s12">
                        <Descripcion mini titulo="Descripción: " descripcion={_props.descripcion} />
                    </div>
                    <div style={{ padding: 0 }} className="col s12 m6">
                        <Descripcion mini titulo="Desde: " descripcion={_props.desde == null ? "N/D" : formatearFecha(_props.desde)} />
                    </div>
                    <div style={{ padding: 0 }} className="col s12 m6">
                        <Descripcion mini titulo="Hasta: " descripcion={_props.hasta == null ? "N/D" : formatearFecha(_props.hasta)} />
                    </div>
                </>
                :
                <ConfirmarBorrar titulo="Borrar Tarea" descripcion="¿Seguro que desea borrar la tarea? Esta acción no se puede deshacer." onClick={borrarTarea} />
            }
        </div>
    );
}

const HistorialTareas = (props) => {

    const [cargando, setCargando] = useState(true);
    const [tareas, setTareas] = useState([]);

    // Modal
    const [estadoModal, setEstadoModal] = useState(false);
    const [editable, setEditable] = useState(true);
    const [titulo, setTitulo] = useState("Agregar");
    const [botonOK, setBotonOK] = useState("Agregar");
    const [plantillaForm, setPlantillaForm] = useState([]);
    const [actualizandoDatos, setActualizandoDatos] = useState(false);

    const abrirModal = () => {
        setEstadoModal(true);
    }

    const cerrarModal = () => {
        setEstadoModal(false);
        setActualizandoDatos(false)
        setPlantillaForm([])
    }

    const agregarTarea = async (_Datos) => {
        const form = new FormData();
        form.append("Tarea", _Datos.tarea);
        form.append("Descripcion", _Datos.descripcion);
        form.append("Inicio", formatearFechaPHP(_Datos.desde));
        form.append("Fin", formatearFechaPHP(_Datos.hasta));

        let datos;

        while (true) {
            datos = await nuevaTarea(form);
            if (datos != null) {
                if (!datos.Error) break;
                else {
                    mostrarError(datos.Mensaje);
                    await sleepear(15000);
                }
            }
            else {
                await sleepear(5000);
            }
        }

        const nuevasTareas = [datos.Mensaje, ...tareas];
        setTareas(nuevasTareas);

        cerrarModal();
        mostrarOK("Agregado con éxito");
    }

    const editarTarea = async (_Datos) => {
        const form = new FormData();
        form.append("Tarea", _Datos.tarea);
        form.append("Descripcion", _Datos.descripcion);
        form.append("Inicio", formatearFechaPHP(_Datos.desde));
        form.append("Fin", formatearFechaPHP(_Datos.hasta));
        form.append("Estado", _Datos.estado);

        let datos;

        while (true) {
            datos = await actualizarTarea(form, _Datos.id);
            if (datos != null) {
                if (!datos.Error) break;
                else {
                    mostrarError(datos.Mensaje);
                    await sleepear(15000);
                }
            }
            else {
                await sleepear(5000);
            }
        }

        const nuevasTareas = [...tareas];
        for (let i = 0; i < nuevasTareas.length; i++) {
            if (nuevasTareas[i].IDTarea == _Datos.id) {
                nuevasTareas[i] = datos.Mensaje;
                nuevasTareas[i].Estado = parseInt(nuevasTareas[i].Estado)
                break;
            }
        }

        setTareas(nuevasTareas);

        cerrarModal();
        mostrarOK("Actualizado con éxito");
    }

    const editarEstado = async (_ID, _Estado) => {
        const form = new FormData();
        form.append("Estado", _Estado);

        let datos;

        while (true) {
            datos = await actualizarEstadoTarea(form, _ID);
            if (datos != null) {
                if (!datos.Error) break;
                else {
                    mostrarError(datos.Mensaje);
                    await sleepear(15000);
                }
            }
            else {
                await sleepear(5000);
            }
        }

        const nuevasTareas = [...tareas];
        for (let i = 0; i < nuevasTareas.length; i++) {
            if (nuevasTareas[i].IDTarea == _ID) {
                nuevasTareas[i] = datos.Mensaje;
                nuevasTareas[i].Estado = parseInt(nuevasTareas[i].Estado)
                break;
            }
        }

        setTareas(nuevasTareas);

        cerrarModal();
        // mostrarOK("Actualizado con éxito");
    }

    const pressOK = (_Datos) => {
        if (_Datos.tarea == "" || _Datos.descripcion == "") {
            mostrarError("No deben existir campos vacíos");
            return;
        }

        setActualizandoDatos(true)

        switch (_Datos._modo) {
            case "Nueva Tarea":
                agregarTarea(_Datos);
                break;
            case "Editar Tarea":
                editarTarea(_Datos);
                break;
            default:
                setActualizandoDatos(false);
                break;
        }
    }

    const pressCancel = () => {
        cerrarModal();
    }
    // Fin Modal

    const abrirAgregarTarea = () => {
        setPlantillaForm(df_NuevaTarea());
        setTitulo("Nueva Tarea");
        setBotonOK("Agregar")
        setEditable(true);
        abrirModal();
    }

    const abrirEditarTarea = (_Datos) => {
        setPlantillaForm(df_EditarTarea(_Datos));
        setTitulo("Editar Tarea");
        setBotonOK("Guardar")
        setEditable(true);
        abrirModal();
    }

    const borrarTarea = async (_ID) => {
        let datos;

        while (true) {
            datos = await eliminarTarea(_ID);
            if (datos != null) {
                if (!datos.Error) break;
                else {
                    mostrarError(datos.Mensaje);
                    await sleepear(15000);
                }
            }
            else {
                await sleepear(5000);
            }
        }

        const nuevasTareas = [...tareas];
        for (let i = 0; i < nuevasTareas.length; i++) {
            if (nuevasTareas[i].IDTarea == _ID) {
                nuevasTareas.splice(i, 1);
                break;
            }
        }

        setTareas(nuevasTareas);
        mostrarOK("Eliminado con éxito");

    }

    const cargarTareas = async () => {

        let datos;

        while (true) {
            datos = await descargarTareas();
            if (datos != null) {
                if (!datos.Error) break;
                else {
                    mostrarError(datos.Mensaje);
                    await sleepear(15000);
                }
            }
            else {
                await sleepear(5000);
            }
        }

        if (datos.error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setCargando(false);
        setTareas(datos.Mensaje.reverse())
    }

    useEffect(() => {
        cargarTareas();
    }, [])

    const ListarTareas = () => {
        return tareas.map((_Tarea, _Key) => {
            return (
                <EntradaTarea
                    key={_Tarea.IDTarea}
                    id={_Tarea.IDTarea}
                    desde={_Tarea.Inicio}
                    hasta={_Tarea.Fin}
                    tarea={_Tarea.Tarea}
                    descripcion={_Tarea.Descripcion}
                    estado={_Tarea.Estado}
                    abrirEditarTarea={abrirEditarTarea}
                    borrar={borrarTarea}
                    editarEstado={editarEstado}
                />
            );
        })
    }

    return (
        <WrapperHistorial cargando={cargando} boton="Nueva" onClick={abrirAgregarTarea}>
            <ListarTareas />

            <FullModal
                abierto={estadoModal}
                cerrar={cerrarModal}>
                <div className="" style={{ backgroundColor: "white", borderRadius: "0.5em" }}>
                    <DynamicForm
                        titulo={titulo}
                        editable={editable}
                        actualizando={actualizandoDatos}
                        botonOK=
                        {
                            {
                                titulo: botonOK,
                                press: pressOK
                            }
                        }

                        botonCancel={pressCancel}
                        secciones=
                        {plantillaForm}
                    />
                </div>
            </FullModal>
        </WrapperHistorial>
    );
}

export default HistorialTareas;