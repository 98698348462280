import React from 'react';

const CircleLoader = ({ tamaño, color }) => {
    return (
        <div className="preloader-wrapper active" style={{ width: tamaño, height: tamaño }}>
            <div className="spinner-layer" style={{ borderColor: color }}>
                <div className="circle-clipper left">
                    <div className="circle"></div>
                </div><div className="gap-patch">
                    <div className="circle"></div>
                </div><div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
            </div>
        </div>
    );
}

export default CircleLoader;