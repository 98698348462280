import React, { useState } from 'react';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';
import FullModal from '../../../componentes/FullModal/FullModal';
import CardEvaluacion from './CardEvaluacion';
import { df_EditarEvaluacion, df_NuevoBloque } from '../../../librerias/plantillasforms'
import { mostrarError, propiedadVacia, sleepear, mostrarOK, insertDatos, deleteDatos, updateDatos, permitirVer } from '../../../librerias/funciones';
import ConfirmarBorrar from '../../../componentes/ConfirmarBorrar/ConfirmarBorrar';
import Evaluaciones from './Evaluaciones';
import { DropIcono, DropOpcion } from '../../../componentes/DropIcono.js/DropIcono';
import { useRef } from 'reactn';
import ModalAsignacion from './Asignacion/ModalAsignacion';

const EntradaBloque = ({ bloque, borrar, editar, aptitudes, contenedor, asignar, rol }) => {

    const [vistaBorrar, setVistaBorrar] = useState(false);

    const borrarBloque = (_Borrar) => {
        if (_Borrar) {
            borrar(bloque.IDFormulario, () => {
                setVistaBorrar(!vistaBorrar)
            })
        }
        else setVistaBorrar(!vistaBorrar)
    }

    return (
        <div style={{ width: "100%", borderBottom: "1px solid rgb(218, 220, 224)" }}>
            {vistaBorrar ?
                <div className="row" style={{ margin: 0, paddingBottom: "1em" }}>
                    <ConfirmarBorrar titulo="Borrar Evaluación" descripcion="¿Seguro desea borrar la evaluación? Esta acción no se puede deshacer" onClick={borrarBloque} />
                </div>
                :
                <div style={{ paddingTop: "0.5em", paddingBottom: "0.5em", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ width: "1em", height: "1em", borderRadius: "100%", backgroundColor: bloque.Estado ? "#16a085" : "#c4c5c5", marginRight: "0.5em" }}></div>
                    <p style={{ flex: 1, margin: 0, fontWeight: "400", fontSize: "1em" }}>
                        {bloque.Descripcion}</p>
                    <DropIcono contenedor={contenedor} tipo="bloque-evaluacion" id={bloque.IDFormulario}>
                        <DropOpcion opcion={"Asignación"} onClick={() => { asignar(bloque) }} />
                        {
                            permitirVer(rol, ["Administrador", "Coordinador de programa"]) &&
                            <>
                                <DropOpcion opcion={"Aptitudes"} onClick={() => { aptitudes(bloque) }} />
                                <DropOpcion opcion={"Editar"} onClick={() => { editar(bloque) }} />
                                <DropOpcion opcion={"Eliminar"} onClick={() => { setVistaBorrar(!vistaBorrar) }} />
                            </>
                        }
                    </DropIcono>
                </div>}
        </div>
    );
}

const ListaBloques = ({ bloques, borrar, editar, aptitudes, contenedor, asignar, rol }) => {
    return (
        bloques.map((_Bloque, _Key) => {
            return <EntradaBloque
                key={_Bloque.IDFormulario}
                bloque={_Bloque}
                borrar={borrar}
                editar={editar}
                aptitudes={aptitudes}
                contenedor={contenedor}
                asignar={asignar}
                rol={rol}
            />
        })
    );
}

const VistaFormBloque = ({ titulo, editable, actualizando, boton, onok, oncancel, plantilla }) => {
    return (
        <div style={{ backgroundColor: "white", borderRadius: "0.5em" }}>
            <DynamicForm
                titulo={titulo}
                editable={editable}
                actualizando={actualizando}
                botonOK={{ titulo: boton, press: onok }}
                botonCancel={oncancel}
                secciones={plantilla}
            />
        </div>
    )
}

const BloqueEvaluaciones = ({ bloques, agregar, borrar, editar, agregarevaluacion, borrarevaluacion, editarevaluacion, rol }) => {

    const [estadoModal, setEstadoModal] = useState(false)
    const [plantilla, setPlantilla] = useState([]);
    const [titulo, setTitulo] = useState("");
    const [editable, setEditable] = useState(false);
    const [actualizando, setActualizando] = useState(false);
    const [boton, setBoton] = useState("");
    const [vista, setVista] = useState("")
    const [bloque, setBloque] = useState(null)

    const abrirAgregarBloque = () => {
        setPlantilla(df_NuevoBloque("Evaluación", "evaluacion"));
        setBoton("Agregar");
        setEditable(true);
        setTitulo("Agregar Evaluación");
        setVista("Form Bloque");
        setEstadoModal(true);
    }

    const abrirEditarBloque = async (_Bloque) => {
        setPlantilla(df_EditarEvaluacion(_Bloque.IDFormulario, _Bloque.Descripcion, _Bloque.Estado));
        setBoton("Guardar");
        setEditable(true);
        setTitulo("Editar Evaluación");
        setVista("Form Bloque");
        setEstadoModal(true);
    }

    const abrirAptitudes = (_Bloque) => {
        setBloque(_Bloque)
        setVista('Evaluaciones')
        setEstadoModal(true)
    }

    const abrirAsignacion = (_Bloque) => {
        setBloque(_Bloque)
        setVista('Asignaciones')
        setEstadoModal(true)
    }

    const agregarBloque = async (_Datos) => {

        if (propiedadVacia(_Datos, ['_modo'])) return;

        setActualizando(true)

        const form = new FormData();
        form.append('Descripcion', _Datos.evaluacion)

        let datos;

        while (true) {
            datos = await insertDatos(form, '/form');
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        mostrarOK("Agregada nueva evaluación")
        agregar(datos.Mensaje)
        cerrarModal();
    }

    const editarBloque = async (_Datos) => {

        if (propiedadVacia(_Datos, ['_modo', 'activo'])) return;

        setActualizando(true)

        const form = new FormData();
        form.append('Descripcion', _Datos.descripcion)
        form.append('Estado', _Datos.activo)

        let datos;

        while (true) {
            datos = await updateDatos(form, _Datos.id, '/form');
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        mostrarOK("Evaluación actualizada")
        editar(datos.Mensaje)
        cerrarModal();
    }

    const borrarBloque = async (_ID, _Cancelar) => {

        let datos;

        while (true) {
            datos = await deleteDatos(_ID, '/form');
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            _Cancelar()
            return;
        }

        mostrarOK("Bloque eliminado")
        borrar(_ID)
        cerrarModal();
    }

    const cerrarModal = () => {
        setEstadoModal(false);
        setPlantilla([]);
    }

    const onOK = (_Datos) => {
        switch (_Datos._modo) {
            case "Agregar Evaluación":
                agregarBloque(_Datos)
                break;
            case "Editar Evaluación":
                editarBloque(_Datos)
                break;
            default:
                break;
        }
    }

    const onCancel = () => {
        cerrarModal();
    }

    const SelectorVista = ({ vista }) => {
        switch (vista) {
            case "Form Bloque":
                return (
                    <VistaFormBloque
                        titulo={titulo}
                        editable={editable}
                        actualizando={actualizando}
                        boton={boton}
                        onok={onOK}
                        oncancel={onCancel}
                        plantilla={plantilla} />
                )
            case 'Evaluaciones':
                return (
                    <Evaluaciones evaluacion={bloque} />
                )
            case "Asignaciones":
                return (
                    <ModalAsignacion bloque={bloque} />
                );
            default:
                return null;
        }
    }

    const contenedor = useRef();

    return (
        <>

            <CardEvaluacion rol={rol} contenedor={contenedor} titulo="Evaluaciones" boton="Agregar" onclick={abrirAgregarBloque}>
                {bloques.length == 0 ?
                    <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <ion-icon style={{ color: 'rgb(117, 117, 117)', fontSize: "2.5em" }} name="alert-circle-outline" />
                        <p style={{ color: "rgb(117, 117, 117)", fontFamily: "Montserrat", fontSize: "1.2em" }}>Aún no tiene bloques de evaluación</p>
                    </div>
                    :
                    <ListaBloques
                        bloques={bloques}
                        borrar={borrarBloque}
                        editar={abrirEditarBloque}
                        aptitudes={abrirAptitudes}
                        contenedor={contenedor}
                        asignar={abrirAsignacion}
                        rol={rol}
                    />}
            </CardEvaluacion>

            {/* Modal Usuario */}
            <FullModal
                abierto={estadoModal}
                cerrar={() => {
                    setVista(null)
                    setEstadoModal(false);
                    setPlantilla([]);
                }}
            >
                <SelectorVista vista={vista} />
            </FullModal>
        </>
    );
}

export default BloqueEvaluaciones;