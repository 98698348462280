import React, { useState, useEffect } from 'react';
import BarraIconos from '../../../componentes/BarraIconos.js/BarraIconos';
import ConfirmarBorrar from '../../../componentes/ConfirmarBorrar/ConfirmarBorrar';
import Detalle from '../../../componentes/DynamicForm/Componentes/Detalle';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';
import MiniLoaderWrap from '../../../componentes/MiniLoaderWrap/MiniLoaderWrap';
import Separador from '../../../componentes/Separador/Separador';
import { deleteDatos, formatearFecha, getDatos, getProyectosAdminUser, insertDatos, mostrarError, mostrarOK, permitirVer, propiedadVacia, sleepear, updateDatos } from '../../../librerias/funciones';

const CampoTexto = ({ campo, texto }) => {
    return (
        <span >
            <span style={{ fontSize: "0.75em", marginRight: "0.5em", color: "rgb(167,167,167)" }}>{campo}: </span>
            <span style={{ fontSize: "0.85em", marginRight: "0.5em", color: "rgb(61,62,59)" }}>{texto} </span>
        </span>
    )
}

const EntradaProyecto = ({ rol, proyecto, borrar, editar, listaproyectos }) => {

    const [vista, setVista] = useState("Detalles")
    const [actualizando, setActualizando] = useState(false)

    const color = proyecto.Estado ? "#1abc9c" : "rgb(112, 112, 112)";

    const funcionBorrar = (_Borrar) => {
        if (_Borrar) {
            borrar(proyecto, () => { setVista("Detalles") })
        }
        else setVista("Detalles")
    }

    const funcionEditar = (_Datos) => {
        editar(proyecto, _Datos, (_Resultado) => {
            setActualizando(false)
            if (_Resultado) {
                setVista("Detalles")
            }
        })
    }

    const SelectorVista = () => {
        switch (vista) {
            case "Detalles":
                return (
                    <div style={{ paddingTop: "1em", paddingBottom: "1em", width: "100%", borderLeft: `0.5em solid ${color}`, paddingLeft: "1em", paddingRight: "1em" }}>
                        <div style={{ paddingBottom: 0, width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <p style={{ margin: 0, flex: 1, color: "rgb(117, 117, 117)", fontWeight: "400" }}>{proyecto.Proyecto}</p>
                            <Separador tamaño="0.25em" orientacion="horizontal" />
                            {
                                permitirVer(rol, ["Administrador", "Coordinador de programa"]) &&
                                <>
                                    <BarraIconos editar={() => { setVista("Editar") }} />
                                    <Separador tamaño="0.25em" orientacion="horizontal" />
                                </>
                            }
                            {
                                permitirVer(rol, ["Administrador"]) &&
                                <>
                                    <BarraIconos borrar={() => { setVista("Borrar") }} />
                                    <Separador tamaño="0.5em" orientacion="horizontal" />
                                </>
                            }
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{ flex: 1 }}>
                                <CampoTexto campo="Puesto" texto={proyecto.Puesto} />
                            </div>
                            {
                                permitirVer(rol, ["Administrador", "Coordinador de programa"]) &&
                                <div style={{ flex: 1 }}>
                                    <CampoTexto campo="Salario" texto={`Q${proyecto.Sueldo}`} />
                                </div>
                            }
                        </div>
                    </div>
                )
            case "Borrar":
                return (
                    <div className="row" style={{ padding: 0 }}>
                        <ConfirmarBorrar titulo="¿En verdad desea borrar la asignación?" descripcion="Esta acción no se puede deshacer." onClick={funcionBorrar} />
                    </div>
                )
            case "Editar":
                return (
                    <DynamicForm
                        titulo=""
                        editable={true}
                        actualizando={actualizando}
                        botonOK=
                        {
                            {
                                titulo: "Guardar",
                                press: funcionEditar
                            }
                        }
                        botonCancel={() => { setVista("Detalles") }}
                        secciones={
                            [
                                {
                                    seccion: "",
                                    campos: [
                                        {
                                            identificador: "id",
                                            inicial: proyecto.HPID,
                                            tipo: "identificador",
                                        },
                                        {
                                            campo: "Proyecto",
                                            identificador: "proyecto",
                                            inicial: proyecto.ProyectoID,
                                            default: "Elegir Proyecto",
                                            tipo: "select",
                                            opciones: listaproyectos,
                                            tamaño: "s12"
                                        },
                                        {
                                            campo: "Puesto",
                                            identificador: "puesto",
                                            inicial: proyecto.Puesto,
                                            tipo: "text",
                                            tamaño: "s6"
                                        },
                                        {
                                            campo: "Salario (Q)",
                                            identificador: "salario",
                                            inicial: proyecto.Sueldo,
                                            tipo: "text",
                                            tamaño: "s6"
                                        },
                                    ]
                                }
                            ]
                        }
                    />
                )
            default:
                return null;
        }
    }

    return (
        <>
            <SelectorVista />
            <div className="divider" style={{ marginTop: "0.5em", marginBottom: "0.5em" }} />
        </>
    );
}

const ListarProyectos = ({ rol, proyectos, borrar, editar, listaproyectos }) => {
    if (proyectos.length == 0) {
        return (
            <p style={{ color: "rgb(117, 117, 117)", fontFamily: "Montserrat" }}>No tiene proyectos registrados</p>
        );
    }
    return proyectos.map((_Proyecto, _Key) => {
        return (
            <EntradaProyecto
                key={_Proyecto.ProyectoID}
                proyecto={_Proyecto}
                borrar={borrar}
                editar={editar}
                listaproyectos={listaproyectos}
                rol={rol}
            />
        )
    })
}

const ModalProyectos = ({ dpi, listaproyectos, rol }) => {

    const [proyectos, setProyectos] = useState([])
    const [cargando, setCargando] = useState(true);
    const [agregar, setAgregar] = useState(false)
    const [actualizando, setActualizando] = useState(false)

    const cargarProyectos = async () => {
        if (dpi == null) return;

        setCargando(true);

        let datos;

        while (true) {
            datos = await getDatos(`/project/${dpi}`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setCargando(false)
        setProyectos(datos.Mensaje.reverse())
    }

    useEffect(() => {
        cargarProyectos();
    }, [dpi])

    const agregarProyecto = async (_Datos) => {

        if (propiedadVacia(_Datos, [])) return;

        setActualizando(true)

        const form = new FormData();

        form.append("Proyecto", _Datos.proyecto)
        form.append("Biyuyo", _Datos.salario)
        form.append("Puesto", _Datos.puesto)

        let datos;

        while (true) {
            datos = await insertDatos(form, `/personProject/${dpi}`);
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevosProyectos = [datos.Mensaje[0], ...proyectos]
        setProyectos(nuevosProyectos)
        setAgregar(false)
        setActualizando(false)
        mostrarOK("Asignado con éxito")

    }

    const borrarProyecto = async (_Proyecto, _Completado) => {
        let datos;

        while (true) {
            datos = await deleteDatos(_Proyecto.HPID, `/personProject`);
            if (datos != null) break;
            await sleepear(5000);
        }

        _Completado();

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevosProyectos = [...proyectos];

        for (let i = 0; i < nuevosProyectos.length; i++) {
            if (nuevosProyectos[i].HPID == _Proyecto.HPID) {
                nuevosProyectos.splice(i, 1)
                break;
            }
        }

        setProyectos(nuevosProyectos)
    }

    const editarProyecto = async (_Proyecto, _Datos, _Completado) => {

        if (propiedadVacia(_Datos, ["_modo"])) return;

        const form = new FormData();
        form.append('Sueldo', _Datos.salario)
        form.append('Puesto', _Datos.puesto)
        form.append('Proyecto', _Datos.proyecto)

        let datos;

        while (true) {
            datos = await updateDatos(form, _Proyecto.HPID, '/personProject');
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            _Completado(false)
            return;
        }

        datos.Mensaje = datos.Mensaje[0]

        const nuevosProyectos = [...proyectos];

        for (let i = 0; i < nuevosProyectos.length; i++) {
            if (nuevosProyectos[i].HPID == datos.Mensaje.HPID) {
                nuevosProyectos[i] = datos.Mensaje
                break;
            }
        }

        setProyectos(nuevosProyectos)

        _Completado(true)
        mostrarOK("Actualizado con éxito")
    }

    return (
        <MiniLoaderWrap cargando={cargando}>
            <div className="row" style={{ margin: 0 }}>

                {
                    permitirVer(rol, ["Administrador"]) &&
                    <div className="col s12" style={{ padding: 0 }}>
                        {
                            agregar ?
                                <DynamicForm
                                    titulo="Asignar a Proyecto"
                                    editable={true}
                                    actualizando={actualizando}
                                    botonOK=
                                    {
                                        {
                                            titulo: "Agregar",
                                            press: agregarProyecto
                                        }
                                    }
                                    botonCancel={() => { setAgregar(!agregar) }}
                                    secciones={
                                        [
                                            {
                                                seccion: "",
                                                campos: [
                                                    {
                                                        campo: "Proyecto",
                                                        identificador: "proyecto",
                                                        inicial: "-1",
                                                        default: "Elegir Proyecto",
                                                        tipo: "select",
                                                        opciones: listaproyectos,
                                                        tamaño: "s12"
                                                    },
                                                    {
                                                        campo: "Puesto",
                                                        identificador: "puesto",
                                                        inicial: "",
                                                        tipo: "text",
                                                        tamaño: "s6"
                                                    },
                                                    {
                                                        campo: "Salario (Q)",
                                                        identificador: "salario",
                                                        inicial: "",
                                                        tipo: "text",
                                                        tamaño: "s6"
                                                    },
                                                ]
                                            }
                                        ]
                                    }
                                />
                                :
                                <div className="row" style={{ margin: 0 }}>
                                    <div className="col s12">
                                        <p style={{ fontSize: "1.3em", marginTop: 0, marginBottom: "0.5em" }}>Asignar a Proyecto</p>
                                        <a onClick={() => { setAgregar(!agregar) }} className="noHighLight botonFlat" style={{ cursor: "pointer", display: "flex", alignItems: 'center' }}>
                                            <ion-icon style={{ fontSize: "1.5em", marginRight: "0.5em" }} name="add-circle-outline"></ion-icon>
                                Asignar</a>
                                    </div>
                                </div>
                        }
                    </div>
                }

                <div className="col s12">
                    <p style={{ fontSize: "1.3em", marginBottom: "0.5em" }}>Listado de Proyectos</p>
                    <ListarProyectos
                        proyectos={proyectos}
                        listaproyectos={listaproyectos}
                        borrar={borrarProyecto}
                        editar={editarProyecto}
                        rol={rol}
                    />
                </div>
            </div>
        </MiniLoaderWrap>
    );
}

export default ModalProyectos;