import React, { useState } from 'react';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';
import { getDatos, insertDatos, mostrarError, propiedadVacia, sleepear, updateDatos } from '../../../librerias/funciones';

const ModalEditar = ({ puesto, editar, cerrar }) => {

    const [actualizando, setActualizando] = useState(false)

    const editarPuesto = async (_Datos) => {

        if (propiedadVacia(_Datos, ["estado"])) return;

        const form = new FormData();
        form.append("Estado", puesto.Estado ? true : false);
        form.append("Puesto", _Datos.puesto)

        setActualizando(true)

        let datos;

        while (true) {
            datos = await updateDatos(form, puesto.IDReclutamiento, '/recruitment');
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        datos.Mensaje.Estado = parseInt(datos.Mensaje.Estado)

        editar(datos.Mensaje)
    }

    return (
        <DynamicForm
            titulo="Editar Puesto"
            editable={true}
            actualizando={actualizando}
            botonOK={{ titulo: "Guardar", press: editarPuesto }}
            botonCancel={cerrar}
            secciones={
                [
                    {
                        seccion: "",
                        campos: [
                            {
                                identificador: "id",
                                inicial: puesto.IDReclutamiento,
                                tipo: "identificador",
                            },
                            {
                                campo: "Puesto",
                                identificador: "puesto",
                                inicial: puesto.Puesto,
                                tipo: "text",
                                tamaño: "s12"
                            }
                        ]
                    }
                ]
            }
        />
    )
}

export default ModalEditar;