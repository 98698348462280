import React from 'react';
import Separador from '../Separador/Separador';

const BarraIconos = ({ borrar, editar, detalles, historial, adelante, descargar, aceptar, cancelar, derecha, documento }) => {
    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {
                borrar &&
                <>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <div onClick={borrar} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ cursor: "pointer", color: 'rgb(243, 91, 38)', fontSize: "1.5em" }} name="trash-outline" />
                    </div>
                </>
            }
            {
                editar &&
                <>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <div onClick={editar} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ cursor: "pointer", color: '#f39c12', fontSize: "1.5em" }} name="create-outline" />
                    </div>
                </>
            }
            {
                detalles &&
                <>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <div onClick={detalles} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ cursor: "pointer", color: 'rgb(0, 153, 242)', fontSize: "1.75em" }} name="list-outline" />
                    </div>
                </>
            }
            {
                historial &&
                <>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <div onClick={historial} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ cursor: "pointer", color: '#487eb0', fontSize: "1.75em" }} name="timer-outline" />
                    </div>
                </>
            }
            {
                adelante &&
                <>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <div onClick={adelante} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ cursor: "pointer", color: '#40739e', fontSize: "1.75em" }} name="return-down-forward-outline" />
                    </div>
                </>
            }
            {
                descargar &&
                <>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <div onClick={descargar} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ cursor: "pointer", color: '#40739e', fontSize: "1.75em" }} name="cloud-download-outline" />
                    </div>
                </>
            }
            {
                cancelar &&
                <>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <div onClick={cancelar} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ cursor: "pointer", color: '#ff6b6b', fontSize: "1.75em" }} name="close-circle-outline" />
                    </div>
                </>
            }
            {
                aceptar &&
                <>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <div onClick={aceptar} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ cursor: "pointer", color: '#1abc9c', fontSize: "1.75em" }} name="checkmark-circle-outline" />
                    </div>
                </>
            }
            {
                derecha &&
                <>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <div onClick={derecha} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ cursor: "pointer", color: '#636e72', fontSize: "2em" }} name="chevron-forward-circle-outline" />
                    </div>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                </>
            }
            {
                documento &&
                <>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <div onClick={documento} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ cursor: "pointer", color: '#8395a7', fontSize: "1.75em" }} name="document-attach-outline" />
                    </div>
                </>
            }
        </div>
    )
}

export default BarraIconos;