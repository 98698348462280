import React, { useState, useEffect } from 'react';
import BarraIconos from '../../../../componentes/BarraIconos.js/BarraIconos';
import MiniLoaderWrap from '../../../../componentes/MiniLoaderWrap/MiniLoaderWrap';
import Separador from '../../../../componentes/Separador/Separador';
import { formatearNombre, generarVinculoFoto, getDatos, mostrarError, sleepear } from '../../../../librerias/funciones';
import Evaluados from './Evaluados';

const MensajeNuHay = ({ mensaje }) => {
    return <p style={{ marginTop: 0, color: "rgb(117, 117, 117)", fontFamily: "Montserrat" }}>{mensaje}</p>
}

const EntradaPersona = ({ persona, evaluador }) => {
    return (
        <div style={{ marginTop: "0.5em", marginBottom: "0.5em", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
            <img className="z-depth-1" src={generarVinculoFoto(persona.Foto)} alt="" width="60em" height="60em" style={{ backgroundColor: "rgb(184, 183, 180)", borderRadius: "30em", marginRight: "2em", objectFit: "cover" }} />
            <div style={{ flex: 1 }}>
                <p style={{ margin: 0, fontWeight: "400", fontSize: "1.1em" }}>{formatearNombre(`${persona.Nombre},${persona.Apellido}`)}</p>
                {/* <div style={{ width: "100%", flexWrap: "wrap", display: "flex", flexDirection: "row" }}>
                    <p style={{ margin: 0, color: "rgb(117, 117, 117)" }}><strong style={{ fontSize: "0.95em" }}>{persona.Cargo}</strong></p>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <p style={{ margin: 0, color: "rgb(117, 117, 117)" }}><span style={{ fontSize: "0.85em" }}>Tel.</span> <strong style={{ fontSize: "0.95em" }}>{persona.Telefono}</strong></p>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <p style={{ margin: 0, color: "rgb(117, 117, 117)" }}><span style={{ fontSize: "0.85em" }}>Salario:</span> <strong style={{ fontSize: "0.95em" }}>Q{persona.Sueldo}</strong></p>
                </div> */}
            </div>
            <BarraIconos derecha={() => { evaluador(persona) }} />
        </div>
    );
}

const ListarPersonas = ({ personas, eliminar, asignado, mensaje, evaluador }) => {
    const listaPersonas = [];
    personas.map((_Persona) => {
        if (_Persona.Asignado == asignado)
            listaPersonas.push(<EntradaPersona key={_Persona.IDPersona} persona={_Persona} eliminar={eliminar} evaluador={evaluador} />)
    })

    if (listaPersonas.length == 0) return <MensajeNuHay mensaje={mensaje} />
    else return listaPersonas;
}

const ModalAsignacion = ({ bloque }) => {

    const [personas, setPersonas] = useState([])
    const [cargando, setCargando] = useState(false)
    const [vistaAsignacion, setVistaAsignacion] = useState(false)
    const [evaluador, setEvaluador] = useState(null)

    const cargarDatos = async () => {

        setCargando(true)

        let datos;

        while (true) {
            datos = await getDatos(`/evaluation/persons/${bloque.IDFormulario}`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setPersonas(datos.Mensaje)

        setCargando(false)
    }

    useEffect(() => {
        cargarDatos();
    }, [])

    const mostrarEvaluados = (_Evaluador) => {
        setEvaluador(_Evaluador)
        setVistaAsignacion(true)
    }

    return (
        vistaAsignacion ?
            <Evaluados bloque={bloque} evaluador={evaluador} regresar={() => {
                cargarDatos();
                setVistaAsignacion(false)
            }} />
            :
            <MiniLoaderWrap cargando={cargando}>
                <p style={{ fontSize: "1.3em" }}>Asignar Evaluadores</p>
                <p style={{ fontSize: "1.3em" }}>Asignadas</p>
                <ListarPersonas personas={personas} asignado={true} mensaje="No hay personas asignadas" evaluador={mostrarEvaluados} />
                <p style={{ fontSize: "1.3em" }}>Asignar Personas</p>
                <ListarPersonas personas={personas} asignado={false} mensaje="No hay personas para asignar" evaluador={mostrarEvaluados} />
            </MiniLoaderWrap>
    );
}

export default ModalAsignacion;