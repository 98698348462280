import React, { useState, useRef, useEffect } from 'react';
import { descargarNotas, mostrarError, sleepear, subirNotas } from '../../../librerias/funciones';

const CardNotas = () => {

    const [texto, setTexto] = useState("");
    const [cargando, setCargando] = useState(true);
    const [subiendo, setSubiendo] = useState(false);
    const timer = useRef();

    const actualizarNotas = async (_Texto) => {
        setSubiendo(true);

        const datos = new FormData();
        datos.append("Contenido", _Texto);

        let resultado;

        while (true) {
            resultado = await subirNotas(datos);
            if (resultado != null) {
                if (!resultado.Error) break;
                else {
                    mostrarError(resultado.Mensaje);
                    await sleepear(15000);
                }
            }
            else {
                await sleepear(5000);
            }
        }

        setSubiendo(false)
        setCargando(false)

    }

    const textoCambiado = (e) => {

        if (subiendo) return;

        const nuevoTexto = e.target.value == null ? "" : e.target.value;

        setCargando(true);
        setTexto(nuevoTexto)

        if (timer.current) {
            clearTimeout(timer.current); //cancel the previous timer.
            timer.current = null;
        }
        timer.current = setTimeout(() => {
            actualizarNotas(nuevoTexto)
        }, 1000);

    }

    const cargarNotas = async () => {

        let datos;

        while (true) {
            datos = await descargarNotas();
            if (datos != null) {
                if (!datos.Error) break;
                else {
                    mostrarError(datos.Mensaje);
                    await sleepear(15000);
                }
            }
            else {
                await sleepear(5000);
            }
        }

        if (datos.error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setTexto(datos.Mensaje)
        setCargando(false);

    }

    useEffect(() => {
        cargarNotas();
    }, [])

    return (
        <div className="col s12 Inicio_CardUtilidades">
            <div className="miniSombra" style={{ backgroundColor: "white", borderRadius: "0.5em", padding: "1em" }}>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                    <p style={{ margin: 0, fontWeight: "500" }}>Notas</p>
                    {!cargando ?
                        <div className="btn-flat" style={{ paddingRight: 0, cursor: "unset", display: "flex", justifyContent: "center", alignItems: "center" }} ><ion-icon style={{ color: 'rgb(26, 188, 156)', fontSize: "1.4em" }} name="checkmark-circle-outline" /></div>
                        :
                        <div className="spinear btn-flat" style={{ cursor: "unset", display: "flex", justifyContent: "center", alignItems: "center" }} ><ion-icon style={{ color: 'rgb(162, 162, 162)', fontSize: "1.4em" }} name="sync-outline" /></div>
                    }
                </div>
                <textarea value={texto == null ? "" : texto} onChange={textoCambiado} style={{ paddingLeft: "0.5em", paddingRight: "0.5em", overflowY: "auto", margin: 0, marginTop: "1em", width: "100%", height: "13.9em", maxHeight: "13.9em", color: "#333431", backgroundColor: "#fdffc4" }} className="materialize-textarea Inicio_AreaNotas" />
            </div>
        </div>
    );
}

export default CardNotas;