import React, { useEffect, useRef, useState } from 'react';
import { useGlobal } from 'reactn';
import FullModal from '../FullModal/FullModal';
import "./ModalPerfil.css";
import { formatearNombre, generarVinculoCV, generarVinculoFoto, insertArchivo, insertDatos, mostrarError, mostrarOK, sleepear } from '../../librerias/funciones';
import EntradaUpload from '../EntradaUpload/EntradaUpload';
const Compress = require('compress.js')

const compress = new Compress()


const obtenerExtension = (_Nombre) => {
    return _Nombre.toLowerCase().split('.').pop();
}

const comprimirImagen = async (_Imagen) => {
    const resizedImage = await compress.compress([_Imagen], {
        size: 1, // the max size in MB, defaults to 2MB
        quality: 1, // the quality of the image, max is 1,
        maxWidth: 256, // the max width of the output image, defaults to 1920px
        maxHeight: 256, // the max height of the output image, defaults to 1920px
        resize: true // defaults to true, set false if you do not want to resize the image width and height
    })
    const img = resizedImage[0];
    const base64str = img.data
    const imgExt = img.ext
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
    return resizedFiile;
}

const CardPerfil = ({ foto, nombre, puesto, cv, dpi, actualizar }) => {

    const refsubir = useRef();
    const refcv = useRef();
    const [subiendoIMG, setSubiendoIMG] = useState(false)
    const [subiendoCV, setSubiendoCV] = useState(false)
    const [urlFoto, setURLFoto] = useGlobal('urlFoto');

    const abrirCV = () => {
        const vinculo = generarVinculoCV(cv)
        window.open(vinculo, '_blank');
    }

    const subirFoto = async (_Archivo) => {

        const extension = obtenerExtension(_Archivo.name);

        if (!(extension == 'jpg' || extension == 'jpeg')) {
            mostrarError("La imagen debe ser jpg o jpeg");
            return;
        }

        let nuevaImagen = await comprimirImagen(_Archivo);
        // nuevaImagen = blobToFile(nuevaImagen, _Archivo.name);
        nuevaImagen = new File([nuevaImagen], _Archivo.name);

        setSubiendoIMG(true)

        const form = new FormData();
        form.append("imagen", nuevaImagen);

        let datos;

        while (true) {
            datos = await insertDatos(form, `/storePhoto/${dpi}`);
            if (datos != null) break;
            await sleepear(5000);
        }

        setSubiendoIMG(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        actualizar(datos.Mensaje)

        // setURLFoto(URL.createObjectURL(nuevaImagen))

        // const nuevosArchivos = [datos.Mensaje, ...archivos];
        // setArchivos(nuevosArchivos);
        mostrarOK("Foto subida con éxito");
    }

    const subirCV = async (_Archivo) => {
        const extension = obtenerExtension(_Archivo.name);

        if (extension != 'pdf') {
            mostrarError("El archivo debe ser PDF");
            return;
        }

        setSubiendoCV(true)

        const form = new FormData();
        form.append("pdf", _Archivo);

        let datos;

        while (true) {
            datos = await insertDatos(form, `/storeCV/${dpi}`);
            if (datos != null) break;
            await sleepear(5000);
        }

        setSubiendoCV(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        mostrarOK("Curriculum subido con éxito");

    }

    return (
        <div className="Inicio_CardPerfil" style={{ padding: "2em", paddingTop: "0em", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            {
                subiendoIMG &&
                <div className="progress" style={{ backgroundColor: "rgba(162, 162, 162,0.25)", margin: 0, marginTop: "1em", marginBottom: "1em", width: "5em" }}>
                    <div style={{ backgroundColor: "#3f92ba" }} className="indeterminate"></div>
                </div>
            }

            <div style={{ display: subiendoIMG ? "none" : "block", marginBottom: "1em", overflow: "hidden", width: "6em", height: "6em", borderRadius: "3em", border: "0.20em solid #b8b7b4", backgroundColor: "#b8b7b4", backgroundImage: `url(${generarVinculoFoto(foto)})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                <div ref={refsubir} className="show-on-hover noHighLight" style={{ backgroundColor: "rgba(0,0,0,0.5", cursor: "pointer", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ion-icon style={{ color: 'white', fontSize: "2em", marginRight: "0.25em", marginLeft: "0.25em" }} name="cloud-upload-outline" />
                </div>
            </div>
            <EntradaUpload boton={refsubir} subir={subirFoto} />
            <p style={{ textAlign: "center", margin: 0, fontFamily: "Montserrat", marginBottom: "0.5em", fontSize: "1.3em", fontWeight: "500" }}>{nombre}</p>
            <p style={{ textAlign: "center", fontFamily: "Montserrat", margin: 0, marginBottom: "1em", color: "#333431", fontWeight: "400", fontSize: "1em" }}>{puesto}</p>

            <div style={{ display: "flex", flexDirection: "row" }}>
                <a ref={refcv} style={{ paddingLeft: "0.25em", paddingRight: "0.25em", flexWrap: "wrap", height: "auto", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#0099f2", marginRight: "0.2em", borderTopLeftRadius: "0.5em", borderBottomLeftRadius: "0.5em" }} className="waves-effect waves-light btn z-depth-0">
                    <ion-icon style={{ color: 'white', fontSize: "1.4em", marginRight: "0.25em", marginLeft: "0.25em" }} name="arrow-up-circle-outline" />
                </a>
                <a onClick={abrirCV} style={{ flexWrap: "wrap", height: "auto", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "#0099f2", borderTopRightRadius: "0.5em", borderBottomRightRadius: "0.5em" }} className="waves-effect waves-light btn z-depth-0">
                    <ion-icon style={{ color: 'white', fontSize: "1.4em", marginRight: "0.25em", marginLeft: "0.25em" }} name="file-tray-full-outline" />
                    <span>Curriculum</span>
                </a>
            </div>
            <EntradaUpload boton={refcv} subir={subirCV} />

        </div>
    );
}

const convertirDato = (_Dato) => {
    if (_Dato == null || _Dato == "") return "N/D";
    else return _Dato;
}

const EntradaCardDatos = (_props) => {
    return (
        <div style={{ textAlign: "center" }} className="col s12 m6 l4">
            <p style={{ marginBottom: 0, color: "#a7a7a7", wordWrap: "break-word" }}>{_props.titulo}</p>
            <p style={{ margin: 0, color: "#3d3e3b", wordWrap: "break-word" }}>{convertirDato(_props.datos)}</p>
        </div>
    );
}

const CardDatos = ({ persona }) => {
    return (
        <center style={{ marginTop: "1em" }}>
            <div className="row igualCol" style={{ padding: 0, margin: 0 }}>
                <EntradaCardDatos titulo="Nombre" datos={persona.Nombre} />
                <EntradaCardDatos titulo="Apellido" datos={persona.Apellido} />
                <EntradaCardDatos titulo="Sexo" datos={persona.Sexo} />
                <EntradaCardDatos titulo="DPI" datos={persona.DPI} />
                <EntradaCardDatos titulo="NIT" datos={persona.NIT} />
                <EntradaCardDatos titulo="Profesión" datos={persona.ProfesionDiversificado} />
                <EntradaCardDatos titulo="Correo" datos={persona.Correo} />
                <EntradaCardDatos titulo="Dirección" datos={persona.Direccion} />
                <EntradaCardDatos titulo="Telefono" datos={persona.Telefono} />
                <EntradaCardDatos titulo="Móvil" datos={persona.TelefonoMovil} />
                <EntradaCardDatos titulo="Emergencia" datos={persona.TelefonoEmergencia} />
                <EntradaCardDatos titulo="Sangre" datos={persona.Sangre} />
                {/* <EntradaCardDatos titulo="Egreso" datos={persona.Egreso} /> */}
                <EntradaCardDatos titulo="Enfermedades" datos={persona.Enfermedades} />
                <EntradaCardDatos titulo="Estado" datos={persona.Estado} />
                <EntradaCardDatos titulo="Estado Civil" datos={persona.EstadoCivil} />
                <EntradaCardDatos titulo="Cónyuge" datos={persona.NombreConyuge} />
                <EntradaCardDatos titulo="Nacimiento" datos={persona.FechaNac} />
                <EntradaCardDatos titulo="Ingreso" datos={persona.Ingreso} />
                <EntradaCardDatos titulo="Licencia" datos={persona.Licencia} />
                <EntradaCardDatos titulo="Correo" datos={persona.Correo} />
                <EntradaCardDatos titulo="Medicamentos Alérgicos" datos={persona.MedicamentosAlergico} />
                <EntradaCardDatos titulo="IGSS" datos={persona.NoAfiliacionIgss} />
                <EntradaCardDatos titulo="Cuenta" datos={persona.NoCuentaBanco} />
                <EntradaCardDatos titulo="Madre" datos={persona.NombreMadre} />
                <EntradaCardDatos titulo="Padre" datos={persona.NombrePadre} />

            </div>
        </center>
    );
}

const ModalPerfil = () => {

    const [abrirInfo, setAbrirInfo] = useGlobal('abrirInfo');
    const [perfilUsuario, setPerfilUsuario] = useGlobal('perfilUsuario');

    const cerrarModal = () => {
        setAbrirInfo(false);
    }

    useEffect(() => {
    }, [abrirInfo, perfilUsuario])

    // if(perfilUsuario == null) return null;

    const actualizarFoto = (_Foto) => {
        const nuevoPerfilUsuario = { ...perfilUsuario };
        nuevoPerfilUsuario.Foto = _Foto;
        setPerfilUsuario(nuevoPerfilUsuario)
    }

    return (
        <FullModal
            // width="90%"
            // maxWidth="50rem"
            abierto={abrirInfo}
            cerrar={cerrarModal}>
            <div className="" style={{ padding: "1em", backgroundColor: "white", borderRadius: "0.5em" }}>
                <CardPerfil actualizar={actualizarFoto} dpi={perfilUsuario.DPI} cv={perfilUsuario.DPI} foto={perfilUsuario.Foto} nombre={formatearNombre(`${perfilUsuario.Nombre},${perfilUsuario.Apellido}`)} puesto={perfilUsuario.Puesto} />
                <CardDatos persona={perfilUsuario} />
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end", overflow: "hidden" }}>
                    <a onClick={cerrarModal} style={{ borderRadius: 0, backgroundColor: "unset", color: "#757575" }} className="waves-effect waves-dark btn z-depth-0">Cerrar</a>
                </div>
            </div>
        </FullModal>
    );
}

export default ModalPerfil;