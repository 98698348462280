import React, { useState } from 'react';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';
import { formatearFechaPHP, insertDatos, mostrarError, mostrarOK, propiedadVacia, sleepear } from '../../../librerias/funciones';

const Agregar = ({ agregar, cerrar }) => {

    const [actualizando, setActualizando] = useState(false)

    const agregarProyecto = async (_Datos) => {

        if (propiedadVacia(_Datos, ["programa"])) return;

        setActualizando(true)

        const form = new FormData();
        form.append('Proyecto', _Datos.nombre)
        form.append('Programa', _Datos.programa)
        form.append('Descripcion', _Datos.descripcion)
        form.append('Inicio', formatearFechaPHP(_Datos.desde))
        form.append('Fin', formatearFechaPHP(_Datos.hasta))
        form.append('Estado', false)

        let datos;

        while (true) {
            datos = await insertDatos(form, '/project');
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        agregar(datos.Mensaje)
        mostrarOK("Agregado con éxito")
        cerrar();
    }

    return (
        <DynamicForm
            titulo="Agregar Proyecto"
            editable={true}
            actualizando={actualizando}
            botonOK={{ titulo: "Agregar", press: agregarProyecto }}
            botonCancel={cerrar}
            secciones={
                [
                    {
                        seccion: "",
                        campos: [
                            {
                                campo: "Nombre",
                                identificador: "nombre",
                                inicial: "",
                                tipo: "text",
                                tamaño: "s12"
                            },
                            {
                                campo: "Programa",
                                identificador: "programa",
                                inicial: "",
                                tipo: "text",
                                tamaño: "s12"
                            },
                            {
                                campo: "Desde",
                                identificador: "desde",
                                inicial: "",
                                tipo: "fecha",
                                tamaño: "s12 m6"
                            },
                            ,
                            {
                                campo: "Hasta",
                                identificador: "hasta",
                                inicial: "",
                                tipo: "fecha",
                                tamaño: "s12 m6"
                            },
                            {
                                campo: "Descripcion",
                                identificador: "descripcion",
                                inicial: "",
                                tipo: "area",
                                tamaño: "s12"
                            },
                        ]
                    }
                ]
            }
        />
    )
}

export default Agregar;