import React from 'react';
import { useState } from 'reactn';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';
import FullModal from '../../../componentes/FullModal/FullModal';
import CardEvaluacion from './CardEvaluacion';
import { df_EditarBloqueAptitud, df_NuevoBloque } from '../../../librerias/plantillasforms'
import { mostrarError, insertBloqueAptitud, propiedadVacia, sleepear, deleteBloqueAptitud, mostrarOK, updateBloqueAptitud, permitirVer } from '../../../librerias/funciones';
import ConfirmarBorrar from '../../../componentes/ConfirmarBorrar/ConfirmarBorrar';
import Aptitudes from './Aptitudes';

const EntradaBloque = ({ bloque, borrar, editar, aptitudes, rol }) => {

    const [vistaBorrar, setVistaBorrar] = useState(false);

    const borrarBloque = (_Borrar) => {
        if (_Borrar) {
            borrar(bloque.IDBloque, () => {
                setVistaBorrar(!vistaBorrar)
            })
        }
        else setVistaBorrar(!vistaBorrar)
    }

    return (
        <div style={{ width: "100%", borderBottom: "1px solid rgb(218, 220, 224)" }}>
            {vistaBorrar ?
                <div className="row" style={{ margin: 0, paddingBottom: "1em" }}>
                    <ConfirmarBorrar titulo="Borrar Bloque" descripcion="¿Seguro desea borrar el bloque? Esta acción no se puede deshacer" onClick={borrarBloque} />
                </div>
                :
                <div style={{ paddingTop: "0.5em", paddingBottom: "0.5em", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <p style={{ flex: 1, margin: 0, fontWeight: "400", fontSize: "1em" }}>{bloque.Nombre}</p>
                    {
                        permitirVer(rol, ["Administrador", "Coordinador de programa"]) &&
                        <>
                            <div onClick={() => { setVistaBorrar(!vistaBorrar) }} className="noHighLightWrapper botonFlatWrap">
                                <ion-icon className="noHighLight" style={{ marginLeft: "0.5em", marginRight: "0.5em", cursor: "pointer", color: 'rgb(243, 91, 38)', fontSize: "1.5em" }} name="trash-outline" />
                            </div>
                            <div onClick={() => { editar(bloque) }} className="noHighLightWrapper botonFlatWrap">
                                <ion-icon className="noHighLight" style={{ cursor: "pointer", color: 'rgb(0, 153, 242)', fontSize: "1.5em" }} name="create-outline" />
                            </div>
                        </>
                    }
                    <div onClick={() => { aptitudes(bloque) }} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ marginLeft: "0.5em", cursor: "pointer", color: 'rgb(0, 153, 242)', fontSize: "1.75em" }} name="list-outline" />
                    </div>
                </div>}
        </div>
    );
}

const ListaBloques = ({ bloques, borrar, editar, aptitudes, rol }) => {
    return (
        bloques.map((_Bloque, _Key) => {
            return <EntradaBloque
                key={_Bloque.IDBloque}
                bloque={_Bloque}
                borrar={borrar}
                editar={editar}
                aptitudes={aptitudes}
                rol={rol}
            />
        })
    );
}

const VistaFormBloque = ({ titulo, editable, actualizando, boton, onok, oncancel, plantilla }) => {
    return (
        <div style={{ backgroundColor: "white", borderRadius: "0.5em" }}>
            <DynamicForm
                titulo={titulo}
                editable={editable}
                actualizando={actualizando}
                botonOK={{ titulo: boton, press: onok }}
                botonCancel={oncancel}
                secciones={plantilla}
            />
        </div>
    )
}

const BloqueAptitudes = ({ bloques, agregar, borrar, editar, agregaraptitud, borraraptitud, editaraptitud, rol }) => {

    const [estadoModal, setEstadoModal] = useState(false)
    const [plantilla, setPlantilla] = useState([]);
    const [titulo, setTitulo] = useState("");
    const [editable, setEditable] = useState(false);
    const [actualizando, setActualizando] = useState(false);
    const [boton, setBoton] = useState("");
    const [vista, setVista] = useState("")
    const [bloque, setBloque] = useState(null)

    const abrirAgregarBloque = () => {
        setPlantilla(df_NuevoBloque("Bloque", "bloque"));
        setBoton("Agregar");
        setEditable(true);
        setTitulo("Agregar Bloque");
        setVista("Form Bloque");
        setEstadoModal(true);
    }

    const abrirEditarBloque = async (_Bloque) => {
        setPlantilla(df_EditarBloqueAptitud(_Bloque));
        setBoton("Guardar");
        setEditable(true);
        setTitulo("Editar Bloque");
        setVista("Form Bloque");
        setEstadoModal(true);
    }

    const abrirAptitudes = (_Bloque) => {
        setBloque(_Bloque)
        setVista('Aptitudes')
        setEstadoModal(true)
    }

    const agregarBloque = async (_Datos) => {
        if (propiedadVacia(_Datos, ['_modo'])) return;

        setActualizando(true)

        const form = new FormData();
        form.append('Nombre', _Datos.bloque)

        let datos;

        while (true) {
            datos = await insertBloqueAptitud(form);
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        mostrarOK("Agregado nuevo bloque")
        agregar(datos.Mensaje)
        cerrarModal();
    }

    const editarBloque = async (_Datos) => {
        if (propiedadVacia(_Datos, ['_modo'])) return;

        setActualizando(true)

        const form = new FormData();
        form.append('Nombre', _Datos.bloque)

        let datos;

        while (true) {
            datos = await updateBloqueAptitud(form, _Datos.id);
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        mostrarOK("Bloque actualizado")
        editar(datos.Mensaje)
        cerrarModal();
    }

    const borrarBloque = async (_ID, _Cancelar) => {

        let datos;

        while (true) {
            datos = await deleteBloqueAptitud(_ID);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            _Cancelar()
            return;
        }

        mostrarOK("Bloque eliminado")
        borrar(_ID)
        cerrarModal();
    }

    const cerrarModal = () => {
        setEstadoModal(false);
        setPlantilla([]);
    }

    const onOK = (_Datos) => {
        switch (_Datos._modo) {
            case "Agregar Bloque":
                agregarBloque(_Datos)
                break;
            case "Editar Bloque":
                editarBloque(_Datos)
                break;
            default:
                break;
        }
    }

    const onCancel = () => {
        cerrarModal();
    }

    const SelectorVista = ({ vista }) => {
        switch (vista) {
            case "Form Bloque":
                return (
                    <VistaFormBloque
                        titulo={titulo}
                        editable={editable}
                        actualizando={actualizando}
                        boton={boton}
                        onok={onOK}
                        oncancel={onCancel}
                        plantilla={plantilla} />
                )
            case 'Aptitudes':
                return (
                    <Aptitudes rol={rol} bloque={bloque} agregar={agregaraptitud} borrar={borraraptitud} editar={editaraptitud} />
                )
            default:
                return null;
        }
    }

    return (
        <>

            <CardEvaluacion rol={rol} titulo="Bloques de Aptitudes" boton="Agregar" onclick={abrirAgregarBloque}>
                {bloques.length == 0 ?
                    <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <ion-icon style={{ color: 'rgb(117, 117, 117)', fontSize: "2.5em" }} name="alert-circle-outline" />
                        <p style={{ color: "rgb(117, 117, 117)", fontFamily: "Montserrat", fontSize: "1.2em" }}>Aún no tiene bloques</p>
                    </div>
                    :
                    <ListaBloques
                        bloques={bloques}
                        borrar={borrarBloque}
                        editar={abrirEditarBloque}
                        aptitudes={abrirAptitudes}
                        rol={rol}
                    />}
            </CardEvaluacion>

            {/* Modal Usuario */}
            <FullModal
                abierto={estadoModal}
                cerrar={() => {
                    setEstadoModal(false);
                    setPlantilla([]);
                }}
            >
                <SelectorVista vista={vista} />
            </FullModal>
        </>
    );
}

export default BloqueAptitudes;