import React, { useEffect, useRef, useState } from 'react';
import { useGlobal } from 'reactn';
import { Version } from '../../librerias/sistema';
import FullModal from '../FullModal/FullModal';

const Integrante = ({ integrante }) => {
    return (
        <div style={{ marginRight: "2em", marginLeft: "2em" }}>
            <img className="miniSombra" src={`${integrante.Foto}`} alt="" width="70em" height="70em" style={{ objectFit: "cover", borderRadius: "35em" }} />
            <p style={{ marginBottom: 0, fontSize: "1.1em", color: "rgb(117, 117, 117)" }}>{integrante.Nombre}</p>
            <p style={{ margin: 0, fontSize: "1.2em", color: "rgb(117, 117, 117)", fontWeight: "500" }}>{integrante.Apellido}</p>
        </div>
    )
}

const ListarIntegrantes = ({ integrantes }) => {
    return integrantes.map((_Integrante, _Key) => {
        return <Integrante key={_Key} integrante={_Integrante} />
    })
}

const Pleca = () => {
    return (
        <span>{` | `}</span>
    )
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

const ListarRandom = () => {
    const integrantes = [
        { Nombre: "David", Apellido: "Melara", Foto: "/img/dm.jpg" },
        { Nombre: "Brandon", Apellido: "de León", Foto: "/img/bd.jpeg" },
        { Nombre: "Diego", Apellido: "Paredes", Foto: "/img/dp.jpeg" },
    ];

    shuffleArray(integrantes)
    integrantes.push({ Nombre: "Juan", Apellido: "Zamora", Foto: "/img/jz.jpeg" })

    return integrantes;
}

const ModalAcerca = () => {

    const [abrir, setAbrir] = useGlobal('abrirAcerca');
    const [integrantes, setIntegrantes] = useState([])
    const [perfilUsuario, setPerfilUsuario] = useGlobal('perfilUsuario');

    const cerrarModal = () => {
        setAbrir(false);
    }

    useEffect(() => {
        setIntegrantes(ListarRandom())
    }, [abrir])

    return (
        <FullModal
            abierto={abrir}
            cerrar={cerrarModal}>
            <div className="" style={{ fontFamily: "Montserrat", textAlign: "center", paddingLeft: "1em", paddingRight: "1em", backgroundColor: "white", borderRadius: "0.5em" }}>
                <p style={{ fontWeight: 500, fontSize: "1.3em", marginBottom: 0 }}>Quazar</p>
                <div>
                    <p style={{ marginBottom: 0, fontSize: "1.1em", fontFamily: "Montserrat", color: "rgb(117, 117, 117)", fontWeight: "500" }}>UI</p>
                    <p style={{ marginTop: 0, fontSize: "1.1em", fontFamily: "Montserrat", color: "rgb(117, 117, 117)" }}>{Version}</p>
                </div>
                <div>
                    <p style={{ marginBottom: 0, fontSize: "1.1em", fontFamily: "Montserrat", color: "rgb(117, 117, 117)", fontWeight: "500" }}>Servidor</p>
                    <p style={{ marginTop: 0, fontSize: "1.1em", fontFamily: "Montserrat", color: "rgb(117, 117, 117)" }}>{perfilUsuario.Sistema}</p>
                </div>
                <div>
                    <span><a href="#!">Videos</a></span>
                    <Pleca />
                    <span><a href="#!">Manual técnico</a></span>
                    <Pleca />
                    <span><a href="#!">Manual de usuario</a></span>
                </div>
                <p style={{ fontWeight: "500", marginBottom: "1.5em", fontSize: "1.3em", fontFamily: "Montserrat" }}><span>Nuestro </span><span>equipo</span></p>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                    <ListarIntegrantes integrantes={integrantes} />
                </div>

                <p style={{ fontWeight: "500", marginBottom: "1.5em", fontSize: "1.3em", fontFamily: "Montserrat" }}>Distribuido por</p>

                <a href="https://capistudioapps.com">
                    <img src="/img/csa.png" alt="" width="200em" height="auto" />
                </a>

                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end", overflow: "hidden" }}>
                    <a onClick={cerrarModal} style={{ borderRadius: 0, backgroundColor: "unset", color: "#757575" }} className="waves-effect waves-dark btn z-depth-0">Cerrar</a>
                </div>
            </div>
        </FullModal>
    );
}

export default ModalAcerca;