import React, { useState } from 'react';

const Titulo = (_props) => {
    return (
        <span style={{ verticalAlign: "middle", color: "rgb(167, 167, 167)", fontSize: _props.mini ? "0.75em" : "0.85em", marginRight: "0.5em" }}>{_props.texto}</span>
    );
}

const Texto = (_props) => {
    return (
        <span style={{ verticalAlign: "middle", color: "rgb(61, 62, 59)", fontSize: _props.mini ? "0.85em" : "1em", fontFamily: _props.swap ? "unset" : "Montserrat" }}>{_props.texto}</span>
    );
}

const Descripcion = (_props) => {
    return (
        <span>
            <Titulo estado={_props.estado} swap={_props.swap} mini={_props.mini} texto={_props.titulo} />
            <Texto estado={_props.estado} swap={_props.swap} mini={_props.mini} texto={_props.descripcion} />
        </span>
    );
}

const ConfirmarBorrar = ({ titulo, descripcion, onClick }) => {

    const [borrando, setBorrando] = useState(false)

    const aceptar = () => {
        setBorrando(true)
        onClick(true)
    }

    const cancelar = () => {
        onClick(false)
    }

    return (
        <>
            <div style={{ padding: 0 }} className="col s12 center">
                <span style={{ fontSize: "1em" }}>{titulo}</span>
            </div>
            <div style={{ padding: 0 }} className="col s12 center">
                <Descripcion mini titulo="" descripcion={descripcion} />
            </div>
            <div className="col s12 center" style={{ marginTop: "0.5em" }}>
                {!borrando ?
                    <>
                        <a onClick={cancelar} style={{ borderRadius: 0, backgroundColor: "unset", color: "#757575" }} className="waves-effect waves-dark btn z-depth-0">Cancelar</a>
                        <a onClick={aceptar} style={{ fontWeight: "500", backgroundColor: "rgb(243, 91, 38)", marginLeft: "0.5em" }} className="waves-effect waves-light btn z-depth-0">Borrar</a>
                    </>
                    :
                    <div className="progress" style={{ backgroundColor: "rgba(162, 162, 162,0.25)" }}>
                        <div style={{ backgroundColor: "rgb(243, 91, 38)" }} className="indeterminate"></div>
                    </div>
                }
            </div>
        </>
    );
}

export default ConfirmarBorrar;