import React, { useRef, useEffect, useState } from 'react';
import LoadWrap from '../../componentes/LoadWrap/LoadWrap';
import M from 'materialize-css';

import "./Personas.css";
import FullModal from '../../componentes/FullModal/FullModal';
import DynamicForm from '../../componentes/DynamicForm/DynamicForm';
import CardEstados from './Componentes/CardEstados';
import CardRoles from './Componentes/CardRoles';
import { df_NuevoUsuario, df_DetallePersona, df_EditarPersona, df_NuevaPersona, df_EditarUsuario } from '../../librerias/plantillasforms';
import { updatePersona, insertPersona, formatearNulo, getPersonas, sleepear, mostrarError, formatearNombre, propiedadVacia, getSelectsPersonas, formatearFechaPHP, mostrarOK, buscarEnPropiedades, insertUsuario, updateUsuario, resetUsuario, getDatos, validarDigitos, validarCantidad, esFechaFutura, generarVinculoCV, permitirVer, generarVinculoFoto, insertDatos, deleteDatos } from '../../librerias/funciones';
import Tareas from './Componentes/Tareas';
import ModalProyectos from './Componentes/ModalProyectos';
import ModalPermisos from './Componentes/Permisos/ModalPermisos';
import ModalArchivos from './Componentes/ModalArchivos';
import Separador from '../../componentes/Separador/Separador';
import VistaUsuario from './Componentes/VistaUsuario';
import AdminPermisos from './Componentes/AdminPermisos/AdminPermisos';
import { useGlobal } from 'reactn';
import EntradaUpload from '../../componentes/EntradaUpload/EntradaUpload';
import ModalBorrar from './Componentes/ModalBorrar';
const Compress = require('compress.js')

const compress = new Compress()

const HeaderTabla = (_props) => {
    return (
        <th>
            {_props.titulo}
            {/* <div style={{ cursor: "pointer", color: "rgb(51, 52, 49)", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                {_props.titulo}
                <ion-icon name="arrow-down-outline" style={{ color: "rgb(51, 52, 49)", fontSize: "1.1em", marginLeft: "0.25em" }}></ion-icon>
            </div> */}
        </th>
    );
}

const obtenerExtension = (_Nombre) => {
    return _Nombre.toLowerCase().split('.').pop();
}

const comprimirImagen = async (_Imagen) => {
    const resizedImage = await compress.compress([_Imagen], {
        size: 1, // the max size in MB, defaults to 2MB
        quality: 1, // the quality of the image, max is 1,
        maxWidth: 256, // the max width of the output image, defaults to 1920px
        maxHeight: 256, // the max height of the output image, defaults to 1920px
        resize: true // defaults to true, set false if you do not want to resize the image width and height
    })
    const img = resizedImage[0];
    const base64str = img.data
    const imgExt = img.ext
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
    return resizedFiile;
}

const EntradaPersona = ({ borrar, foto, rol, persona, detalles, editar, tareas, proyectos, usuario, contraseña, permisos, archivos, contenedor }) => {

    const dropRef = useRef();
    const [subiendoIMG, setSubiendoIMG] = useState(false)
    const refsubir = useRef();

    useEffect(() => {
        M.Dropdown.init(dropRef.current, {
            constrainWidth: false,
            container: contenedor
        });
    }, []);

    const abrirDetalles = () => {
        detalles(persona);
    }

    const abrirEditar = () => {
        editar(persona)
    }

    const abrirTareas = () => {
        tareas(persona)
    }

    const mostrarCV = () => {
        window.open(generarVinculoCV(persona.DPI), '_blank');
    }

    const mostrarFoto = () => {
        window.open(generarVinculoFoto(persona.Foto), '_blank');
    }

    const funcionSubir = async (_Archivo) => {
        const extension = obtenerExtension(_Archivo.name);

        if (!(extension == 'jpg' || extension == 'jpeg')) {
            mostrarError("La imagen debe ser jpg o jpeg");
            return;
        }

        let nuevaImagen = await comprimirImagen(_Archivo);

        nuevaImagen = new File([nuevaImagen], _Archivo.name);

        setSubiendoIMG(true)

        foto(persona.DPI, nuevaImagen, (_OK) => {
            setSubiendoIMG(false);
        })
    }

    return (
        <tr>
            <td>
                <center>
                    {
                        subiendoIMG &&
                        <div className="progress" style={{ backgroundColor: "rgba(162, 162, 162,0.25)", margin: 0, marginTop: "1em", marginBottom: "1em", width: "4em" }}>
                            <div style={{ backgroundColor: "#3f92ba" }} className="indeterminate"></div>
                        </div>
                    }

                    <div style={{ display: subiendoIMG ? "none" : "block", overflow: "hidden", width: "4em", height: "4em", borderRadius: "2em", border: "0.20em solid #b8b7b4", backgroundColor: "#b8b7b4", backgroundImage: `url(${generarVinculoFoto(persona.Foto)})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                        <div ref={refsubir} className="show-on-hover noHighLight" style={{ backgroundColor: "rgba(0,0,0,0.5", cursor: "pointer", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <ion-icon style={{ color: 'white', fontSize: "2em", marginRight: "0.25em", marginLeft: "0.25em" }} name="cloud-upload-outline" />
                        </div>
                    </div>
                    <EntradaUpload boton={refsubir} subir={funcionSubir} noRemove />
                </center>
            </td>
            <td style={{ fontWeight: "500" }}>{formatearNombre(`${persona.Nombre},${persona.Apellido}`)}</td>
            <td>{formatearNulo(persona.Cargo)}</td>
            <td>{formatearNulo(persona.ProfesionDiversificado)}</td>
            <td>{formatearNulo(persona.Ingreso)}</td>
            <td>{formatearNulo(persona.Estado)}</td>
            <td>
                <a ref={dropRef} className={`dropdown-trigger`} data-target={`entrada-personas-${persona.IDPersona}`} style={{ height: "100%", paddingLeft: "1rem", paddingRight: "1rem", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <ion-icon name="ellipsis-vertical" style={{ color: "rgb(51, 52, 49)", fontSize: "2em" }}></ion-icon>
                </a>
                <ul style={{ backgroundColor: "transparent", overflow: "visible" }} id={`entrada-personas-${persona.IDPersona}`} className='dropdown-content z-depth-0'>
                    <center className="Personas_floatopciones" style={{ width: "15em", backgroundColor: "white", borderRadius: "0.5em", overflow: "hidden" }}>
                        <li><a onClick={abrirDetalles} style={{ fontSize: "1em", color: "rgb(51, 52, 49)" }} >Detalles</a></li>
                        {
                            permitirVer(rol, ["Administrador"]) &&
                            <>
                                <li><a onClick={abrirEditar} style={{ fontSize: "1em", color: "rgb(51, 52, 49)" }} >Editar</a></li>
                                <li><a onClick={() => { borrar(persona) }} style={{ fontSize: "1em", color: "rgb(51, 52, 49)" }} >Eliminar</a></li>
                                <div className="divider"></div>
                                <li><a onClick={() => { usuario(persona, persona.Usuario ? "Editar Usuario" : "Crear Usuario") }} style={{ fontSize: "1em", color: "rgb(51, 52, 49)" }} >{persona.Usuario ? "Editar Usuario" : "Crear Usuario"}</a></li>
                                {persona.Usuario &&
                                    <li><a onClick={() => { contraseña(persona) }} style={{ fontSize: "1em", color: "rgb(51, 52, 49)" }} >Restablecer contraseña</a></li>
                                }
                            </>
                        }
                        <div className="divider"></div>
                        {persona.Usuario &&
                            <li><a onClick={abrirTareas} style={{ fontSize: "1em", color: "rgb(51, 52, 49)" }} >Tareas</a></li>
                        }
                        <li><a onClick={() => { proyectos(persona) }} style={{ fontSize: "1em", color: "rgb(51, 52, 49)" }} >Proyectos</a></li>
                        <li><a onClick={() => { permisos(persona) }} style={{ fontSize: "1em", color: "rgb(51, 52, 49)" }} >Permisos</a></li>
                        {
                            permitirVer(rol, ["Administrador", "Coordinador de programa"]) &&
                            <li><a onClick={() => { archivos(persona) }} style={{ fontSize: "1em", color: "rgb(51, 52, 49)" }} >Archivos</a></li>
                        }
                        <li><a onClick={mostrarFoto} style={{ fontSize: "1em", color: "rgb(51, 52, 49)" }} >Ver foto</a></li>
                        <li><a onClick={mostrarCV} style={{ fontSize: "1em", color: "rgb(51, 52, 49)" }} >Curriculum</a></li>
                    </center>
                </ul>
            </td>
        </tr>
    );
}

const ListarPersonas = ({ borrar, foto, rol, personas, contenedor, editar, detalles, buscador, tareas, proyectos, usuario, contraseña, permisos, archivos }) => {
    return (
        personas.map((_Persona) => {
            if (buscarEnPropiedades(_Persona, buscador, ['CV', 'Categoria', 'IDEstado', 'IDPersona', 'IDSexo', 'IDTipoSangre', 'TipoSangre'])) {
                return (
                    <EntradaPersona
                        key={_Persona.IDPersona}
                        persona={_Persona}
                        editar={editar}
                        detalles={detalles}
                        tareas={tareas}
                        proyectos={proyectos}
                        usuario={usuario}
                        contraseña={contraseña}
                        permisos={permisos}
                        archivos={archivos}
                        contenedor={contenedor}
                        rol={rol}
                        foto={foto}
                        borrar={borrar}
                    />
                )
            }
            else {
                return null;
            }
        })
    );
}

const Personas = () => {
    const [estadoModal, setEstadoModal] = useState(false);
    const [editable, setEditable] = useState(true);
    const [titulo, setTitulo] = useState("Agregar");
    const [botonOK, setBotonOK] = useState("Agregar");
    const [plantillaForm, setPlantillaForm] = useState([]);
    const [personas, setPersonas] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [selects, setSelects] = useState({})
    const [actualizandoDatos, setActualizandoDatos] = useState(false)
    const [buscador, setBuscador] = useState("")
    const [dpi, setDPI] = useState(null);
    const [tituloUsuario, setTituloUsuario] = useState("");
    const [botonUsuario, setBotonUsuario] = useState("");
    const [plantillaUsuario, setPlantillaUsuario] = useState([])
    const [contraseña, setContraseña] = useState("")
    const contRef = useRef();
    const [vista, setVista] = useState(null)
    const [proyectos, setProyectos] = useState([])
    const [roles, setRoles] = useState([])
    const perfilUsuario = useGlobal('perfilUsuario')[0];

    const abrirModal = (_Vista) => {
        setVista(_Vista)
        setEstadoModal(true)
    }

    const cerrarModal = () => {
        setEstadoModal(false);
        setPlantillaForm([])
        setVista(null)
    }

    const editarPersona = async (_Datos) => {

        if (propiedadVacia(_Datos, [
            'igss',
            'licencia',
            'conyuge',
            'madre',
            'padre',
            'enfermedades',
            'medicamentos',
            'banco',
            'movil'
        ])) {
            return;
        }

        if (!validarDigitos(_Datos.dpi)) {
            mostrarError("DPI solo puede contener dígitos")
            return;
        }
        if (!validarCantidad(_Datos.dpi, 13)) {
            mostrarError("DPI debe contener 13 dígitos")
            return;
        }

        if (!validarDigitos(_Datos.telefono)) {
            mostrarError("Teléfono solo puede contener dígitos")
            return;
        }
        if (!validarCantidad(_Datos.telefono, 8)) {
            mostrarError("Teléfono debe contener 8 dígitos")
            return;
        }

        if (_Datos.movil != "" && _Datos.movil != null) {
            if (!validarDigitos(_Datos.movil)) {
                mostrarError("Móvil solo puede contener dígitos")
                return;
            }
            if (!validarCantidad(_Datos.movil, 8)) {
                mostrarError("Móvil debe contener 8 dígitos")
                return;
            }
        }

        if (esFechaFutura(_Datos.ingreso)) {
            mostrarError("La fecha de ingreso no debe ser mayor a la fecha actual")
            return;
        }
        if (esFechaFutura(_Datos.nacimiento)) {
            mostrarError("La fecha de nacimiento no debe ser mayor a la fecha actual")
            return;
        }

        setActualizandoDatos(true)

        const form = new FormData();
        form.append("Nombre", _Datos.nombre);
        form.append("Apellido", _Datos.apellido);
        form.append("DPI", _Datos.dpi);
        form.append("NIT", _Datos.nit);
        form.append("Telefono", _Datos.telefono);
        form.append("Ingreso", formatearFechaPHP(_Datos.ingreso));
        form.append("Egreso", formatearFechaPHP(_Datos.egreso));
        // form.append("CV", "");
        form.append("Estado", _Datos.estado);
        form.append("FechaNac", formatearFechaPHP(_Datos.nacimiento));
        form.append("Correo", _Datos.correo);
        form.append("Direccion", _Datos.direccion);
        form.append("Sexo", _Datos.sexo);
        form.append("TelefonoMovil", _Datos.movil);
        form.append("NoAfiliacionIgss", _Datos.igss);
        form.append("Licencia", _Datos.licencia);
        form.append("ProfesionDiversificado", _Datos.profesion);
        form.append("EstadoCivil", _Datos.civil);
        form.append("NombreMadre", _Datos.madre);
        form.append("NombrePadre", _Datos.padre);
        form.append("NombreConyuge", _Datos.conyuge);
        form.append("TelefonoEmergencia", _Datos.emergencia);
        form.append("TipoSangre", _Datos.sangre);
        form.append("Enfermedades", _Datos.enfermedades);
        form.append("MedicamentosAlergico", _Datos.medicamentos);
        form.append("NoCuentaBanco", _Datos.banco);
        form.append("Cargo", _Datos.puesto);

        let datos;

        while (true) {
            datos = await updatePersona(form, _Datos._dpi);
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizandoDatos(false)

        if (datos.Error) {
            if (datos.Mensaje.indexOf("DPI") != -1) mostrarError("El DPI ya existe")
            else if (datos.Mensaje.indexOf("NIT") != -1) mostrarError("El NIT ya existe")
            else mostrarError(datos.Mensaje);
            return;
        }

        const nuevasPersonas = [...personas];
        for (let i = 0; i < nuevasPersonas.length; i++) {
            if (nuevasPersonas[i].IDPersona == _Datos.persona) {
                nuevasPersonas[i] = { ...nuevasPersonas[i], ...datos.Mensaje }
                break;
            }
        }

        setPersonas(nuevasPersonas);

        cerrarModal();
        mostrarOK("Actualizado con éxito");
    }

    const agregarPersona = async (_Datos) => {

        if (propiedadVacia(_Datos, [
            'igss',
            'licencia',
            'conyuge',
            'madre',
            'padre',
            'enfermedades',
            'medicamentos',
            'banco',
            'movil'
        ])) {
            return;
        }

        if (!validarDigitos(_Datos.dpi)) {
            mostrarError("DPI solo puede contener dígitos")
            return;
        }
        if (!validarCantidad(_Datos.dpi, 13)) {
            mostrarError("DPI debe contener 13 dígitos")
            return;
        }

        if (!validarDigitos(_Datos.telefono)) {
            mostrarError("Teléfono solo puede contener dígitos")
            return;
        }
        if (!validarCantidad(_Datos.telefono, 8)) {
            mostrarError("Teléfono debe contener 8 dígitos")
            return;
        }

        if (_Datos.movil != "" && _Datos.movil != null) {
            if (!validarDigitos(_Datos.movil)) {
                mostrarError("Móvil solo puede contener dígitos")
                return;
            }
            if (!validarCantidad(_Datos.movil, 8)) {
                mostrarError("Móvil debe contener 8 dígitos")
                return;
            }
        }

        if (esFechaFutura(_Datos.ingreso)) {
            mostrarError("La fecha de ingreso no debe ser mayor a la fecha actual")
            return;
        }
        if (esFechaFutura(_Datos.nacimiento)) {
            mostrarError("La fecha de nacimiento no debe ser mayor a la fecha actual")
            return;
        }

        setActualizandoDatos(true)

        const form = new FormData();
        form.append("Nombre", _Datos.nombre);
        form.append("Apellido", _Datos.apellido);
        form.append("DPI", _Datos.dpi);
        form.append("NIT", _Datos.nit);
        form.append("Telefono", _Datos.telefono);
        form.append("Ingreso", formatearFechaPHP(_Datos.ingreso));
        // form.append("Egreso", null);
        // form.append("CV", "");
        form.append("Estado", _Datos.estado);
        form.append("FechaNac", formatearFechaPHP(_Datos.nacimiento));
        form.append("Correo", _Datos.correo);
        form.append("Direccion", _Datos.direccion);
        form.append("Sexo", _Datos.sexo);
        form.append("TelefonoMovil", _Datos.movil);
        form.append("NoAfiliacionIgss", _Datos.igss);
        form.append("Licencia", _Datos.licencia);
        form.append("ProfesionDiversificado", _Datos.profesion);
        form.append("EstadoCivil", _Datos.civil);
        form.append("NombreMadre", _Datos.madre);
        form.append("NombrePadre", _Datos.padre);
        form.append("NombreConyuge", _Datos.conyuge);
        form.append("TelefonoEmergencia", _Datos.emergencia);
        form.append("TipoSangre", _Datos.sangre);
        form.append("Enfermedades", _Datos.enfermedades);
        form.append("MedicamentosAlergico", _Datos.medicamentos);
        form.append("NoCuentaBanco", _Datos.banco);
        form.append("Cargo", _Datos.puesto);

        let datos;

        while (true) {
            datos = await insertPersona(form);
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizandoDatos(false)

        if (datos.Error) {
            if (datos.Mensaje.indexOf("DPI") != -1) mostrarError("El DPI ya existe")
            else if (datos.Mensaje.indexOf("NIT") != -1) mostrarError("El NIT ya existe")
            else mostrarError(datos.Mensaje);
            return;
        }

        const nuevasPersonas = [datos.Mensaje, ...personas];
        setPersonas(nuevasPersonas)

        cerrarModal();
        mostrarOK("Creada con éxito");

    }

    const agregarUsuario = async (_Datos) => {

        if (propiedadVacia(_Datos, [])) return;

        setActualizandoDatos(true)

        const form = new FormData();
        form.append("Usuario", _Datos.usuario);
        form.append("Rol", _Datos.rol);

        let datos;

        while (true) {
            datos = await insertUsuario(form, _Datos.dpi);
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizandoDatos(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevasPersonas = [...personas];
        for (let i = 0; i < nuevasPersonas.length; i++) {
            if (nuevasPersonas[i].DPI == _Datos.dpi) {
                nuevasPersonas[i].Usuario = _Datos.usuario;
                nuevasPersonas[i].IDRol = parseInt(_Datos.rol)
                break;
            }
        }

        setPersonas(nuevasPersonas);

        cerrarModal()
        mostrarOK("Creado con éxito");

        setContraseña(datos.Mensaje.Contraseña);
        abrirModal("contraseña");

    }

    const editarUsuario = async (_Datos) => {

        setActualizandoDatos(true);

        const form = new FormData();
        form.append("Usuario", _Datos.usuario);
        form.append("Estado", _Datos.activo);
        form.append("Rol", _Datos.rol);

        let datos;

        while (true) {
            datos = await updateUsuario(form, _Datos.dpi);
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizandoDatos(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevasPersonas = [...personas];
        for (let i = 0; i < nuevasPersonas.length; i++) {
            if (nuevasPersonas[i].DPI == _Datos.dpi) {
                nuevasPersonas[i].Usuario = _Datos.usuario;
                nuevasPersonas[i].EstadoUsuario = _Datos.activo
                nuevasPersonas[i].IDRol = _Datos.rol
                break;
            }
        }

        setPersonas(nuevasPersonas);

        cerrarModal()
        mostrarOK("Actualizado con éxito");

    }

    const pressOK = (_Datos) => {
        switch (_Datos._modo) {
            case 'Nueva Persona':
                agregarPersona(_Datos)
                break;
            case 'Editar Persona':
                editarPersona(_Datos);
                break;
            case 'Crear Usuario':
                agregarUsuario(_Datos);
                break;
            case 'Editar Usuario':
                editarUsuario(_Datos);
                break;
            default:
                break;
        }
    }

    const pressCancel = () => {
        cerrarModal();
    }

    const abrirAgregarPersona = () => {
        setPlantillaForm(df_NuevaPersona(selects))
        setTitulo("Nueva Persona");
        setBotonOK("Agregar")
        setEditable(true);
        abrirModal("formulario");
    }

    const abrirEditarPersona = (_Datos) => {
        setPlantillaForm(df_EditarPersona(_Datos, selects))
        setTitulo("Editar Persona");
        setBotonOK("Guardar")
        setEditable(true);
        abrirModal("formulario");
    }

    const detallesPersona = (_Datos) => {
        setPlantillaForm(df_DetallePersona(_Datos))
        setTitulo("Detalles");
        setBotonOK("Cerrar")
        setEditable(false);
        abrirModal("formulario");
    }

    const abrirModalBorrar = (_Persona) => {
        setDPI(_Persona.DPI)
        abrirModal("borrar")
    }

    const abrirModalTareas = (_Datos) => {
        setDPI(_Datos.DPI)
        abrirModal("tareas");
    }

    const abrirModalProyectos = (_Datos) => {
        setDPI(_Datos.DPI)
        abrirModal("proyectos");
    }

    const abrirModalPermisos = (_Datos) => {
        setDPI(_Datos.DPI)
        abrirModal("permisos");
    }

    const abrirModalArchivos = (_Datos) => {
        setDPI(_Datos.DPI)
        abrirModal("archivos");
    }

    const abrirModalUsuarios = (_Datos, _Modo) => {
        setTituloUsuario(_Modo);
        setDPI(_Datos.DPI)
        setEditable(true);

        if (_Modo == "Crear Usuario") {
            setBotonUsuario("Crear")
            setPlantillaUsuario(df_NuevoUsuario(_Datos.DPI, roles))
        }
        else {
            setBotonUsuario("Guardar")
            setPlantillaUsuario(df_EditarUsuario(_Datos.DPI, _Datos.Usuario, _Datos.EstadoUsuario, `${_Datos.IDRol}`, roles))
        }

        abrirModal("usuarios");
    }

    const cargarDatosPersonas = async () => {
        let datos;

        while (true) {
            datos = await getPersonas();
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return false;
        }

        setPersonas(datos.Mensaje.Personas.reverse())
        return true;
    }

    const cargarDatosSelects = async () => {
        let datos;

        while (true) {
            datos = await getSelectsPersonas();
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return false;
        }

        setSelects(datos.Mensaje)

        return true;
    }

    const cargarDatosProyectos = async () => {
        let datos;

        while (true) {
            datos = await getDatos('/admin/project');
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return false;
        }

        const nuevosProyectos = [];

        datos.Data.forEach(_Proyecto => {
            nuevosProyectos.push({
                opcion: _Proyecto.Proyecto,
                id: _Proyecto.ProyectoID
            })
        });

        setProyectos(nuevosProyectos)

        return true;
    }

    const cargarDatosRoles = async () => {
        let datos;

        while (true) {
            datos = await getDatos('/role');
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return false;
        }

        const nuevosRoles = [];

        datos.Mensaje.forEach(_Rol => {
            nuevosRoles.push({
                opcion: _Rol.Rol,
                id: _Rol.IDRol
            })
        });

        setRoles(nuevosRoles)

        return true;
    }

    const cargarPersonas = async (_Datos) => {

        setCargando(true)

        const resultados = await Promise.all([
            cargarDatosPersonas(),
            cargarDatosSelects(),
            cargarDatosProyectos(),
            cargarDatosRoles()
        ])

        for (let i = 0; i < resultados.length; i++) {
            if (resultados[i] == false) return
        }

        setCargando(false);
    }

    const buscarCampo = (e) => {
        setBuscador(e.target.value)
    }

    const limpiarBuscador = () => {
        setBuscador("");
    }

    const cambiarContraseña = async (_Datos) => {

        setContraseña("Generando Contraseña...");

        let datos;

        while (true) {
            datos = await resetUsuario(_Datos.DPI);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setContraseña(datos.Contraseña)
        abrirModal("contraseña");

    }

    const borrarPersona = async (_Borrar) => {
        console.log(dpi)

        if (!_Borrar) {
            cerrarModal();
            return;
        }

        let datos;

        while (true) {
            datos = await deleteDatos(dpi, '/person');
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            cerrarModal()
            return;
        }

        const nuevasPersonas = [...personas];
        for (let i = 0; i < nuevasPersonas.length; i++) {
            if (nuevasPersonas[i].DPI == dpi) {
                nuevasPersonas.splice(i, 1);
                break;
            }
        }

        setPersonas(nuevasPersonas);
        mostrarOK("Persona eliminada con éxito");
        cerrarModal();


    }

    useEffect(() => {
        cargarPersonas();
    }, [])

    const SelectorVista = () => {
        switch (vista) {
            case "contraseña":
                return (
                    <>
                        <p style={{ fontSize: "1.3em" }}>Contraseña Temporal</p>
                        <p style={{ fontSize: "1.1em", color: "rgb(117, 117, 117)", fontFamily: "Montserrat" }}>{contraseña}</p>
                    </>
                )
            case 'usuarios':
                return <VistaUsuario
                    titulo={tituloUsuario}
                    editable={editable}
                    actualizando={actualizandoDatos}
                    botonOK={{ titulo: botonUsuario, press: pressOK }}
                    botonCancel={cerrarModal}
                    secciones={plantillaUsuario}
                />
            case 'archivos':
                return (
                    <div style={{ backgroundColor: "white", borderRadius: "0.5em" }}>
                        <ModalArchivos dpi={dpi} />
                    </div>
                );
            case 'permisos':
                return (
                    <div style={{ backgroundColor: "white", borderRadius: "0.5em" }}>
                        <ModalPermisos dpi={dpi} />
                    </div>
                )
            case 'proyectos':
                return (
                    <div style={{ backgroundColor: "white", borderRadius: "0.5em" }}>
                        <ModalProyectos rol={perfilUsuario.Rol} dpi={dpi} listaproyectos={proyectos} />
                    </div>
                )
            case 'tareas':
                return (
                    <div style={{ backgroundColor: "white", borderRadius: "0.5em" }}>
                        <Tareas dpi={dpi} />
                    </div>
                )
            case 'formulario':
                return (
                    <div style={{ backgroundColor: "white", borderRadius: "0.5em" }}>
                        <DynamicForm
                            titulo={titulo}
                            editable={editable}
                            actualizando={actualizandoDatos}
                            botonOK={{ titulo: botonOK, press: pressOK }}
                            botonCancel={pressCancel}
                            secciones={plantillaForm}
                        />
                    </div>
                )
            case "adminpermisos":
                return <AdminPermisos />;
            case 'borrar':
                return <ModalBorrar borrar={borrarPersona} />
            default:
                return null
        }
    }

    const subirFoto = async (_DPI, _Imagen, _Completado) => {
        const form = new FormData();
        form.append("imagen", _Imagen);

        let datos;

        while (true) {
            datos = await insertDatos(form, `/storePhoto/${_DPI}`);
            if (datos != null) break;
            await sleepear(5000);
        }

        _Completado()

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevasPersonas = [...personas];
        for (let i = 0; i < nuevasPersonas.length; i++) {
            if (nuevasPersonas[i].DPI == _DPI) {
                nuevasPersonas[i].Foto = datos.Mensaje;
                break;
            }
        }

        setPersonas(nuevasPersonas);
        mostrarOK("Foto actualizada con éxito")

    }

    return (
        <LoadWrap cargando={cargando} titulo="Personas">

            {/* Estadisticas */}

            <div style={{ paddingLeft: "0.75em", paddingRight: "0.75em" }}>
                <p style={{ marginTop: 0, marginBottom: "0.5em", marginRight: "1em", color: "#333431", fontFamily: "Montserrat", fontSize: "2em", fontWeight: "500" }}>Estadísticas</p>
            </div>

            <div className="row igualCol" style={{ color: "#333431" }}>
                <CardEstados personas={personas} />
                <CardRoles personas={personas} />
            </div>

            <div style={{ paddingLeft: "0.75rem", paddingRight: "0.75rem" }}>

                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", marginBottom: "2em" }}>
                    <p style={{ marginTop: 0, marginBottom: 0, marginRight: "1em", color: "#333431", fontFamily: "Montserrat", fontSize: "2em", fontWeight: "500" }}>Lista de personas</p>
                    {
                        permitirVer(perfilUsuario.Rol, ["Administrador"]) &&
                        <>
                            <a onClick={abrirAgregarPersona} style={{ marginTop: "0.5em", marginBottom: "0.5em", display: "block", backgroundColor: "#0190ee", borderRadius: "2em" }} className="waves-effect waves-light btn"><i style={{ marginRight: "0.25em" }} className="material-icons left">add</i>Agregar</a>
                            <Separador tamaño="0.75em" orientacion="horizontal" />
                        </>
                    }
                    {
                        permitirVer(perfilUsuario.Rol, ["Administrador", "Coordinador de programa"]) &&
                        <a onClick={() => { abrirModal("adminpermisos") }} style={{ marginTop: "0.5em", marginBottom: "0.5em", display: "block", backgroundColor: "#e17055", borderRadius: "2em" }} className="waves-effect waves-light btn"><i style={{ marginRight: "0.25em" }} className="material-icons left">rule</i>Permisos</a>
                    }
                </div>

                <div className="miniSombra" style={{ backgroundColor: "white", borderRadius: "0.5em", marginBottom: "2em" }}>

                    <div style={{ paddingTop: "2em", paddingBottom: "1.3em", paddingLeft: "1em", paddingRight: "1em" }}>
                        <div style={{ paddingTop: "0.25em", paddingBottom: "0.25em", paddingLeft: "1em", paddingRight: "1em", display: "flex", flexDirection: "row", alignItems: "center", border: "0.2em solid #d8d8d8", width: "100%", borderRadius: "2em" }}>
                            <ion-icon style={{ color: "#9f9f9f", fontSize: "1.5em", marginRight: "0.5em" }} name="search-outline"></ion-icon>
                            <input value={buscador} onChange={buscarCampo} placeholder="Buscar" style={{ flex: 1, margin: 0 }} type="text" className="Personas_input"></input>
                            <div onClick={limpiarBuscador} className='botonFlatWrap' style={{ display: "flex", alignItems: "center" }}>
                                <ion-icon style={{ color: "#9f9f9f", fontSize: "1.5em" }} name="close-outline"></ion-icon>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: "100%", overflow: "auto" }}>
                        <table ref={contRef} className=" Personas_Tabla centered">
                            <thead style={{ backgroundColor: "#efefef" }}>
                                <tr>
                                    <HeaderTabla titulo="Foto" />
                                    <HeaderTabla titulo="Nombre" />
                                    <HeaderTabla titulo="Puesto" />
                                    <HeaderTabla titulo="Título" />
                                    <HeaderTabla titulo="Contratación" />
                                    <HeaderTabla titulo="Estado" />
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <ListarPersonas
                                    buscador={buscador}
                                    personas={personas}
                                    editar={abrirEditarPersona}
                                    detalles={detallesPersona}
                                    tareas={abrirModalTareas}
                                    proyectos={abrirModalProyectos}
                                    usuario={abrirModalUsuarios}
                                    contraseña={cambiarContraseña}
                                    permisos={abrirModalPermisos}
                                    archivos={abrirModalArchivos}
                                    contenedor={contRef}
                                    rol={perfilUsuario.Rol}
                                    foto={subirFoto}
                                    borrar={abrirModalBorrar}
                                />
                            </tbody>
                        </table>
                    </div>


                </div>

            </div>

            {/* Modal */}
            <FullModal
                abierto={estadoModal}
                cerrar={cerrarModal}>
                <SelectorVista />
            </FullModal>



        </LoadWrap>
    );
}

export default Personas;