import React, { useState, useEffect } from 'react';
import ConfirmarBorrar from '../../../componentes/ConfirmarBorrar/ConfirmarBorrar';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';
import { deleteTareaAdminUser, formatearFecha, formatearFechaPHP, getTareasAdminUser, insertTareaAdminUser, mostrarError, mostrarOK, propiedadVacia, sleepear, updateTareaAdminUser } from '../../../librerias/funciones';
import { df_NuevaTarea } from '../../../librerias/plantillasforms';

const Titulo = (_props) => {
    return (
        <span style={{ verticalAlign: "middle", color: "rgb(167, 167, 167)", fontSize: _props.mini ? "0.75em" : "0.85em", marginRight: "0.5em" }}>{_props.texto}</span>
    );
}

const Texto = (_props) => {
    return (
        <span style={{ fontWeight: _props.bold ? 500 : 400, verticalAlign: "middle", color: "rgb(61, 62, 59)", fontSize: _props.mini ? "0.85em" : "1em", fontFamily: _props.swap ? "unset" : "Montserrat" }}>{_props.texto}</span>
    );
}

const Descripcion = (_props) => {
    return (
        <span>
            {_props.titulo == "" ? null : <Titulo estado={_props.estado} swap={_props.swap} mini={_props.mini} texto={_props.titulo} />}
            <Texto estado={_props.estado} bold={_props.bold} swap={_props.swap} mini={_props.mini} texto={_props.descripcion} />
        </span>
    );
}

const VistaEditar = ({ tarea, editar, regresar }) => {

    const [actualizando, setActualizando] = useState(false)

    const funcionEditar = (_Datos) => {
        setActualizando(true)
        editar(_Datos, (_OK) => {
            setActualizando(false)
            if (_OK) regresar();
        })
    }

    return (
        <DynamicForm
            titulo=""
            editable={true}
            actualizando={actualizando}
            botonOK={{ titulo: "Guardar", press: funcionEditar }}
            botonCancel={regresar}
            // noCancel
            secciones={
                [
                    {
                        seccion: "",
                        campos: [
                            {
                                identificador: "id",
                                inicial: tarea.IDTarea,
                                tipo: "identificador",
                            },
                            {
                                campo: "Tarea",
                                identificador: "tarea",
                                inicial: tarea.Tarea,
                                tipo: "text",
                                tamaño: "s12"
                            },
                            {
                                campo: "Descripción",
                                identificador: "descripcion",
                                inicial: tarea.Descripcion,
                                tipo: "area",
                                tamaño: "s12"
                            },
                            {
                                campo: "Desde",
                                identificador: "desde",
                                inicial: tarea.Inicio,
                                tipo: "fecha",
                                tamaño: "s12 m6"
                            },
                            {
                                campo: "Hasta",
                                identificador: "hasta",
                                inicial: tarea.Fin,
                                tipo: "fecha",
                                tamaño: "s12 m6"
                            }
                        ]
                    }
                ]
            }
        />
    )
}

const Detalles = ({ tarea, borrar, editar }) => {

    return (
        <>
            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                    <Descripcion titulo="" descripcion={tarea.Tarea} />
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                    {
                        !tarea.Estado &&
                        <>
                            <div onClick={borrar} className="noHighLightWrapper botonFlatWrap">
                                <ion-icon className="noHighLight" style={{ marginLeft: "0.5em", marginRight: "0.5em", cursor: "pointer", color: 'rgb(243, 91, 38)', fontSize: "1.5em" }} name="trash-outline" />
                            </div>
                            <div onClick={editar} className="noHighLightWrapper botonFlatWrap">
                                <ion-icon className="noHighLight" style={{ marginRight: "0.5em", cursor: "pointer", color: 'rgb(0, 153, 242)', fontSize: "1.5em" }} name="create-outline" />
                            </div>
                        </>
                    }
                    <div className="noHighLightWrapper">
                        {tarea.Estado ?
                            <ion-icon className="noHighLight" style={{ position: "relative", top: "0.06em", color: 'rgb(26, 188, 156)', fontSize: "1.5em" }} name="checkmark-circle-outline" />
                            :
                            <ion-icon className="noHighLight" style={{ position: "relative", top: "0.06em", color: 'rgb(162, 162, 162)', fontSize: "1.5em" }} name="ellipse-outline" />
                        }
                    </div>

                </div>
            </div>
            <Descripcion mini titulo="Descripción: " descripcion={tarea.Descripcion} />
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <div style={{ flex: 1 }}><Descripcion mini titulo="Desde: " descripcion={tarea.Inicio == null ? "N/D" : formatearFecha(tarea.Inicio)} /></div>
                <div style={{ flex: 1 }}><Descripcion mini titulo="Hasta: " descripcion={tarea.Fin == null ? "N/D" : formatearFecha(tarea.Fin)} /></div>
            </div>
        </>
    )
}

const Borrar = ({ id, cancelar, borrar }) => {

    const funcionBorrar = (_Borrar) => {
        if (_Borrar) {
            borrar(id, (_OK) => {
                if (!_OK) cancelar()
            })
        }
        else cancelar();
    }

    return (
        <div className="row">
            <ConfirmarBorrar titulo="Borrar Tarea" descripcion="¿Seguro que desea borrar la tarea? Esta acción no se puede deshacer." onClick={funcionBorrar} />
        </div>
    )
}

const EntradaTarea = ({ tarea, borrar, editar }) => {

    const [vista, setVista] = useState("Detalles")

    const SelectorVista = () => {
        switch (vista) {
            case "Detalles":
                return (
                    <Detalles
                        tarea={tarea}
                        borrar={() => { setVista("Borrar") }}
                        editar={() => { setVista("Editar") }}
                    />
                )
            case "Borrar":
                return (
                    <Borrar
                        id={tarea.IDTarea}
                        cancelar={() => { setVista("Detalles") }}
                        borrar={borrar}
                    />
                )

            case "Editar":
                return (
                    <VistaEditar
                        tarea={tarea}
                        editar={editar}
                        regresar={() => { setVista("Detalles") }}
                    />
                )

            default:
                return null;
        }
    }

    return (
        <>
            <SelectorVista />
            <div style={{ marginTop: "1em", marginBottom: "1em" }} className="divider"></div>
        </>
    )
}

const ListarTareas = ({ tareas, borrar, editar }) => {
    return tareas.map((_Tarea, _Key) => {
        return (
            <EntradaTarea
                key={_Tarea.IDTarea}
                tarea={_Tarea}
                // id={_Tarea.IDTarea}
                // abrirEditarTarea={abrirEditarTarea}
                borrar={borrar}
                editar={editar}
            />
        );
    })
}

const Tareas = ({ dpi }) => {

    const [actualizando, setActualizando] = useState(false);
    const [tareas, setTareas] = useState([])
    const [cargando, setCargando] = useState(true)
    const [agregar, setAgregar] = useState(false);

    const agregarTarea = async (_Datos) => {

        if (propiedadVacia(_Datos, [])) return;

        setActualizando(true);

        const form = new FormData();
        form.append("Tarea", _Datos.tarea);
        form.append("Descripcion", _Datos.descripcion);
        form.append("Inicio", formatearFechaPHP(_Datos.desde));
        form.append("Fin", formatearFechaPHP(_Datos.hasta));

        let datos;

        while (true) {
            datos = await insertTareaAdminUser(form, dpi);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevasTareas = [datos.Mensaje, ...tareas];

        setTareas(nuevasTareas);
        mostrarOK("Agregado con éxito");
        setActualizando(false)
        setAgregar(false)
    }

    const cargarTareas = async () => {
        if (dpi == null) return;

        setCargando(true);
        let datos;

        while (true) {
            datos = await getTareasAdminUser(dpi);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setCargando(false)
        setTareas(datos.Mensaje.reverse())

    }

    const borrarTarea = async (_ID, _Error) => {

        let datos;

        while (true) {
            datos = await deleteTareaAdminUser(_ID);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            _Error();
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevasTareas = [...tareas];
        for (let i = 0; i < nuevasTareas.length; i++) {
            if (nuevasTareas[i].IDTarea == _ID) {
                nuevasTareas.splice(i, 1);
                break;
            }
        }

        setTareas(nuevasTareas);
        mostrarOK("Eliminado con éxito");
    }

    const editarTarea = async (_Datos, _Completado) => {

        if (propiedadVacia(_Datos, ["estado", "_modo"])) {
            _Completado(false)
            return;
        }

        const form = new FormData();
        form.append("Tarea", _Datos.tarea);
        form.append("Descripcion", _Datos.descripcion);
        form.append("Inicio", formatearFechaPHP(_Datos.desde));
        form.append("Fin", formatearFechaPHP(_Datos.hasta));
        // form.append("Estado", _Datos.estado);

        let datos;

        while (true) {
            datos = await updateTareaAdminUser(form, _Datos.id);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            _Completado(false);
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevasTareas = [...tareas];
        for (let i = 0; i < nuevasTareas.length; i++) {
            if (nuevasTareas[i].IDTarea == _Datos.id) {
                nuevasTareas[i] = datos.Mensaje;
                nuevasTareas[i].Estado = parseInt(nuevasTareas[i].Estado)
                break;
            }
        }

        setTareas(nuevasTareas);
        _Completado(true);
        mostrarOK("Actualizado con éxito");
    }

    const toglearAgregar = () => {
        setAgregar(!agregar);
    }

    useEffect(() => {
        cargarTareas();
    }, [dpi])

    return (
        cargando ?
            <>
                <center style={{ padding: "1em" }}>
                    <div className="progress" style={{ backgroundColor: "rgba(162, 162, 162,0.25)", margin: 0, width: "100%" }}>
                        <div style={{ backgroundColor: "#3f92ba" }} className="indeterminate"></div>
                    </div>
                </center>
            </>
            :
            <>
                {
                    agregar ?
                        <DynamicForm
                            titulo="Agregar Tarea"
                            editable={true}
                            actualizando={actualizando}
                            botonOK=
                            {
                                {
                                    titulo: "Agregar",
                                    press: agregarTarea
                                }
                            }
                            botonCancel={toglearAgregar}
                            secciones={df_NuevaTarea()}
                        />
                        :
                        <div className="row" style={{ margin: 0 }}>
                            <div className="col s12">
                                <p style={{ fontSize: "1.3em", marginTop: 0, marginBottom: "0.5em" }}>Nueva Tarea</p>
                                <a onClick={toglearAgregar} className="noHighLight botonFlat" style={{ cursor: "pointer", display: "flex", alignItems: 'center' }}>
                                    <ion-icon style={{ fontSize: "1.5em", marginRight: "0.5em" }} name="add-circle-outline"></ion-icon>
                                Agregar</a>
                            </div>
                        </div>
                }

                <div className="row" style={{ margin: 0 }}>
                    <div className="col s12">
                        <p style={{ fontSize: "1.3em" }}>Listado de Tareas</p>
                        <ListarTareas
                            tareas={tareas}
                            borrar={borrarTarea}
                            editar={editarTarea} />
                    </div>
                </div>
            </>

    );
}

export default Tareas;