const Vinculos = [
    ["Inicio", "/", "home-outline", []],
    ["Personas", "/personas", "people-outline", ['Administrador', 'Coordinador de programa', 'Responsable de proyecto']],
    ["Evaluaciones", "/evaluaciones", "clipboard-outline", ['Administrador', 'Coordinador de programa', 'Responsable de proyecto']],
    ["Reclutamiento", "/reclutamiento", "megaphone-outline", ['Administrador']],
    ["Proyectos", "/proyectos", "albums-outline", ['Administrador', 'Coordinador de programa', 'Responsable de proyecto']],
    ["Reportes", "/reportes", "newspaper-outline", ['Administrador', 'Coordinador de programa', 'Responsable de proyecto']],
];

export {
    Vinculos
}