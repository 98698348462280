import React, { useEffect, useState } from 'react';
import WrapperHistorial from './WrapperHistorial';
import { formatearFecha, formatearNombre, generarVinculoFoto, getProyectos, mostrarError, sleepear } from '../../../librerias/funciones';
import FullModal from '../../../componentes/FullModal/FullModal';
import Separador from '../../../componentes/Separador/Separador';

const TituloLicencia = (_props) => {
    return (
        <span style={{ verticalAlign: "middle", color: "rgb(167, 167, 167)", fontSize: _props.mini ? "0.75em" : "0.85em", marginRight: "0.5em" }}>{_props.texto}</span>
    );
}

const TextoLicencia = (_props) => {
    const color = _props.texto == "Activo" ? "#1abc9c" : "rgb(112, 112, 112)";
    return (
        <span style={{ textAlign: "justify", verticalAlign: "middle", color: color, fontSize: _props.mini ? "0.85em" : "1em", fontFamily: _props.swap ? "unset" : "Montserrat" }}>{_props.texto}</span>
    );
}

const DescripcionLicencia = (_props) => {
    return (
        <span>
            {_props.titulo == "" ? null : <TituloLicencia estado={_props.estado} swap={_props.swap} mini={_props.mini} texto={_props.titulo} />}
            <TextoLicencia estado={_props.estado} swap={_props.swap} mini={_props.mini} texto={_props.descripcion} />
        </span>
    );
}

const formatoFechaFinal = (_Fecha) => {
    if (_Fecha == "" || _Fecha == null) return "N/D";
    else return formatearFecha(_Fecha);
}

const EntradaProyecto = (_props) => {

    const color = _props.estado ? "#1abc9c" : "rgb(112, 112, 112)";

    const mostrarInformacion = () => {
        _props.informacion({
            titulo: _props.titulo,
            motivo: _props.motivo,
            personas: _props.personas
        });
    }

    return (
        <div className="microSombra" style={{ marginTop: "1.3em", marginBottom: "1.3em", borderLeft: `0.5em solid ${color}`, width: "100%", backgroundColor: "white", borderRadius: "0.5em", padding: "1em" }}>
            <div className="row" style={{ margin: 0, padding: 0 }}>
                <div style={{ padding: 0 }} className="col s12">
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ flex: 1 }}>
                            <DescripcionLicencia mini estado titulo="Estado: " descripcion={_props.estado ? "Activo" : "Inactivo"} />
                        </div>
                        <div style={{ marginLeft: "0.5em", marginRight: "0.5em" }} className="noHighLightWrapper botonFlatWrap">
                            <ion-icon onClick={mostrarInformacion} className="noHighLight" style={{ cursor: "pointer", color: 'rgb(0, 153, 242)', fontSize: "1.5em" }} name="open-outline" />
                        </div>
                    </div>
                </div>
                <div style={{ padding: 0 }} className="col s12">
                    <DescripcionLicencia titulo="" descripcion={_props.titulo} />
                </div>
                {/* <div style={{ padding: 0 }} className="col s12">
                    <DescripcionLicencia mini titulo="Descripción: " descripcion={_props.motivo} />
                </div> */}
                <div style={{ padding: 0 }} className="col s12 m6">
                    <DescripcionLicencia mini titulo="Desde: " descripcion={`${formatearFecha(_props.desde)}`} />
                </div>
                {/* <div style={{ padding: 0 }} className="col s12 m6">
                    <DescripcionLicencia mini titulo="Iniciando: " descripcion={_props.iniciando} />
                </div> */}
                <div style={{ padding: 0 }} className="col s12 m6">
                    <DescripcionLicencia mini titulo="Hasta: " descripcion={formatoFechaFinal(_props.hasta)} />
                </div>
                {/* <div style={{ padding: 0 }} className="col s12 m6">
                    <DescripcionLicencia mini titulo="Finalizando: " descripcion={_props.finalizando} />
                </div> */}
            </div>



        </div>
    );
}

const EntradaPersona = ({ persona }) => {
    return (
        <div style={{ marginTop: "0.5em", marginBottom: "0.5em", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
            <img className="z-depth-1" src={generarVinculoFoto(persona.Foto)} alt="" width="60em" height="60em" style={{ backgroundColor: "rgb(184, 183, 180)", borderRadius: "30em", marginRight: "2em", objectFit: "cover" }} />
            <div style={{ flex: 1 }}>
                <p style={{ margin: 0, fontWeight: "400", fontSize: "1.1em" }}>{formatearNombre(`${persona.Nombre},${persona.Apellido}`)}</p>
                <div style={{ width: "100%", flexWrap: "wrap", display: "flex", flexDirection: "row" }}>
                    <p style={{ margin: 0, color: "rgb(117, 117, 117)" }}><span style={{ fontSize: "0.85em" }}>Puesto:</span> <strong style={{ fontSize: "0.95em" }}>{persona.Puesto}</strong></p>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <p style={{ margin: 0, color: "rgb(117, 117, 117)" }}><span style={{ fontSize: "0.85em" }}>Tel.</span> <strong style={{ fontSize: "0.95em" }}>{persona.Telefono}</strong></p>
                </div>
            </div>
        </div>
    );
}

const ListadoPersonas = ({ personas }) => {
    if (personas == null) return null;
    return (
        personas.map((_Persona, _Key) => {
            return (
                <EntradaPersona persona={_Persona} key={_Key} />
            )
        })
    );
}

const HistorialProyectos = () => {

    const [cargando, setCargando] = useState(true);
    const [proyectos, setProyectos] = useState([]);
    const [persona, setPersona] = useState({});

    // Modal
    const [abierto, setAbierto] = useState(false);

    const abrirModal = () => {
        setAbierto(true);
    }

    const cerrarModal = () => {
        setAbierto(false);
    }

    const mostrarInformacion = (_Persona) => {
        setPersona(_Persona)
        abrirModal();
    }

    // Fin Modal

    const cargarProyectos = async () => {

        let datos;

        while (true) {
            datos = await getProyectos();
            if (datos != null) {
                if (!datos.Error) break;
                else {
                    mostrarError(datos.Mensaje);
                    await sleepear(15000);
                }
            }
            else {
                await sleepear(5000);
            }
        }

        setCargando(false);
        setProyectos(datos.Mensaje.reverse())
    }

    useEffect(() => {
        cargarProyectos();
    }, [])

    const ListarProyectos = () => {
        return proyectos.map((_Proyecto, _Key) => {
            return (
                <EntradaProyecto
                    key={_Proyecto.ProyectoID}
                    desde={_Proyecto.Inicio}
                    hasta={_Proyecto.Fin}
                    titulo={_Proyecto.Proyecto}
                    motivo={_Proyecto.Descripcion}
                    estado={_Proyecto.Estado}
                    personas={_Proyecto.personas}
                    informacion={mostrarInformacion}
                />
            )
        })
    }

    return (
        <WrapperHistorial cargando={cargando}>
            <ListarProyectos />

            <FullModal
                abierto={abierto}
                cerrar={cerrarModal}>
                <div className="" style={{ backgroundColor: "white", borderRadius: "0.5em" }}>
                    <p style={{ fontSize: "1.3em", marginTop: 0, marginBottom: "0.5em" }}>
                        Información
                </p>
                    <p style={{ fontSize: "1.1em", marginTop: 0, marginBottom: "0.5em" }}>
                        {persona.titulo}
                    </p>
                    <p style={{ marginTop: 0, color: "rgb(117, 117, 117)", fontFamily: "Montserrat" }}>{persona.motivo}</p>

                    <div className="divider"></div>
                    <ListadoPersonas personas={persona.personas} />
                </div>
            </FullModal>
        </WrapperHistorial>
    );
}

export default HistorialProyectos;