import React, { useState, useEffect, useRef } from 'react';
import BarraIconos from '../../../../componentes/BarraIconos.js/BarraIconos';
import ConfirmarBorrar from '../../../../componentes/ConfirmarBorrar/ConfirmarBorrar';
import { DropIcono, DropOpcion } from '../../../../componentes/DropIcono.js/DropIcono';
import DynamicForm from '../../../../componentes/DynamicForm/DynamicForm';
import MiniLoaderWrap from '../../../../componentes/MiniLoaderWrap/MiniLoaderWrap';
import { deleteDatos, getDatos, insertDatos, mostrarError, mostrarOK, propiedadVacia, sleepear, updateDatos } from '../../../../librerias/funciones';
import ModalOpciones from './ModalOpciones';

const VistaBorrar = ({ pregunta, cancelar, borrar }) => {

    const funcionBorrar = (_Borrar) => {
        if (_Borrar) {
            borrar(pregunta, (_OK) => {
                if (!_OK) cancelar()
            })
        }
        else cancelar();
    }

    return (
        <div className="row" style={{ margin: 0, marginBottom: "0.5em" }}>
            <ConfirmarBorrar titulo="Borrar Pregunta" descripcion="¿En verdad desea borrar la pregunta? Esta acción no se puede deshacer." onClick={funcionBorrar} />
        </div>
    )
}

const VistaDetalle = ({ pregunta, mostrarBorrar, mostrarEditar, mostrarRespuestas }) => {
    const contenedor = useRef();

    return (
        <div ref={contenedor} style={{ marginTop: "0.5em", padding: "1em", borderLeft: "solid 0.5em #00cec9", marginBottom: "0.5em", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
                <p style={{ margin: 0, fontWeight: "400", fontSize: "1.1em" }}>{pregunta.Pregunta}</p>
            </div>
            <DropIcono contenedor={contenedor} tipo="lista-preguntas" id={pregunta.IDPregunta}>
                <DropOpcion opcion="Respuestas" onClick={mostrarRespuestas} />
                <DropOpcion opcion="Editar" onClick={mostrarEditar} />
                <DropOpcion opcion="Eliminar" onClick={mostrarBorrar} />
            </DropIcono>
        </div>
    )
}

const VistaEditar = ({ pregunta, editar, regresar }) => {

    const [actualizando, setActualizando] = useState(false)

    const funcionEditar = (_Datos) => {
        setActualizando(true)
        editar(_Datos, (_OK) => {
            if (_OK) regresar();
        })
    }

    return (
        <DynamicForm
            titulo=""
            editable={true}
            actualizando={actualizando}
            botonOK={{ titulo: "Guardar", press: funcionEditar }}
            botonCancel={regresar}
            // noCancel
            secciones={
                [
                    {
                        seccion: "",
                        campos: [
                            {
                                identificador: "id",
                                inicial: pregunta.IDPregunta,
                                tipo: "identificador",
                            },
                            {
                                campo: "Pregunta",
                                identificador: "pregunta",
                                inicial: pregunta.Pregunta,
                                tipo: "text",
                                tamaño: "s12"
                            },
                        ]
                    }
                ]
            }
        />
    )
}

const EntradaPregunta = ({ pregunta, preguntas, editar, borrar, respuestas }) => {

    const [vista, setVista] = useState("Detalles")

    const SelectorVista = () => {
        switch (vista) {
            case "Detalles":
                return <VistaDetalle
                    pregunta={pregunta}
                    mostrarBorrar={() => { setVista("Borrar") }}
                    mostrarEditar={() => { setVista("Editar") }}
                    mostrarRespuestas={() => { respuestas(pregunta) }}
                />
            case "Borrar":
                return <VistaBorrar
                    pregunta={pregunta}
                    cancelar={() => { setVista("Detalles") }}
                    borrar={borrar}
                />
            case "Editar":
                return <VistaEditar
                    pregunta={pregunta}
                    editar={editar}
                    regresar={() => { setVista("Detalles") }}
                />
            default:
                return null;
        }
    }

    return (
        <>
            <SelectorVista />
            <div className="divider"></div>
        </>
    )
}

const ListarPreguntas = ({ preguntas, borrar, editar, respuestas }) => {
    return preguntas.map((_Pregunta) => {
        return <EntradaPregunta
            key={_Pregunta.IDPregunta}
            pregunta={_Pregunta}
            borrar={borrar}
            editar={editar}
            respuestas={respuestas}
        />
    })
}

const ModalPreguntas = ({ puesto }) => {

    const [preguntas, setPreguntas] = useState([])
    const [cargando, setCargando] = useState(true)
    const [estadoAgregar, setEstadoAgregar] = useState(false)
    const [actualizando, setActualizando] = useState(false)
    const [pregunta, setPregunta] = useState(null)
    const [mostrarRespuestas, setMostrarRespuestas] = useState(false)

    const cargarDatos = async () => {

        setCargando(true)

        let datos;

        while (true) {
            datos = await getDatos(`/recruitment/${puesto.IDReclutamiento}`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setPreguntas(datos.Mensaje.reverse())

        setCargando(false)
    }

    useEffect(() => {
        cargarDatos();
    }, [])

    const borrarPregunta = async (_Pregunta, _Completado) => {

        let datos;

        while (true) {
            datos = await deleteDatos(_Pregunta.IDPregunta, `/question`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            _Completado(false)
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevasPreguntas = [...preguntas];
        for (let i = 0; i < nuevasPreguntas.length; i++) {
            if (nuevasPreguntas[i].IDPregunta == _Pregunta.IDPregunta) {
                nuevasPreguntas.splice(i, 1);
                break;
            }
        }

        setPreguntas(nuevasPreguntas);
        mostrarOK("Eliminado con éxito")
    }

    const funcionEditar = async (_Datos, _Completado) => {
        if (propiedadVacia(_Datos, ["_modo"])) return;

        const form = new FormData();
        form.append("Pregunta", _Datos.pregunta);

        let datos;

        while (true) {
            datos = await updateDatos(form, _Datos.id, `/question`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            _Completado(false)
            mostrarError(datos.Mensaje);
            return;
        }

        _Completado(true);

        const nuevasPreguntas = [...preguntas];
        for (let i = 0; i < nuevasPreguntas.length; i++) {
            if (nuevasPreguntas[i].IDPregunta == _Datos.id) {
                nuevasPreguntas[i].Pregunta = _Datos.pregunta
                break;
            }
        }

        setPreguntas(nuevasPreguntas)
        mostrarOK("Actualizado con éxito")

    }

    const agregarPregunta = async (_Datos) => {

        if (propiedadVacia(_Datos, [])) return;

        const form = new FormData();
        form.append("Pregunta", _Datos.pregunta);
        form.append("Reclutamiento", puesto.IDReclutamiento);

        let datos;

        while (true) {
            datos = await insertDatos(form, `/question`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevaPregunta = {
            IDPregunta: datos.Mensaje.IDPregunta,
            Opciones: [],
            Pregunta: datos.Mensaje.Pregunta,
        }

        const nuevasPreguntas = [nuevaPregunta, ...preguntas];
        setPreguntas(nuevasPreguntas)

        mostrarOK("Agregado con éxito")
        setEstadoAgregar(false)

    }

    const funcionRespuestas = (_Pregunta) => {
        setPregunta(_Pregunta)
        setMostrarRespuestas(true)
    }

    const agregarRespuesta = (_Pregunta, _Opcion) => {
        const nuevasPreguntas = [...preguntas]

        for (let i = 0; i < nuevasPreguntas.length; i++) {
            if (nuevasPreguntas[i].IDPregunta == _Pregunta.IDPregunta) {
                nuevasPreguntas[i].Opciones = [...nuevasPreguntas[i].Opciones, _Opcion]
                break;
            }
        }

        setPreguntas(nuevasPreguntas)
        mostrarOK("Agregado con éxito")
    }

    const editarRespuesta = (_Opcion) => {
        const nuevasPreguntas = [...preguntas];

        for (let i = 0; i < nuevasPreguntas.length; i++) {
            if (nuevasPreguntas[i].IDPregunta == _Opcion.Pregunta) {
                for (let j = 0; j < nuevasPreguntas[i].Opciones.length; j++) {
                    if (nuevasPreguntas[i].Opciones[j].IDOpcion == _Opcion.IDOpcion) {
                        nuevasPreguntas[i].Opciones[j] = _Opcion
                        break;
                    }
                }
            }
        }

        setPreguntas(nuevasPreguntas)

        mostrarOK("Actualizado con éxito")
    }

    const eliminarRespuesta = (_Opcion, _Pregunta) => {
        const nuevasPreguntas = [...preguntas]

        for (let i = 0; i < nuevasPreguntas.length; i++) {
            if (nuevasPreguntas[i].IDPregunta == _Pregunta) {
                for (let j = 0; j < nuevasPreguntas[i].Opciones.length; j++) {
                    if (nuevasPreguntas[i].Opciones[j].IDOpcion == _Opcion) {
                        nuevasPreguntas[i].Opciones.splice(j, 1)
                        break;
                    }
                }
            }
        }

        setPreguntas(nuevasPreguntas)
        mostrarOK("Eliminado con éxito")

    }

    return (
        <MiniLoaderWrap cargando={cargando}>
            {mostrarRespuestas ?
                <ModalOpciones
                    pregunta={pregunta}
                    agregar={agregarRespuesta}
                    borrar={eliminarRespuesta}
                    editar={editarRespuesta}
                    regresar={() => { setMostrarRespuestas(false) }}
                />
                :
                <>
                    {
                        estadoAgregar ?
                            <DynamicForm
                                titulo="Agregar Pregunta"
                                editable={true}
                                actualizando={actualizando}
                                botonOK=
                                {
                                    {
                                        titulo: "Agregar",
                                        press: agregarPregunta
                                    }
                                }
                                botonCancel={() => { setEstadoAgregar(false) }}
                                secciones={[
                                    {
                                        seccion: "",
                                        campos: [
                                            {
                                                campo: "Pregunta",
                                                identificador: "pregunta",
                                                inicial: "",
                                                tipo: "text",
                                                tamaño: "s12"
                                            },
                                        ]
                                    }
                                ]}
                            />
                            :
                            <div className="row" style={{ margin: 0, padding: 0 }}>
                                <div className="col s12" style={{ padding: 0 }}>
                                    <p style={{ fontSize: "1.3em", marginTop: 0, marginBottom: "0.5em" }}>Nueva Pregunta</p>
                                    <a onClick={() => { setEstadoAgregar(true) }} className="noHighLight botonFlat" style={{ cursor: "pointer", display: "flex", alignItems: 'center' }}>
                                        <ion-icon style={{ fontSize: "1.5em", marginRight: "0.5em" }} name="add-circle-outline"></ion-icon>
                                Agregar</a>
                                </div>
                            </div>
                    }
                    <p style={{ fontSize: "1.3em" }}>Listado de Preguntas</p>
                    <ListarPreguntas
                        preguntas={preguntas}
                        borrar={borrarPregunta}
                        editar={funcionEditar}
                        respuestas={funcionRespuestas}
                    />
                </>
            }
        </MiniLoaderWrap>
    )
}

export default ModalPreguntas;