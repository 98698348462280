import React, { useRef, useEffect } from 'react';

const EntradaUpload = ({ boton, subir, noRemove }) => {

    const refInput = useRef();

    const archivoSeleccionado = () => {
        subir(refInput.current.files[0])
    }

    const abrirDialogo = () => {
        refInput.current.value = null;
        refInput.current.click();
    }

    useEffect(() => {

        boton.current.addEventListener('click', abrirDialogo);

        return () => {
            if (!noRemove) boton.current.removeEventListener('click', abrirDialogo);
        }
    }, [])

    return (
        <input onChange={archivoSeleccionado} ref={refInput} type="file" style={{ display: "none" }} />
    );
}

export default EntradaUpload;