import React from 'react';
import Area from './Area';
import Detalle from './Detalle';
import Dias from './Dias';
import Fecha from './Fecha';
import Hora from './Hora';
import Identificador from './Identificador';
import Select from './Select';
import Switch from './Switch';
import Texto from './Texto';

// const Entrada = () => {
//     const [campo, setCampo] = useState(objetoDefault());

//     datosCampos.current[_props.campo.identificador] = campo;

//     const dia = useRef();
//     const mes = useRef();
//     const año = useRef();

//     const fechaActual = new Date();

//     const [diaActual, setDiaActual] = useState(fechaActual.getDate());
//     const [mesActual, setMesActual] = useState(fechaActual.getMonth());
//     const [añoActual, setAñoActual] = useState(fechaActual.getFullYear());

//     const cambiarCampo = (e) => {
//         setCampo(e.target.value);
//     }

//     const cambiarDia = (e) => {
//         setDiaActual(parseInt(e.target.value))
//         setCampo({
//             dia: parseInt(e.target.value),
//             mes: mesActual,
//             año: añoActual
//         })
//     }
//     const cambiarMes = (e) => {
//         setMesActual(parseInt(e.target.value))
//         setDiaActual(1)
//         setCampo({
//             dia: 1,
//             mes: parseInt(e.target.value),
//             año: añoActual
//         })
//     }
//     const cambiarAño = (e) => {
//         setAñoActual(parseInt(e.target.value))
//         setDiaActual(1)
//         setCampo({
//             dia: 1,
//             mes: mesActual,
//             año: parseInt(e.target.value)
//         })
//     }

//     useEffect(() => {
//         if (_props.campo.tipo == "fecha") {
//             M.FormSelect.init(dia.current, {
//                 dropdownOptions: {
//                     container: contenedor.current
//                 }
//             })
//             M.FormSelect.init(mes.current, {
//                 dropdownOptions: {
//                     container: contenedor.current
//                 }
//             })
//             M.FormSelect.init(año.current, {
//                 dropdownOptions: {
//                     container: contenedor.current
//                 }
//             })
//         }
//     }, [diaActual, mesActual, añoActual])

//     // Tipos de campos

//     switch (_props.campo.tipo) {
//         case "text":
//             return (
//                 <div className={`col input-field ${_props.campo.tamaño}`} style={{ margin: 0, marginTop: "0.5em" }}>
//                     <p style={{ margin: 0, color: "#757575", fontWeight: "400" }}>{_props.campo.campo}</p>
//                     <input onChange={cambiarCampo} value={campo} className="DynamicForm_Entrada" style={{ boxSizing: "border-box", margin: 0, fontFamily: "Montserrat", fontSize: "0.95em" }} placeholder={_props.campo.campo} type={_props.campo.tipo} />
//                 </div>
//             );

//         case "area":
//             return (
//                 <div className={`col input-field ${_props.campo.tamaño}`} style={{ margin: 0, marginTop: "0.5em" }}>
//                     <p style={{ margin: 0, color: "#757575", fontWeight: "400" }}>{_props.campo.campo}</p>
//                     <textarea onChange={cambiarCampo} value={campo} placeholder={_props.campo.campo} style={{ height: "8em", maxHeight: "8em", overflowY: "auto", boxSizing: "border-box", margin: 0, fontFamily: "Montserrat", fontSize: "0.95em" }} className="materialize-textarea DynamicForm_Entrada"></textarea>
//                 </div>
//             );

//         case "fecha":
//             return (
//                 <Fecha />
//             );

//         default:
//             return null;
//     }

// }

// const objetoDefault = () => {
//     switch (_props.campo.tipo) {
//         case "fecha":
//             const fechaActual = new Date();
//             return {
//                 dia: fechaActual.getDate(),
//                 mes: fechaActual.getMonth(),
//                 año: fechaActual.getFullYear()
//             }

//         default:
//             return _props.campo.inicial;
//     }
// }

const Entrada = ({ campo, contenedor, datos, onChange }) => {

    switch (campo.tipo) {
        case "text":
            return <Texto campo={campo} datos={datos} />
        case "area":
            return <Area campo={campo} datos={datos} />
        case "fecha":
            return <Fecha campo={campo} contenedor={contenedor} datos={datos} />
        case "hora":
            return <Hora campo={campo} contenedor={contenedor} datos={datos} />
        case "identificador":
            return <Identificador campo={campo} datos={datos} />
        case "dias":
            return <Dias campo={campo} datos={datos} contenedor={contenedor} />
        case "select":
            return <Select campo={campo} datos={datos} contenedor={contenedor} onChange={onChange} />
        case "switch":
            return <Switch campo={campo} datos={datos} />
        default:
            return null;
    }
}

const CampoIndividual = ({ campo, editable, contenedor, datos, onChange }) => {
    if (editable) {
        return (<Entrada campo={campo} contenedor={contenedor} datos={datos} onChange={onChange} />)
    }
    else {
        return <Detalle campo={campo} />
    }
}

export default CampoIndividual;