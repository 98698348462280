import React, { useState } from 'react';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';
import { formatearFechaPHP, insertDatos, mostrarError, mostrarOK, propiedadVacia, sleepear, updateDatos } from '../../../librerias/funciones';

const Editar = ({ proyecto, editar, cerrar }) => {

    const [actualizando, setActualizando] = useState(false)

    const editarProyecto = async (_Datos) => {

        if (propiedadVacia(_Datos, ["activo", 'programa'])) return;

        setActualizando(true)

        const form = new FormData();
        form.append('Proyecto', _Datos.nombre)
        form.append('Programa', _Datos.programa)
        form.append('Descripcion', _Datos.descripcion)
        form.append('Inicio', formatearFechaPHP(_Datos.desde))
        form.append('Fin', formatearFechaPHP(_Datos.hasta))
        form.append('Estado', _Datos.activo)

        let datos;

        while (true) {
            datos = await updateDatos(form, _Datos.id, '/project');
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        editar(datos.Mensaje)
        mostrarOK("Actualizado con éxito")
        cerrar();

    }

    return (
        <DynamicForm
            titulo="Editar Proyecto"
            editable={true}
            actualizando={actualizando}
            botonOK={{ titulo: "Guardar", press: editarProyecto }}
            botonCancel={cerrar}
            secciones={
                [
                    {
                        seccion: "",
                        campos: [
                            {
                                identificador: "id",
                                inicial: proyecto.ProyectoID,
                                tipo: "identificador",
                            },
                            {
                                campo: "Nombre",
                                identificador: "nombre",
                                inicial: proyecto.Proyecto,
                                tipo: "text",
                                tamaño: "s10"
                            },
                            {
                                campo: "Activo",
                                identificador: "activo",
                                inicial: proyecto.Estado,
                                tipo: "switch",
                                tamaño: "s2"
                            },
                            {
                                campo: "Programa",
                                identificador: "programa",
                                inicial: proyecto.Programa,
                                tipo: "text",
                                tamaño: "s12"
                            },
                            {
                                campo: "Desde",
                                identificador: "desde",
                                inicial: proyecto.Inicio,
                                tipo: "fecha",
                                tamaño: "s12 m6"
                            },
                            ,
                            {
                                campo: "Hasta",
                                identificador: "hasta",
                                inicial: proyecto.Fin,
                                tipo: "fecha",
                                tamaño: "s12 m6"
                            },
                            {
                                campo: "Descripcion",
                                identificador: "descripcion",
                                inicial: proyecto.Descripcion,
                                tipo: "area",
                                tamaño: "s12"
                            },
                        ]
                    }
                ]
            }
        />
    )
}

export default Editar;