import React, { useState, useRef, useEffect } from "react";
import styles from './Login.module.css';
import { Mail, Lock, Eye, EyeOff, User } from "react-feather";

import M from 'materialize-css';

import { insertDatos, mostrarError, sleepear } from '../../librerias/funciones';
import CircleLoader from "../../componentes/CircleLoader.js/CircleLoader";

const VistaLogin = ({ logear, restablecer }) => {

    const [correo, setCorreo] = useState("");
    const [contraseña, setContraseña] = useState("");
    const [mostrar, setMostrar] = useState(false);
    const [actualizando, setActualizando] = useState(false)

    const modalRef = useRef(null);
    const instanciaModal = useRef(null);

    const modificarCorreo = (e) => {
        setCorreo(e.target.value);
    }

    const modificarContraseña = (e) => {
        setContraseña(e.target.value);
    }

    const iniciarSesion = async () => {

        if (correo === "") {
            mostrarError("Usuario no debe estar vacío");
            return;
        }
        if (contraseña === "") {
            mostrarError("Contraseña no debe estar vacía");
            return;
        }

        setActualizando(true)

        const form = new FormData();
        form.append("Usuario", correo)
        form.append("Contraseña", contraseña)

        let datos;

        while (true) {
            datos = await insertDatos(form, '/login');
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return false;
        }

        if (datos.Reset) restablecer()
        else logear();
    }

    const toglearMostrar = () => {
        setMostrar(!mostrar);
    }


    useEffect(() => {
        instanciaModal.current = M.Modal.init(modalRef.current, {});
    }, [])

    return (
        <div className={styles.Contenedor} style={{ backgroundColor: "#f2f2f2" }}>

            <div className={`container ${styles.Login} miniSombra`} style={{ backgroundColor: "white", padding: "1em", borderRadius: "1em" }}>

                <div>
                    <p style={{ color: "rgb(0, 153, 242)", fontSize: "2em", margin: 0, fontWeight: "500" }}>Bienvenido</p>
                    <p style={{ color: "#7f8c8d", fontSize: "1.4em", margin: 0, fontFamily: "Montserrat" }}>Inicie sesión para continuar</p>
                </div>

                <div>
                    <div className={styles.wrapperInput}>
                        <Mail color='rgb(0, 153, 242)' size="1.9rem" strokeWidth="0.12rem" />
                        <input onChange={modificarCorreo} className={styles.Entrada} placeholder="Usuario" type="text" />
                    </div>
                    <div className={styles.wrapperInput}>
                        <Lock color='rgb(0, 153, 242)' size="1.9rem" strokeWidth="0.12rem" />
                        <input onChange={modificarContraseña} className={styles.Entrada} placeholder="Contraseña" type={mostrar ? "text" : "password"} />
                        {mostrar ? <EyeOff onClick={toglearMostrar} className={styles.botonOjo} color='rgb(0, 153, 242)' size="1.9rem" strokeWidth="0.12rem" /> : <Eye onClick={toglearMostrar} className={styles.botonOjo} color='rgb(0, 153, 242)' size="1.9rem" strokeWidth="0.12rem" />}
                    </div>
                    {
                        !actualizando ?
                            <div className="">
                                <a onClick={iniciarSesion} className={`${styles.botonSesion} waves-effect waves-light btn`}>Iniciar Sesión</a>
                            </div>
                            :
                            <div style={{ width: "100%", marginTop: "3em" }}>
                                <center>
                                    <CircleLoader tamaño="1.3em" color="rgb(0, 153, 242)" />
                                </center>
                            </div>
                    }
                </div>



                <div style={{ marginTop: "2.3em" }}>
                    <div className="divider"></div>
                </div>

            </div>
        </div>
    );
}

export default VistaLogin;