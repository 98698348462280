import React from 'react';

const MiniLoaderWrap = ({ cargando, children }) => {
    return (
        cargando ?
            <center style={{ padding: "1em" }}>
                <div className="progress" style={{ backgroundColor: "rgba(162, 162, 162,0.25)", margin: 0, width: "100%" }}>
                    <div style={{ backgroundColor: "#3f92ba" }} className="indeterminate"></div>
                </div>
            </center>
            :
            children
    );
}

export default MiniLoaderWrap;