import React, { useState, useEffect } from 'react';
import BarraIconos from '../../../componentes/BarraIconos.js/BarraIconos';
import MensajeNuHay from '../../../componentes/MensajeNuHay/MensajeNuHay';
import MiniLoaderWrap from '../../../componentes/MiniLoaderWrap/MiniLoaderWrap';
import { formatearFecha, formatearNombre, generarVinculoCVR, getDatos, mostrarError, sleepear } from '../../../librerias/funciones';

const EntradaCandidato = ({ candidato, id }) => {

    const descargarCV = () => {
        window.open(generarVinculoCVR(id, candidato.DPI))
    }

    return (

        <>
            <div className="" style={{ marginTop: "0.75em", marginBottom: "0.75em", padding: "1em", width: "100 % ", borderLeft: `0.5em solid #8395a7`, backgroundColor: "white" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <p style={{ margin: 0, fontSize: "1em", fontFamily: "Montserrat" }}>{formatearNombre(candidato.Nombre)}</p>
                    <p style={{ margin: 0, fontSize: "0.9em", fontFamily: "Montserrat" }}><span style={{ fontWeight: "500" }}>Fecha:</span> {formatearFecha(candidato.Fecha)}</p>
                    <BarraIconos documento={descargarCV} />
                </div >
                <div style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                    <p style={{ margin: 0, flex: 1, fontSize: "0.9em", fontFamily: "Montserrat" }}><span style={{ fontWeight: "500" }}>Puntaje:</span> {candidato.Puntaje}</p>
                    <p style={{ margin: 0, flex: 1, fontSize: "0.9em", fontFamily: "Montserrat" }}><span style={{ fontWeight: "500" }}>Telefono:</span> {candidato.Telefono}</p>
                    <p style={{ margin: 0, flex: 1, fontSize: "0.9em", fontFamily: "Montserrat" }}><span style={{ fontWeight: "500" }}>Correo:</span> {candidato.Correo}</p>
                </div>
            </div >
            <div className="divider"></div>
        </>
    );
}

const ListarCandidatos = ({ candidatos, id }) => {
    if (candidatos.length == 0) return <MensajeNuHay mensaje="Aún no hay candidatos" />
    return candidatos.map((_Candidato, _Key) => {
        return <EntradaCandidato key={_Key} candidato={_Candidato} id={id} />
    })
}

const ModalCandidatos = ({ puesto }) => {

    const [cargando, setCargando] = useState(false)
    const [candidatos, setCandidatos] = useState([])

    const cargarDatos = async () => {

        let datos;

        while (true) {
            datos = await getDatos(`/participation/${puesto.IDReclutamiento}`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setCandidatos(datos.Mensaje)
    }

    useEffect(() => {
        cargarDatos();
    }, [])

    return (
        <MiniLoaderWrap cargando={cargando}>
            <p style={{ fontSize: "1.3em" }}>{puesto.Puesto}</p>
            < ListarCandidatos candidatos={candidatos} id={puesto.IDReclutamiento} />
        </MiniLoaderWrap >
    );
}

export default ModalCandidatos;