import React from 'react';
import CampoIndividual from './CampoIndividual';

const Campos = ({ campos, editable, contenedor, datos, onChange }) => {
    return (
        campos.map((_Campo, _Key) => {
            return (
                <CampoIndividual key={_Key} campo={_Campo} editable={editable} contenedor={contenedor} datos={datos} onChange={onChange} />
            );
        })
    );
}

const TituloSeccion = ({ seccion }) => {
    if (seccion == "") return null;
    else return (
        <div className="col s12" style={{ margin: 0, marginTop: "0.5em" }}>
            <p style={{ margin: 0, marginTop: "1em", marginBottom: "0.5em", color: "#404040", fontFamily: "Montserrat", fontWeight: "500" }}>{seccion}</p>
        </div>
    );
}

const Secciones = ({ secciones, editable, contenedor, datos, onChange }) => {
    return (
        secciones.map((_Seccion, _Key) => {
            return (
                <React.Fragment key={_Key}>
                    <TituloSeccion seccion={_Seccion.seccion} />
                    <Campos campos={_Seccion.campos} editable={editable} contenedor={contenedor} datos={datos} onChange={onChange} />
                </React.Fragment>
            );
        })
    );
}

export default Secciones;