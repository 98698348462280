import React from 'react';
import TituloPagina from '../../componentes/TituloPagina/TituloPagina';

const Vista404 = () => {
    return (
        <>
            <TituloPagina titulo="No encontrado" />
            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <ion-icon style={{ color: 'rgb(117, 117, 117)', fontSize: "2.5em" }} name="alert-circle-outline" />
                    <p style={{ color: "rgb(117, 117, 117)", fontFamily: "Montserrat", fontSize: "1.2em" }}>
                        Esta página no existe o no tiene permiso para acceder a ella
                    </p>
                </div>
            </div>
        </>
    )
}

export default Vista404;