import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import BarraIconos from '../../componentes/BarraIconos.js/BarraIconos';
import ConfirmarBorrar from '../../componentes/ConfirmarBorrar/ConfirmarBorrar';
import FullModal from '../../componentes/FullModal/FullModal';
import LoadWrap from '../../componentes/LoadWrap/LoadWrap';
import Separador from '../../componentes/Separador/Separador';
import TituloBoton from '../../componentes/TituloBoton/TituloBoton';
import { deleteDatos, formatearFecha, getDatos, mostrarError, mostrarOK, permitirVer, sleepear } from '../../librerias/funciones';
import Agregar from './Componentes/Agregar';
import BotonBorder from './Componentes/BotonBorder';
import Detalles from './Componentes/Detalles';
import Editar from './Componentes/Editar';

const colorEstado = (_Estado) => {
    if (_Estado) return "rgb(26, 188, 156)";
    else return "rgb(112, 112, 112)";
}

const EntradaProyecto = ({ proyecto, detalles, editar, borrar, rol }) => {

    const [vistaBorrar, setVistaBorrar] = useState(false)

    const funcionBorrar = (_Borrar) => {
        if (_Borrar) {
            borrar(proyecto, () => {
                setVistaBorrar(false)
            })
        }
        else setVistaBorrar(false);
    }

    const Programa = () => {
        if (proyecto.Programa == "" || proyecto.Programa == null) return null;
        else return <p style={{ margin: 0, flex: 1, fontSize: "0.9em", fontFamily: "Montserrat" }}><span style={{ fontWeight: "500" }}>Programa:</span> {proyecto.Programa}</p>
    }

    return (

        <>
            {
                vistaBorrar ?
                    <div className="row" style={{ borderRadius: "0.4em", backgroundColor: "white", margin: 0, marginBottom: "0.75em", marginTop: "0.75em", padding: 0, paddingTop: "1em", paddingBottom: "1em" }}>
                        <ConfirmarBorrar titulo="Borrar Proyecto" descripcion="¿En verdad desea borrar el proyecto? Esta acción no se puede deshacer." onClick={funcionBorrar} />
                    </div>
                    :
                    <>
                        <div className="" style={{ marginTop: "0.75em", marginBottom: "0.75em", padding: "1em", width: "100 % ", borderLeft: `0.5em solid ${colorEstado(proyecto.Estado)}`, backgroundColor: "white" }}>
                            < div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }
                            }>
                                <p style={{ width: "100%", margin: 0, fontSize: "1em", fontFamily: "Montserrat" }}>{proyecto.Proyecto}</p>
                                <BarraIconos
                                    borrar={permitirVer(rol, ["Administrador"]) ? () => { setVistaBorrar(true) } : null}
                                    editar={permitirVer(rol, ["Administrador", "Coordinador de programa"]) ? () => { editar(proyecto) } : null}
                                    detalles={() => { detalles(proyecto) }}
                                />
                            </div >
                            <Programa />
                            <div style={{ marginTop: "0.2em", width: "100%", display: "flex", flexDirection: "row" }}>
                                <p style={{ margin: 0, flex: 1, fontSize: "0.9em", fontFamily: "Montserrat" }}><span style={{ fontWeight: "500" }}>Desde:</span> {formatearFecha(proyecto.Inicio)}</p>
                                <p style={{ margin: 0, flex: 1, fontSize: "0.9em", fontFamily: "Montserrat" }}><span style={{ fontWeight: "500" }}>Hasta:</span> {formatearFecha(proyecto.Fin)}</p>
                            </div>
                        </div >
                    </>
            }
            <div className="divider"></div>
        </>
    );
}

const ListaProyectos = ({ proyectos, editar, detalles, borrar, rol }) => {
    if (proyectos.length == 0) return (
        <div style={{ paddingTop: "2em", flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <ion-icon style={{ color: 'rgb(117, 117, 117)', fontSize: "2.5em" }} name="alert-circle-outline" />
            <p style={{ color: "rgb(117, 117, 117)", fontFamily: "Montserrat", fontSize: "1.2em" }}>Aún no hay proyectos</p>
        </div>
    )
    return proyectos.map((_Proyecto, _Key) => {
        return (
            <EntradaProyecto
                key={_Proyecto.ProyectoID}
                proyecto={_Proyecto}
                editar={editar}
                detalles={detalles}
                borrar={borrar}
                rol={rol}
            />
        );
    })
}

const Proyectos = () => {

    const [cargando, setCargando] = useState(false)
    const [proyectos, setProyectos] = useState([])
    const [estadoModal, setEstadoModal] = useState(false)
    const [vista, setVista] = useState(null)
    const [proyectoActivo, setProyectoActivo] = useState(null)
    const perfilUsuario = useGlobal('perfilUsuario')[0];

    const cargarDatos = async () => {

        setCargando(true)

        let datos;

        while (true) {
            datos = await getDatos('/admin/projectsAndPersons');
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setProyectos(datos.Mensaje.reverse())

        setCargando(false)

    }

    const abrirAgregar = () => {
        setVista("Agregar")
        setEstadoModal(true)
    }

    const abrirEditar = (_Proyecto) => {
        setProyectoActivo(_Proyecto)
        setVista("Editar")
        setEstadoModal(true)
    }

    const abrirDetalles = (_Proyecto) => {
        setProyectoActivo(_Proyecto)
        setVista("Detalles")
        setEstadoModal(true)
    }

    useEffect(() => {
        cargarDatos();
    }, [])

    const agregarProyecto = (_Proyecto) => {
        const nuevosProyectos = [_Proyecto, ...proyectos]
        setProyectos(nuevosProyectos)
    }

    const editarProyecto = (_Proyecto) => {
        const nuevosProyectos = [...proyectos]

        for (let i = 0; i < nuevosProyectos.length; i++) {
            if (nuevosProyectos[i].ProyectoID == _Proyecto.ProyectoID) {
                nuevosProyectos[i] = { ...nuevosProyectos[i], ..._Proyecto };
                break;
            }
        }

        setProyectos(nuevosProyectos)
    }

    const cerrarModal = () => {
        setVista(null)
        setEstadoModal(false);
    }

    const SelectorVista = () => {
        switch (vista) {
            case "Detalles":
                return <Detalles proyecto={proyectoActivo} rol={perfilUsuario.Rol} />
            case "Editar":
                return <Editar proyecto={proyectoActivo} editar={editarProyecto} cerrar={cerrarModal} />
            case "Agregar":
                return <Agregar agregar={agregarProyecto} cerrar={cerrarModal} />
            default:
                return null;
        }
    }

    const borrarProyecto = async (_Proyecto, _Completado) => {

        let datos;

        while (true) {
            datos = await deleteDatos(_Proyecto.ProyectoID, "/project");
            if (datos != null) break;
            await sleepear(5000);
        }

        _Completado();

        if (datos.Error) {
            mostrarError("No es posible eliminar un proyecto con datos");
            return;
        }

        const nuevosProyectos = [...proyectos];

        for (let i = 0; i < nuevosProyectos.length; i++) {
            if (nuevosProyectos[i].ProyectoID == _Proyecto.ProyectoID) {
                nuevosProyectos.splice(i, 1);
                break;
            }
        }

        setProyectos(nuevosProyectos);
        mostrarOK("Eliminado con éxito")

    }

    return (
        <LoadWrap cargando={cargando} titulo="Proyectos">

            <div className="row igualCol" >
                <div className="col s12" style={{ margin: 0, marginBottom: "0.5em" }}>
                    <TituloBoton
                        titulo="Lista de proyectos"
                        boton="Agregar"
                        onClick={abrirAgregar}
                        esconder={permitirVer(perfilUsuario.Rol, ["Administrador"])}
                    />
                </div>

                <div className="col s12 miniSombra" style={{ paddingBottom: "0.75em", margin: 0, marginTop: "1em", backgroundColor: "white", borderRadius: "0.5em" }}>
                    <ListaProyectos
                        proyectos={proyectos}
                        editar={abrirEditar}
                        detalles={abrirDetalles}
                        borrar={borrarProyecto}
                        rol={perfilUsuario.Rol} />
                </div>

            </div>

            {/* Modal Usuario */}
            <FullModal
                abierto={estadoModal}
                cerrar={cerrarModal}
            >
                <SelectorVista vista={vista} />
            </FullModal>

        </LoadWrap>
    );
}

export default Proyectos;