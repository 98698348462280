import React, { useEffect, useRef, useState } from 'react';
import M from 'materialize-css';
import './FullModal.css';

const FullModal = (props) => {

    const refModal = useRef();
    const refWrap = useRef();
    const instModal = useRef();
    const [claseModal, setClaseModal] = useState("modal FullModal_Modal z-depth-0 FullModal_Esconder")

    const modalCerrado = () => {
        setClaseModal("modal FullModal_Modal z-depth-0 FullModal_Esconder")
        props.cerrar()
    }

    const ScrollearTop = () => {
        refWrap.current.scrollTo(0, 0);
    }

    useEffect(() => {
        if (instModal.current == null || typeof instModal.current == "undefined") {
            instModal.current = M.Modal.init(refModal.current, {
                onCloseEnd: modalCerrado,
                onOpenEnd: ScrollearTop,
                dismissible: false
            });
        }

        if (props.abierto) {
            if (!instModal.current.isOpen) {
                setClaseModal("modal FullModal_Modal z-depth-0 FullModal_Mostrar")
                instModal.current.open();
            }
        }
        else {
            if (instModal.current.isOpen) instModal.current.close();
        }

    }, [props.abierto])

    const stopear = (e) => {
        e.stopPropagation();
    }

    return (
        <div ref={refModal} className={claseModal} >
            <div ref={refWrap} className="modal-content FullModal_Wrap" style={{ width: props.width ? props.width : "90%", maxWidth: props.maxWidth ? props.maxWidth : "50rem" }} onClick={stopear} >
                <div className="noHighLightWrapper botonFlatWrap" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                    <ion-icon onClick={props.cerrar} className="noHighLight" style={{ paddingRight: "0.25em", cursor: "pointer", color: 'rgb(117, 117, 117)', fontSize: "2em" }} name="close-circle-outline" />
                </div>
                {props.children}
            </div>
        </div>

    );
}

export default FullModal;