import React, { useEffect, useRef } from 'react';
import { Paleta } from '../../librerias/sistema';
import BarraNavegacion from '../BarraNavegacion/BarraNavegacion';
import HeaderVinculo from '../HeaderVinculo/HeaderVinculo';
import MainWrap from '../MainWrap/MainWrap';
import { SideVinculos, VinculoSeparador, ContenedorLogo } from '../SideVinculos/SideVinculos';
import './DashWrap.css';
import { useLocation } from 'react-router-dom';
import { useGlobal } from 'reactn';

const DashWrap = (props) => {

    const location = useLocation()
    const refWrap = useRef();
    const [sideAbierto, setSideAbierto] = useGlobal('dashSideAbierto');

    useEffect(() => {
        refWrap.current.scrollTo(0, 0);
    }, [location])

    return (
        <>
            <BarraNavegacion />
            <MainWrap>
                <div style={{ margin: 0, height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div style={{ backgroundColor: Paleta.colorPrimario, display: "flex", flexDirection: "column", height: "100%", width: sideAbierto ? "16rem" : "5.5rem" }} className="hide-on-med-and-down abrirDashAnimado">
                        {/* <HeaderVinculo lugar="dash" /> */}

                        {/* <VinculoSeparador /> */}

                        <ul className="DashWrap_ListaVinculos" style={{ margin: 0, flex: 1, overflowY: "auto" }}>
                            <SideVinculos tipo="dash" />
                        </ul>

                        {/* <VinculoSeparador /> */}

                        {/* <ContenedorLogo /> */}

                    </div>
                    <div style={{ height: "100%", flex: 1, backgroundColor: Paleta.colorSecundario, display: "flex", flexDirection: "column", overflow: "hidden" }} className="DashWrap_Panel">
                        {/* <BarraNavegacion /> */}
                        <div style={{ flex: 1, width: "100%", overflow: "hidden" }}>
                            <div ref={refWrap} style={{ height: "100%", width: "100%", overflowY: "auto" }}>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </MainWrap>
        </>
    );
}

export default DashWrap;