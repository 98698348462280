import React from 'react';
import ConfirmarBorrar from '../../../componentes/ConfirmarBorrar/ConfirmarBorrar';

const ModalBorrar = ({ borrar }) => {
    return (
        <ConfirmarBorrar
            titulo="¿En verdad desea borrar a esta persona?"
            descripcion="Esta acción no se puede deshacer"
            onClick={borrar} />
    )
}

export default ModalBorrar;