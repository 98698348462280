import React from 'react';
import { permitirVer } from '../../../librerias/funciones';

const CardEvaluacion = ({ titulo, boton, children, onclick, contenedor, rol }) => {
    return (
        <div ref={contenedor} className="col s12 l6" style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>
            <div className="miniSombra" style={{ padding: "1em", backgroundColor: "white", borderRadius: "0.5em", width: "100%", }}>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                    <p style={{ margin: 0, fontWeight: "500", fontSize: "1.1em" }}>{titulo}</p>
                    {
                        permitirVer(rol, ["Administrador", "Coordinador de programa"]) &&
                        <a onClick={onclick} style={{ paddingRight: 0, display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", borderRadius: "0.5em", textTransform: "none" }} className="Inicio_BotonSubir botonFlat btn-flat">{boton}</a>
                    }
                </div>
                <div style={{ width: "100%", height: "20em", overflowY: "auto" }}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default CardEvaluacion;