import React, { useState, useEffect } from 'react';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';
import FullModal from '../../../componentes/FullModal/FullModal';
import { deletePermisos, insertPermisos, formatearFecha, formatearFechaPHP, formatearHora, getPermisos, mostrarError, propiedadVacia, sleepear, mostrarOK, formatearHoraPHP, updatePermisos, formatearNombre } from '../../../librerias/funciones';
import { df_EditarPermiso, df_NuevoPermiso } from '../../../librerias/plantillasforms';
import ConfirmarBorrar from './ConfirmarBorrar';
import WrapperHistorial from './WrapperHistorial';

const TituloLicencia = (_props) => {
    return (
        <span style={{ verticalAlign: "middle", color: "rgb(167, 167, 167)", fontSize: _props.mini ? "0.75em" : "0.85em", marginRight: "0.5em" }}>{_props.texto}</span>
    );
}

const TextoLicencia = (_props) => {
    let color = "rgb(61, 62, 59)";
    if (_props.estado) {
        switch (_props.texto) {
            case "Pendiente":
                color = "rgb(112, 112, 112)";
                break;
            case "Aprobado":
                color = "#1abc9c";
                break;
            case "Denegado":
                color = "#ff6b6b";
                break;

            default:
                break;
        }
    }
    return (
        <span style={{ textAlign: "justify", verticalAlign: "middle", color: color, fontSize: _props.mini ? "0.85em" : "1em", fontFamily: _props.swap ? "unset" : "Montserrat" }}>{_props.texto}</span>
    );
}

const DescripcionLicencia = (_props) => {
    return (
        <span>
            <TituloLicencia estado={_props.estado} swap={_props.swap} mini={_props.mini} texto={_props.titulo} />
            <TextoLicencia estado={_props.estado} swap={_props.swap} mini={_props.mini} texto={_props.descripcion} />
        </span>
    );
}

const EntradaLicencia = (_props) => {

    const [borrar, setBorrar] = useState(false);

    let color;
    switch (_props.estado) {
        case "Pendiente":
            color = "rgb(112, 112, 112)";
            break;
        case "Aprobado":
            color = "#1abc9c";
            break;
        case "Denegado":
            color = "#ff6b6b";
            break;

        default:
            break;
    }

    const toglear = () => {
        setBorrar(true);
    }

    const borrarTarea = (_Borrar) => {
        if (_Borrar) {
            setBorrar(true)
            _props.borrar(_props.id)
        }
        else {
            setBorrar(false)
        }
    }

    const editar = () => {
        _props.editar({
            id: _props.id,
            desde: _props.desde,
            hasta: _props.hasta,
            iniciando: _props.iniciando,
            finalizando: _props.finalizando,
            motivo: _props.motivo
        })
    }

    return (
        <div className="microSombra" style={{ marginTop: "1.3em", marginBottom: "1.3em", borderLeft: borrar ? "none" : `0.5em solid ${color}`, width: "100%", backgroundColor: "white", borderRadius: "0.5em", padding: "1em" }}>
            <div className="row" style={{ margin: 0, padding: 0 }}>
                {
                    !borrar ?
                        <>
                            <div style={{ padding: 0 }} className="col s12">

                                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <div style={{ flex: 1 }}>
                                        <DescripcionLicencia mini estado titulo="Estado: " descripcion={_props.estado} />
                                    </div>
                                    {
                                        _props.estado == "Pendiente" ?
                                            <>
                                                <div style={{ marginLeft: "0.5em", marginRight: "0.5em" }} className="noHighLightWrapper botonFlatWrap">
                                                    <ion-icon onClick={toglear} className="noHighLight" style={{ cursor: "pointer", color: 'rgb(243, 91, 38)', fontSize: "1.5em" }} name="trash-outline" />
                                                </div>
                                                <div className="noHighLightWrapper botonFlatWrap">
                                                    <ion-icon onClick={editar} className="noHighLight" style={{ cursor: "pointer", color: 'rgb(0, 153, 242)', fontSize: "1.5em" }} name="create-outline" />
                                                </div>
                                            </>
                                            :
                                            null
                                    }

                                </div>
                            </div>
                            <div style={{ padding: 0 }} className="col s12">
                                <DescripcionLicencia mini titulo="Motivo: " descripcion={_props.motivo} />
                            </div>
                            {
                                _props.estado == "Pendiente" ?
                                    null
                                    :
                                    <div style={{ padding: 0 }} className="col s12">
                                        <DescripcionLicencia mini titulo={`${_props.estado} por: `} descripcion={formatearNombre(_props.procesado)} />
                                    </div>
                            }
                            <div style={{ padding: 0 }} className="col s12 m6">
                                <DescripcionLicencia mini titulo="Desde: " descripcion={`${formatearFecha(_props.desde)} - ${formatearHora(_props.iniciando)}`} />
                            </div>
                            {/* <div style={{ padding: 0 }} className="col s12 m6">
                    <DescripcionLicencia mini titulo="Iniciando: " descripcion={_props.iniciando} />
                </div> */}
                            <div style={{ padding: 0 }} className="col s12 m6">
                                <DescripcionLicencia mini titulo="Hasta: " descripcion={`${formatearFecha(_props.hasta)} - ${formatearHora(_props.finalizando)}`} />
                            </div>
                            {/* <div style={{ padding: 0 }} className="col s12 m6">
                    <DescripcionLicencia mini titulo="Finalizando: " descripcion={_props.finalizando} />
                </div> */}
                        </>
                        :
                        <ConfirmarBorrar titulo="Borrar Solicitud" descripcion="¿Seguro que desea borrar la solicitud? Esta acción no se puede deshacer." onClick={borrarTarea} />
                }
            </div>
        </div>
    );
}

const HistorialPermisos = () => {

    const [cargando, setCargando] = useState(true);
    const [permisos, setPermisos] = useState([]);

    // Inicio Modal
    const [estadoModal, setEstadoModal] = useState(false);
    const [editable, setEditable] = useState(true);
    const [titulo, setTitulo] = useState("Agregar");
    const [botonOK, setBotonOK] = useState("Agregar");
    const [plantillaForm, setPlantillaForm] = useState([]);
    const [actualizandoDatos, setActualizandoDatos] = useState(false);

    const abrirModal = () => {
        setEstadoModal(true);
    }

    const cerrarModal = () => {
        setEstadoModal(false);
        setActualizandoDatos(false)
        setPlantillaForm([])
    }

    const agregarPermiso = async (_Datos) => {

        const form = new FormData();
        form.append("FechaInicio", formatearFechaPHP(_Datos.desde));
        form.append("FechaFinal", formatearFechaPHP(_Datos.hasta));
        form.append("HoraInicio", formatearHoraPHP(_Datos.iniciando));
        form.append("HoraFinal", formatearHoraPHP(_Datos.finalizando));
        form.append("Motivo", _Datos.motivo);

        let datos;

        while (true) {
            datos = await insertPermisos(form);
            if (datos != null) {
                if (!datos.Error) break;
                else {
                    mostrarError(datos.Mensaje);
                    await sleepear(15000);
                }
            }
            else {
                await sleepear(5000);
            }
        }

        const nuevosPermisos = [datos.Mensaje, ...permisos];
        setPermisos(nuevosPermisos);

        cerrarModal();
        mostrarOK("Solicitado con éxito");
    }

    const borrarPermiso = async (_ID) => {
        let datos;

        while (true) {
            datos = await deletePermisos(_ID);
            if (datos != null) {
                if (!datos.Error) break;
                else {
                    mostrarError(datos.Mensaje);
                    await sleepear(15000);
                }
            }
            else {
                await sleepear(5000);
            }
        }

        const nuevosPermisos = [...permisos];
        for (let i = 0; i < permisos.length; i++) {
            if (nuevosPermisos[i].IDPermiso == _ID) {
                nuevosPermisos.splice(i, 1);
                break;
            }
        }

        setPermisos(nuevosPermisos);
        mostrarOK("Eliminado con éxito");
    }

    const editarPermiso = async (_Datos) => {

        const form = new FormData();
        form.append("FechaInicio", formatearFechaPHP(_Datos.desde));
        form.append("FechaFinal", formatearFechaPHP(_Datos.hasta));
        form.append("HoraInicio", formatearHoraPHP(_Datos.iniciando));
        form.append("HoraFinal", formatearHoraPHP(_Datos.finalizando));
        form.append("Motivo", _Datos.motivo);

        let datos;

        while (true) {
            datos = await updatePermisos(form, _Datos.id);
            if (datos != null) {
                if (!datos.Error) break;
                else {
                    mostrarError(datos.Mensaje);
                    await sleepear(15000);
                }
            }
            else {
                await sleepear(5000);
            }
        }

        const nuevosPermisos = [...permisos];
        for (let i = 0; i < nuevosPermisos.length; i++) {
            if (nuevosPermisos[i].IDPermiso == _Datos.id) {
                nuevosPermisos[i] = datos.Mensaje;
                break;
            }
        }

        setPermisos(nuevosPermisos);

        cerrarModal();
        mostrarOK("Actualizado con éxito");
    }

    const pressOK = (_Datos) => {

        if (propiedadVacia(_Datos, [])) return;

        setActualizandoDatos(true)

        switch (_Datos._modo) {
            case "Solicitar Permiso":
                agregarPermiso(_Datos);
                break;
            case "Editar Permiso":
                editarPermiso(_Datos);
                break;
            default:
                setActualizandoDatos(false);
                break;
        }
    }

    const pressCancel = () => {
        cerrarModal();
    }

    const abrirAgregarPermiso = () => {
        setPlantillaForm(df_NuevoPermiso());
        setTitulo("Solicitar Permiso");
        setBotonOK("Solicitar")
        setEditable(true);
        abrirModal();
    }

    const abrirEditarPermiso = (_Datos) => {
        setPlantillaForm(df_EditarPermiso(_Datos));
        setTitulo("Editar Permiso");
        setBotonOK("Guardar")
        setEditable(true);
        abrirModal();
    }

    // Fin Modal


    const cargarPermisos = async () => {
        let datos;

        while (true) {
            datos = await getPermisos();
            if (datos != null) {
                if (!datos.Error) break;
                else {
                    mostrarError(datos.Mensaje);
                    await sleepear(15000);
                }
            }
            else {
                await sleepear(5000);
            }
        }

        if (datos.error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setCargando(false);
        setPermisos(datos.Mensaje.reverse())
    }

    useEffect(() => {
        cargarPermisos();
    }, [])

    const ListarPermisos = () => {
        return (
            permisos.map((_Permiso, _Key) => {
                return (
                    <EntradaLicencia
                        key={_Permiso.IDPermiso}
                        id={_Permiso.IDPermiso}
                        desde={_Permiso.FechaInicio}
                        iniciando={_Permiso.HoraInicio}
                        hasta={_Permiso.FechaFinal}
                        finalizando={_Permiso.HoraFinal}
                        motivo={_Permiso.Motivo}
                        estado={_Permiso.Estado}
                        borrar={borrarPermiso}
                        editar={abrirEditarPermiso}
                        procesado={_Permiso.AprobadoPor}
                    />
                );
            })
        );
    }

    return (
        <WrapperHistorial cargando={cargando} boton="Solicitar" onClick={abrirAgregarPermiso}>
            <ListarPermisos />
            <FullModal
                abierto={estadoModal}
                cerrar={cerrarModal}>
                <div className="" style={{ backgroundColor: "white", borderRadius: "0.5em" }}>
                    <DynamicForm
                        titulo={titulo}
                        editable={editable}
                        actualizando={actualizandoDatos}
                        botonOK={{ titulo: botonOK, press: pressOK }}
                        botonCancel={pressCancel}
                        secciones={plantillaForm}
                    />
                </div>
            </FullModal>
            {/* <EntradaLicencia
                desde="12 Nov, 2020"
                hasta="13 Nov, 2020"
                motivo="Licencia Post Parto"
                estado="Pendiente"
            />
            <EntradaLicencia
                desde="12 Nov, 2020"
                hasta="13 Nov, 2020"
                motivo="Salidita al cine"
                estado="Denegado"
            />
            <EntradaLicencia
                desde="12 Nov, 2020"
                hasta="13 Nov, 2020"
                motivo="Mal del puerco"
                estado="Aprobado"
            />
            <EntradaLicencia
                desde="12 Nov, 2020"
                hasta="13 Nov, 2020"
                motivo="Solo escribiendo un texto bastante largo porque tengo curiosidad como se vería"
                estado="Pendiente"
            />
            <EntradaLicencia
                desde="12 Nov, 2020"
                hasta="13 Nov, 2020"
                motivo="Salidita al cine"
                estado="Denegado"
            />
            <EntradaLicencia
                desde="12 Nov, 2020"
                hasta="13 Nov, 2020"
                motivo="Mal del puerco"
                estado="Aprobado"
            /> */}
        </WrapperHistorial>
    );
}

export default HistorialPermisos;