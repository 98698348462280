import React, { useState, useRef, useEffect } from "react";
import styles from './Login.module.css';
import { Mail, Lock, Eye, EyeOff, User } from "react-feather";

import M from 'materialize-css';

import { getDatos, mostrarError, mostrarOK, sleepear, updateDatos } from '../../librerias/funciones';
import CircleLoader from "../../componentes/CircleLoader.js/CircleLoader";

const Restablecer = ({ logear }) => {


    const [contraseña2, setContraseña2] = useState("");
    const [contraseña, setContraseña] = useState("");
    const [mostrar, setMostrar] = useState(false);
    const [actualizando, setActualizando] = useState(false)

    const modificarContraseña = (e) => {
        setContraseña(e.target.value);
    }

    const modificarContraseña2 = (e) => {
        setContraseña2(e.target.value);
    }

    const iniciarSesion = async () => {

        if (contraseña === "") {
            mostrarError("Contraseña no debe estar vacía");
            return;
        }
        if (contraseña2 === "") {
            mostrarError("Confirmar contraseña no debe estar vacía");
            return;
        }

        if (contraseña != contraseña2) {
            mostrarError("Las contraseñas no coinciden")
            return;
        }

        setActualizando(true)

        const form = new FormData();
        form.append("Nueva", contraseña)

        let datos;

        while (true) {
            datos = await updateDatos(form, null, '/userPass');
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return false;
        }

        mostrarOK("Contraseña actualizada")

        logear()
    }

    const toglearMostrar = () => {
        setMostrar(!mostrar);
    }

    return (
        <div className={styles.Contenedor} style={{ backgroundColor: "#f2f2f2" }}>

            <div className={`container ${styles.Login} miniSombra`} style={{ backgroundColor: "white", padding: "1em", borderRadius: "1em" }}>

                <div>
                    <p style={{ color: "rgb(0, 153, 242)", fontSize: "2em", margin: 0, fontWeight: "500" }}>Restablecer</p>
                    <p style={{ color: "#7f8c8d", fontSize: "1.4em", margin: 0, fontFamily: "Montserrat" }}>Contraseña</p>
                </div>

                <div>
                    <div className={styles.wrapperInput}>
                        <Lock color='rgb(0, 153, 242)' size="1.9rem" strokeWidth="0.12rem" />
                        <input onChange={modificarContraseña} className={styles.Entrada} placeholder="Contraseña" type={mostrar ? "text" : "password"} />
                        {/* {mostrar ? <EyeOff onClick={toglearMostrar} className={styles.botonOjo} color='rgb(0, 153, 242)' size="1.9rem" strokeWidth="0.12rem" /> : <Eye onClick={toglearMostrar} className={styles.botonOjo} color='rgb(0, 153, 242)' size="1.9rem" strokeWidth="0.12rem" />} */}
                    </div>
                    <div className={styles.wrapperInput}>
                        <Lock color='rgb(0, 153, 242)' size="1.9rem" strokeWidth="0.12rem" />
                        <input onChange={modificarContraseña2} className={styles.Entrada} placeholder="Confirmar Contraseña" type={mostrar ? "text" : "password"} />
                        {mostrar ? <EyeOff onClick={toglearMostrar} className={styles.botonOjo} color='rgb(0, 153, 242)' size="1.9rem" strokeWidth="0.12rem" /> : <Eye onClick={toglearMostrar} className={styles.botonOjo} color='rgb(0, 153, 242)' size="1.9rem" strokeWidth="0.12rem" />}
                    </div>
                    {
                        !actualizando ?
                            <div className="">
                                <a onClick={iniciarSesion} className={`${styles.botonSesion} waves-effect waves-light btn`}>Continuar</a>
                            </div>
                            :
                            <div style={{ width: "100%", marginTop: "3em" }}>
                                <center>
                                    <CircleLoader tamaño="1.3em" color="rgb(0, 153, 242)" />
                                </center>
                            </div>
                    }
                </div>



                <div style={{ marginTop: "2.3em" }}>
                    <div className="divider"></div>
                </div>

            </div>
        </div>
    );
}

export default Restablecer;