import React, { useState, useEffect, useRef } from 'react';
import M from 'materialize-css';

const ListarOpciones = ({ opciones }) => {
    return opciones.map((_Opcion, _Key) => {
        return (
            <option key={_Key} value={`${_Opcion.id}`}>{_Opcion.opcion}</option>
        );
    })
}

const Select = ({ campo, datos, contenedor, nopad, onChange }) => {
    const [valor, setValor] = useState(campo.inicial)
    datos.current[campo.identificador] = valor;

    const refSelect = useRef();

    const cambiarCampo = (e) => {
        // if (onChange) onChange(`${e.target.value}`)
        if (campo.onChange) campo.onChange(`${e.target.value}`)
        setValor(`${e.target.value}`)
    }

    useEffect(() => {
        M.FormSelect.init(refSelect.current, {
            dropdownOptions: {
                container: contenedor.current
            }
        })
    }, [])

    return (
        <div className={`col input-field ${campo.tamaño}`} style={{ paddingRight: 0, marginTop: "0.25em", marginBottom: "0.25em" }}>
            <p style={{ margin: 0, color: "#757575", fontWeight: "400" }}>{campo.campo}</p>
            <div style={{ margin: 0, paddingLeft: 0 }} className="input-field col s12">
                <select value={valor} onChange={cambiarCampo} ref={refSelect}>
                    <option disabled value="-1">{campo.default}</option>
                    <ListarOpciones opciones={campo.opciones} />
                </select>
            </div>
        </div>
    )
}

export default Select;