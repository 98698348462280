import React, { useEffect, useRef, useState } from 'react';
import { useGlobal } from 'reactn';
import LoadWrap from '../../componentes/LoadWrap/LoadWrap';
import { getBloquesAptitud, getBloquesEvaluacion, mostrarError, sleepear } from '../../librerias/funciones';
import BloqueAptitudes from './Componentes/BloqueAptitudes';
import BloqueEvaluaciones from './Componentes/BloqueEvaluaciones';

const Evaluaciones = () => {

    const [cargando, setCargando] = useState(true);
    const [evaluaciones, setEvaluaciones] = useState([]);
    const [bloques, setBloques] = useState([]);
    const perfilUsuario = useGlobal('perfilUsuario')[0];

    const cargarDatosBloques = async () => {
        let datos;

        while (true) {
            datos = await getBloquesAptitud();
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return false;
        }

        setBloques(datos.Mensaje.reverse())
        return true;
    }

    const cargarDatosEvaluaciones = async () => {
        let datos;

        while (true) {
            datos = await getBloquesEvaluacion();
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return false;
        }

        setEvaluaciones(datos.Mensaje.reverse())
        return true;
    }

    const cargarDatos = async () => {

        setCargando(true)

        const resultados = await Promise.all([
            cargarDatosBloques(),
            cargarDatosEvaluaciones()
        ])

        for (let i = 0; i < resultados.length; i++) {
            if (resultados[i] == false) return
        }

        setCargando(false);
    }

    const agregarBloque = (_Bloque) => {
        _Bloque.Aptitudes = [];
        const nuevosBloques = [_Bloque, ...bloques]
        setBloques(nuevosBloques)
    }

    const agregarAptitud = (_Aptitud) => {
        const nuevosBloques = [...bloques];
        for (let i = 0; i < nuevosBloques.length; i++) {
            if (nuevosBloques[i].IDBloque == _Aptitud.Bloque) {
                nuevosBloques[i].Aptitudes = [...nuevosBloques[i].Aptitudes, _Aptitud];
                break;
            }
        }
        setBloques(nuevosBloques)
    }

    const borrarAptitud = (_Aptitud) => {
        const nuevosBloques = [...bloques];

        for (let i = 0; i < nuevosBloques.length; i++) {
            if (nuevosBloques[i].IDBloque == _Aptitud.Bloque) {

                for (let j = 0; j < nuevosBloques[i].Aptitudes.length; j++) {
                    if (nuevosBloques[i].Aptitudes[j].IDAptitud == _Aptitud.IDAptitud) {
                        nuevosBloques[i].Aptitudes.splice(j, 1);
                        break;
                    }
                }
                break;
            }
        }
        setBloques(nuevosBloques)
    }

    const editarAptitud = (_Aptitud) => {
        const nuevosBloques = [...bloques];

        for (let i = 0; i < nuevosBloques.length; i++) {
            if (nuevosBloques[i].IDBloque == _Aptitud.Bloque) {
                for (let j = 0; j < nuevosBloques[i].Aptitudes.length; j++) {
                    if (nuevosBloques[i].Aptitudes[j].IDAptitud == _Aptitud.IDAptitud) {
                        nuevosBloques[i].Aptitudes[j].Aptitud = _Aptitud.Aptitud
                        break;
                    }
                }
                break;
            }
        }
        setBloques(nuevosBloques)
    }

    const eliminarBloque = (_ID) => {
        const nuevosBloques = [...bloques];

        for (let i = 0; i < nuevosBloques.length; i++) {
            if (nuevosBloques[i].IDBloque == _ID) {
                nuevosBloques.splice(i, 1)
                break;
            }
        }

        setBloques(nuevosBloques)
    }

    const editarBloque = (_Bloque) => {
        const nuevosBloques = [...bloques];

        for (let i = 0; i < nuevosBloques.length; i++) {
            if (nuevosBloques[i].IDBloque == _Bloque.IDBloque) {
                nuevosBloques[i].Nombre = _Bloque.Nombre
                break;
            }
        }

        setBloques(nuevosBloques)
    }

    // Evaluacion

    const agregarBloqueEvaluacion = (_Evaluacion) => {
        const nuevasEvaluaciones = [_Evaluacion, ...evaluaciones]
        setEvaluaciones(nuevasEvaluaciones)
    }
    const editarBloqueEvaluacion = (_Evaluacion) => {
        const nuevasEvaluaciones = [...evaluaciones];

        for (let i = 0; i < nuevasEvaluaciones.length; i++) {
            if (nuevasEvaluaciones[i].IDFormulario == _Evaluacion.IDFormulario) {
                nuevasEvaluaciones[i] = _Evaluacion
                break;
            }
        }

        setEvaluaciones(nuevasEvaluaciones)
    }
    const borrarBloqueEvaluacion = (_ID) => {
        const nuevasEvaluaciones = [...evaluaciones];

        for (let i = 0; i < nuevasEvaluaciones.length; i++) {
            if (nuevasEvaluaciones[i].IDFormulario == _ID) {
                nuevasEvaluaciones.splice(i, 1)
                break;
            }
        }

        setEvaluaciones(nuevasEvaluaciones)
    }

    useEffect(() => {
        cargarDatos();
    }, [])

    return (
        <LoadWrap cargando={cargando} titulo="Evaluaciones">
            <div className="row " style={{ margin: 0 }}>

                <div className="col s12" style={{ margin: 0 }}>
                    <p style={{ marginTop: 0, marginBottom: "0.5em", marginRight: "1em", color: "#333431", fontFamily: "Montserrat", fontSize: "2em", fontWeight: "500" }}>
                        Evaluacion de aptitudes
                    </p>
                </div>

                <BloqueEvaluaciones
                    bloques={evaluaciones}
                    agregar={agregarBloqueEvaluacion}
                    borrar={borrarBloqueEvaluacion}
                    editar={editarBloqueEvaluacion}
                    rol={perfilUsuario.Rol}
                />

                <BloqueAptitudes
                    bloques={bloques}
                    agregar={agregarBloque}
                    borrar={eliminarBloque}
                    editar={editarBloque}
                    agregaraptitud={agregarAptitud}
                    borraraptitud={borrarAptitud}
                    editaraptitud={editarAptitud}
                    rol={perfilUsuario.Rol}
                />

                <div className="col s12" style={{ margin: 0 }}>

                </div>

            </div>
        </LoadWrap>
    );
}

export default Evaluaciones;