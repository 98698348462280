import React, { useEffect, useState } from 'react';
import { useRef } from 'reactn';
import ConfirmarBorrar from '../../componentes/ConfirmarBorrar/ConfirmarBorrar';
import { DropIcono, DropOpcion } from '../../componentes/DropIcono.js/DropIcono';
import FullModal from '../../componentes/FullModal/FullModal';
import LoadWrap from '../../componentes/LoadWrap/LoadWrap';
import { deleteDatos, getDatos, mostrarError, mostrarOK, sleepear, updateDatos } from '../../librerias/funciones';
import ModalAgregar from './Componentes/ModalAgregar';
import ModalCandidatos from './Componentes/ModalCandidatos';
import ModalEditar from './Componentes/ModalEditar';
import ModalPreguntas from './Componentes/Preguntas/ModalPreguntas';

const colorEstado = (_Estado) => {
    if (_Estado) return "rgb(26, 188, 156)";
    else return "rgb(112, 112, 112)";
}

const EntradaPuesto = ({ puesto, editar, candidatos, borrar, contenedor, cambiar, formulario }) => {

    const [vistaBorrar, setVistaBorrar] = useState(false)
    const [valor, setValor] = useState(puesto.Estado)
    const [actualizando, setActualizando] = useState(false)

    const funcionBorrar = (_Borrar) => {
        if (_Borrar) {
            borrar(puesto, () => {
                setVistaBorrar(false)
            })
        }
        else setVistaBorrar(false);
    }

    const cambiarEstado = () => {
        if (actualizando) return;
        setActualizando(true)

        const nuevoValor = !valor;
        setValor(nuevoValor)

        cambiar(puesto, nuevoValor, (_OK) => {
            setActualizando(false);
            if (_OK) setValor(nuevoValor)
            else setValor(!nuevoValor)
        })
    }

    return (

        <>
            {
                vistaBorrar ?
                    <div className="row" style={{ borderRadius: "0.4em", backgroundColor: "white", margin: 0, marginBottom: "0.75em", marginTop: "0.75em", paddingTop: "1em", paddingBottom: "1em" }}>
                        <ConfirmarBorrar titulo="Borrar Puesto" descripcion="¿En verdad desea borrar el puesto? Esta acción no se puede deshacer." onClick={funcionBorrar} />
                    </div>
                    :
                    <>
                        <div className="" style={{ marginTop: "0.75em", marginBottom: "0.75em", padding: "1em", width: "100 % ", borderLeft: `0.5em solid ${colorEstado(puesto.Estado)}`, backgroundColor: "white" }}>
                            < div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }
                            }>
                                <div>
                                    <p style={{ width: "100%", margin: 0, fontSize: "1em", fontFamily: "Montserrat" }}>{puesto.Puesto}</p>
                                    <p style={{ margin: 0, flex: 1, fontSize: "0.9em", fontFamily: "Montserrat" }}><span style={{ fontWeight: "500" }}>Candidatos:</span> {puesto.Cantidad}</p>
                                </div>

                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div className="switch" style={{ position: "relative", top: "0em", left: "-0.5em" }}>
                                        <label>
                                            <input disabled={actualizando} checked={valor} onChange={cambiarEstado} type="checkbox" />
                                            <span className="lever" style={{ margin: 0 }}></span>
                                        </label>
                                    </div>

                                    <DropIcono contenedor={contenedor} tipo="lista-puestos" id={puesto.IDReclutamiento}>
                                        <DropOpcion opcion="Editar" onClick={() => { editar(puesto) }} />
                                        <DropOpcion opcion="Formulario" onClick={() => { formulario(puesto) }} />
                                        <DropOpcion opcion="Candidatos" onClick={() => { candidatos(puesto) }} />
                                        <DropOpcion opcion="Borrar" onClick={() => { setVistaBorrar(true) }} />
                                    </DropIcono>
                                </div>
                            </div >
                        </div >
                    </>
            }
            <div className="divider"></div>
        </>



    );
}

const ListarPuestos = ({ puestos, contenedor, borrar, cambiar, editar, candidatos, formulario }) => {
    if (puestos.length == 0) {
        return (
            <div style={{ paddingTop: "2em", flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <ion-icon style={{ color: 'rgb(117, 117, 117)', fontSize: "2.5em" }} name="alert-circle-outline" />
                <p style={{ color: "rgb(117, 117, 117)", fontFamily: "Montserrat", fontSize: "1.2em" }}>Aún no hay plazas</p>
            </div>
        )
    }
    return puestos.map((_Puesto) => {
        return <EntradaPuesto
            key={_Puesto.IDReclutamiento}
            puesto={_Puesto}
            contenedor={contenedor}
            borrar={borrar}
            cambiar={cambiar}
            editar={editar}
            candidatos={candidatos}
            formulario={formulario}
        />
    })
}

const Reclutamiento = () => {

    const [cargando, setCargando] = useState(true)
    const [puestos, setPuestos] = useState([])
    const [puesto, setPuesto] = useState(null)
    const contenedor = useRef();
    const [vista, setVista] = useState(null)
    const [estadoModal, setEstadoModal] = useState(false)

    const cerrarModal = () => {
        setEstadoModal(false)
        setVista(null)
    }

    const abrirModal = (_Vista) => {
        setVista(_Vista)
        setEstadoModal(true)
    }

    const cargarDatos = async () => {

        let datos;

        while (true) {
            datos = await getDatos('/recruitment');
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setPuestos(datos.Mensaje.reverse())

        setCargando(false)
    }

    useEffect(() => {
        cargarDatos();
    }, [])

    const agregarPuesto = (_Puesto) => {
        const nuevosPuestos = [_Puesto, ...puestos];
        setPuestos(nuevosPuestos)
        mostrarOK("Agregado con éxito")
        cerrarModal();
    }

    const borrarPuesto = async (_Puesto, _Completado) => {

        let datos;

        while (true) {
            datos = await deleteDatos(_Puesto.IDReclutamiento, '/recruitment');
            if (datos != null) break;
            await sleepear(5000);
        }

        _Completado();

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevosPuestos = [...puestos];
        for (let i = 0; i < nuevosPuestos.length; i++) {
            if (nuevosPuestos[i].IDReclutamiento == _Puesto.IDReclutamiento) {
                nuevosPuestos.splice(i, 1)
                break;
            }
        }

        setPuestos(nuevosPuestos)
        mostrarOK("Eliminado con éxito");
    }

    const cambiarPuesto = async (_Puesto, _Valor, _Completado) => {

        const form = new FormData();
        form.append("Estado", _Valor);
        form.append("Puesto", _Puesto.Puesto)

        let datos;

        while (true) {
            datos = await updateDatos(form, _Puesto.IDReclutamiento, '/recruitment');
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            _Completado(false);
            return;
        }

        _Completado(true);

        const nuevosPuestos = [...puestos];
        for (let i = 0; i < nuevosPuestos.length; i++) {
            if (nuevosPuestos[i].IDReclutamiento == _Puesto.IDReclutamiento) {
                nuevosPuestos[i].Estado = _Valor;
                break;
            }
        }

        setPuestos(nuevosPuestos)
    }

    const editarPuesto = (_Puesto) => {
        const nuevosPuestos = [...puestos];
        for (let i = 0; i < nuevosPuestos.length; i++) {
            if (nuevosPuestos[i].IDReclutamiento == _Puesto.IDReclutamiento) {
                nuevosPuestos[i] = _Puesto;
                break;
            }
        }

        cerrarModal();
        setPuestos(nuevosPuestos)
        mostrarOK("Actualizado con éxito")
    }

    const SelectorVista = () => {
        switch (vista) {
            case "Agregar":
                return <ModalAgregar cerrar={cerrarModal} agregar={agregarPuesto} />
            case "Editar":
                return <ModalEditar puesto={puesto} cerrar={cerrarModal} editar={editarPuesto} />
            case "Candidatos":
                return <ModalCandidatos puesto={puesto} />
            case "Formulario":
                return <ModalPreguntas puesto={puesto} />
            default:
                return null;
        }
    }

    return (
        <LoadWrap cargando={cargando} titulo="Reclutamiento">

            <div style={{ paddingLeft: "0.75rem", paddingRight: "0.75rem" }}>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", marginBottom: "2em" }}>
                    <p style={{ marginTop: 0, marginBottom: 0, marginRight: "1em", color: "#333431", fontFamily: "Montserrat", fontSize: "2em", fontWeight: "500" }}>Lista de puestos</p>
                    <a onClick={() => { abrirModal("Agregar") }} style={{ marginTop: "0.5em", marginBottom: "0.5em", display: "block", backgroundColor: "#0190ee", borderRadius: "2em" }} className="waves-effect waves-light btn"><i style={{ marginRight: "0.25em" }} className="material-icons left">add</i>Agregar</a>
                </div>

                <div ref={contenedor} className="miniSombra" style={{ padding: "0.75em", margin: 0, marginTop: "1em", backgroundColor: "white", borderRadius: "0.5em" }}>
                    <ListarPuestos
                        puestos={puestos}
                        contenedor={contenedor}
                        borrar={borrarPuesto}
                        cambiar={cambiarPuesto}
                        editar={(_Puesto) => {
                            setPuesto(_Puesto);
                            abrirModal("Editar")
                        }}
                        candidatos={(_Puesto) => {
                            setPuesto(_Puesto);
                            abrirModal("Candidatos")
                        }}
                        formulario={(_Puesto) => {
                            setPuesto(_Puesto)
                            abrirModal("Formulario")
                        }}
                    />
                </div>
            </div>

            <FullModal
                abierto={estadoModal}
                cerrar={cerrarModal}
            >
                <SelectorVista vista={vista} />
            </FullModal>

        </LoadWrap>
    );
}

export default Reclutamiento;