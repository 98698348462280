import { useEffect } from 'react';
import { useGlobal } from 'reactn';

import { Datos } from '../../librerias/sistema';

const TituloPagina = (props) => {
    const [linkActivo, setLinkActivo] = useGlobal('LinkActivo');
    // document.querySelector('meta[name="description"]').setAttribute("content", props.descripcion);

    useEffect(() => {
        setLinkActivo(props.titulo)
        document.title = `${Datos.Nombre} - ${props.titulo}`;
    }, [])

    return (
        null
    );
}

export default TituloPagina;