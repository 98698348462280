import React, { useState, useEffect, useRef } from 'react';
import BarraIconos from '../../../../componentes/BarraIconos.js/BarraIconos';
import ConfirmarBorrar from '../../../../componentes/ConfirmarBorrar/ConfirmarBorrar';
import { DropIcono, DropOpcion } from '../../../../componentes/DropIcono.js/DropIcono';
import DynamicForm from '../../../../componentes/DynamicForm/DynamicForm';
import MiniLoaderWrap from '../../../../componentes/MiniLoaderWrap/MiniLoaderWrap';
import { deleteDatos, getDatos, insertDatos, mostrarError, mostrarOK, propiedadVacia, sleepear, updateDatos } from '../../../../librerias/funciones';

const VistaBorrar = ({ opcion, cancelar, borrar }) => {

    const funcionBorrar = (_Borrar) => {
        if (_Borrar) {
            borrar(opcion, (_OK) => {
                if (!_OK) cancelar()
            })
        }
        else cancelar();
    }

    return (
        <div className="row" style={{ margin: 0, marginBottom: "0.5em" }}>
            <ConfirmarBorrar titulo="Borrar Respuesta" descripcion="¿En verdad desea borrar la respuesta? Esta acción no se puede deshacer." onClick={funcionBorrar} />
        </div>
    )
}

const VistaDetalle = ({ opcion, mostrarBorrar, mostrarEditar }) => {
    const contenedor = useRef();

    return (
        <div ref={contenedor} style={{ marginTop: "0.5em", padding: "1em", borderLeft: "solid 0.5em #0abde3", marginBottom: "0.5em", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
            <p style={{ flex: 1, margin: 0, fontWeight: "400", fontSize: "1.1em" }}>{opcion.Opcion}</p>
            <p style={{ flex: 1, margin: 0, fontWeight: "400", fontSize: "1.1em" }}> <span style={{ fontSize: "0.8em", fontFamily: "Montserrat" }}>Ponderación:</span> {opcion.Ponderacion}</p>
            <DropIcono contenedor={contenedor} tipo="lista-opciones" id={opcion.IDOpcion}>
                {/* <DropOpcion opcion="Respuestas" onClick={() => { }} /> */}
                <DropOpcion opcion="Editar" onClick={mostrarEditar} />
                <DropOpcion opcion="Eliminar" onClick={mostrarBorrar} />
            </DropIcono>
        </div>
    )
}

const VistaEditar = ({ opcion, editar, regresar }) => {

    const [actualizando, setActualizando] = useState(false)

    const funcionEditar = (_Datos) => {
        setActualizando(true)
        editar(_Datos, (_OK) => {
            setActualizando(false)
            if (_OK) regresar();
        })
    }

    return (
        <DynamicForm
            titulo=""
            editable={true}
            actualizando={actualizando}
            botonOK={{ titulo: "Guardar", press: funcionEditar }}
            botonCancel={regresar}
            // noCancel
            secciones={
                [
                    {
                        seccion: "",
                        campos: [
                            {
                                identificador: "id",
                                inicial: opcion.IDOpcion,
                                tipo: "identificador",
                            },
                            {
                                campo: "Respuesta",
                                identificador: "respuesta",
                                inicial: opcion.Opcion,
                                tipo: "text",
                                tamaño: "s12 m9"
                            },
                            {
                                campo: "Ponderación",
                                identificador: "ponderacion",
                                inicial: opcion.Ponderacion,
                                tipo: "text",
                                tamaño: "s6 m3"
                            },
                        ]
                    }
                ]
            }
        />
    )
}

const EntradaOpcion = ({ opcion, borrar, editar }) => {

    const [vista, setVista] = useState("Detalles")

    const SelectorVista = () => {
        switch (vista) {
            case "Detalles":
                return <VistaDetalle
                    opcion={opcion}
                    mostrarBorrar={() => { setVista("Borrar") }}
                    mostrarEditar={() => { setVista("Editar") }}
                />
            case "Borrar":
                return <VistaBorrar
                    opcion={opcion}
                    cancelar={() => { setVista("Detalles") }}
                    borrar={borrar}
                />
            case "Editar":
                return <VistaEditar
                    opcion={opcion}
                    editar={editar}
                    regresar={() => { setVista("Detalles") }}
                />
            default:
                return null;
        }
    }

    return (
        <>
            <SelectorVista />
            <div className="divider"></div>
        </>
    )
}

const ListarOpciones = ({ opciones, borrar, editar }) => {
    return [...opciones].reverse().map((_Opcion) => {
        return <EntradaOpcion
            key={_Opcion.IDOpcion}
            opcion={_Opcion}
            borrar={borrar}
            editar={editar}
        />
    })
}

const ModalOpciones = ({ pregunta, agregar, borrar, regresar, editar }) => {

    // const [preguntas, setPreguntas] = useState([])
    // const [cargando, setCargando] = useState(true)
    const [estadoAgregar, setEstadoAgregar] = useState(false)
    const [actualizando, setActualizando] = useState(false)

    // const cargarDatos = async () => {

    //     setCargando(true)

    //     let datos;

    //     while (true) {
    //         datos = await getDatos(`/recruitment/${puesto.IDReclutamiento}`);
    //         if (datos != null) break;
    //         await sleepear(5000);
    //     }

    //     if (datos.Error) {
    //         mostrarError(datos.Mensaje);
    //         return;
    //     }

    //     setPreguntas(datos.Mensaje.reverse())

    //     setCargando(false)
    // }

    // useEffect(() => {
    //     cargarDatos();
    // }, [])

    const borrarOpcion = async (_Opcion, _Completado) => {

        let datos;

        while (true) {
            datos = await deleteDatos(_Opcion.IDOpcion, `/option`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            _Completado(false)
            mostrarError(datos.Mensaje);
            return;
        }

        _Completado(true)

        borrar(_Opcion.IDOpcion, pregunta.IDPregunta)


        // const nuevasPreguntas = [...preguntas];
        // for (let i = 0; i < nuevasPreguntas.length; i++) {
        //     if (nuevasPreguntas[i].IDPregunta == _Pregunta.IDPregunta) {
        //         nuevasPreguntas.splice(i, 1);
        //         break;
        //     }
        // }

        // setPreguntas(nuevasPreguntas);
        // mostrarOK("Eliminado con éxito")
    }

    const funcionEditar = async (_Datos, _Completado) => {

        if (propiedadVacia(_Datos, ["_modo"])) {
            _Completado(false)
            return;
        }

        const form = new FormData();
        form.append("Opcion", _Datos.respuesta);
        form.append("Ponderacion", _Datos.ponderacion);

        let datos;

        while (true) {
            datos = await updateDatos(form, _Datos.id, `/option`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            _Completado(false)
            mostrarError(datos.Mensaje);
            return;
        }

        _Completado(true);
        editar(datos.Mensaje)
    }

    const agregarRespuesta = async (_Datos) => {

        if (propiedadVacia(_Datos, ["_modo"])) return;
        let ponderacion;

        try {
            ponderacion = parseInt(_Datos.ponderacion)
            if (isNaN(ponderacion)) ponderacion = 0;
        } catch (error) {
            mostrarError("Ponderación debe ser un número entero")
            return;
        }

        const form = new FormData();
        form.append("Opcion", _Datos.respuesta);
        form.append("Ponderacion", ponderacion);
        form.append("Pregunta", pregunta.IDPregunta);

        let datos;

        while (true) {
            datos = await insertDatos(form, `/option`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setEstadoAgregar(false)

        datos.Mensaje.Ponderacion = parseInt(datos.Mensaje.Ponderacion)
        agregar(pregunta, datos.Mensaje)

    }

    return (
        <>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ marginRight: "0.5em" }} onClick={regresar} className="noHighLightWrapper botonFlatWrap">
                    <ion-icon className="noHighLight" style={{ cursor: "pointer", color: 'black', fontSize: "1.75em" }} name="arrow-back-outline" />
                </div>
                <p style={{ fontSize: "1.3em", margin: 0 }}>
                    Respuestas
                </p>
            </div >
            {
                estadoAgregar ?
                    <DynamicForm
                        titulo=""
                        editable={true}
                        actualizando={actualizando}
                        botonOK=
                        {
                            {
                                titulo: "Agregar",
                                press: agregarRespuesta
                            }
                        }
                        botonCancel={() => { setEstadoAgregar(false) }}
                        secciones={[
                            {
                                seccion: "",
                                campos: [
                                    {
                                        campo: "Respuesta",
                                        identificador: "respuesta",
                                        inicial: "",
                                        tipo: "text",
                                        tamaño: "s12 m9"
                                    },
                                    {
                                        campo: "Ponderación",
                                        identificador: "ponderacion",
                                        inicial: "",
                                        tipo: "text",
                                        tamaño: "s6 m3"
                                    },
                                ]
                            }
                        ]}
                    />
                    :
                    <div className="row" style={{ margin: 0, marginTop: "1em" }}>
                        <div className="col s12" style={{ padding: 0 }}>
                            {/* <p style={{ fontSize: "1.3em", marginTop: 0, marginBottom: "0.5em" }}>Nueva Respuesta</p> */}
                            <a onClick={() => { setEstadoAgregar(true) }} className="noHighLight botonFlat" style={{ cursor: "pointer", display: "flex", alignItems: 'center' }}>
                                <ion-icon style={{ fontSize: "1.5em", marginRight: "0.5em" }} name="add-circle-outline"></ion-icon>
                                Agregar</a>
                        </div>
                    </div>
            }

            <p style={{ fontSize: "1.3em" }}>Listado de Respuestas</p>
            <ListarOpciones
                opciones={pregunta.Opciones}
                borrar={borrarOpcion}
                editar={funcionEditar}
            />
        </>
    )
}

export default ModalOpciones;