import React, { useState, useEffect, useRef } from 'react';
import M from 'materialize-css';

const ListarHoras = () => {
    const lista = [];

    for (let index = 0; index <= 23; index++) {
        const valor = index < 10 ? "0" + index : index;
        lista.push(<option key={index} value={`${index}`}>{valor}</option>)
    }

    return lista;
}

const ListarMinutos = () => {
    const lista = [];

    for (let index = 0; index <= 59; index++) {
        const valor = index < 10 ? "0" + index : index;
        lista.push(<option key={index} value={`${index}`}>{valor}</option>)
    }

    return lista;

}

const objetoHora = (_Hora, _Minuto) => {
    return {
        hora: _Hora,
        minuto: _Minuto,
    }
}

const horaInicial = (_Hora) => {
    if (_Hora == null || _Hora == "") {
        const hora = new Date();
        return (
            {
                hora: hora.getHours(),
                minuto: hora.getMinutes(),
            }
        );
    }
    else {
        const hora = _Hora.split(":");

        let horas = parseInt(hora[0]);
        let minutos = parseInt(hora[1]);

        return (
            {
                hora: horas,
                minuto: minutos,
            }
        );
    }
}

const Hora = ({ campo, contenedor, datos, actualizar, nopad }) => {

    const horaActual = horaInicial(campo.inicial);

    const [hora, setHora] = useState(horaActual.hora)
    const [minuto, setMinuto] = useState(horaActual.minuto)

    const [valor, setValor] = useState(objetoHora(hora, minuto))
    datos.current[campo.identificador] = valor;

    const refHora = useRef();
    const refMinuto = useRef();

    const actualizarValor = (_Hora) => {
        setValor(_Hora)
        if (actualizar != null) actualizar(_Hora);
    }

    const cambiarHora = (e) => {
        const horaSeleccionada = parseInt(e.target.value);
        setHora(horaSeleccionada)
        actualizarValor(objetoHora(horaSeleccionada, minuto));
    }
    const cambiarMinuto = (e) => {
        const minutoSeleccionado = parseInt(e.target.value);
        setMinuto(minutoSeleccionado)
        actualizarValor(objetoHora(hora, minutoSeleccionado));
    }

    useEffect(() => {
        M.FormSelect.init(refMinuto.current, {
            dropdownOptions: {
                container: contenedor.current
            }
        })
        M.FormSelect.init(refHora.current, {
            dropdownOptions: {
                container: contenedor.current
            }
        })
    },
        [valor])

    return (
        <div className={`col input-field ${campo.tamaño}`} style={nopad ? { paddingLeft: 0, marginTop: "0.25em", marginBottom: "0.25em" } : { marginTop: "0.25em", marginBottom: "0.25em" }}>
            <p style={{ margin: 0, color: "#757575", fontWeight: "400" }}>{campo.campo}</p>
            <div className="" style={{ margin: 0 }}>
                <div style={{ margin: 0, paddingLeft: 0 }} className="input-field col s6">
                    <select value={hora} onChange={cambiarHora} ref={refHora}>
                        <option disabled value="-1">Horas</option>
                        <ListarHoras />
                    </select>
                </div>
                <div style={{ margin: 0, paddingLeft: 0 }} className="input-field col s6">
                    <select value={minuto} onChange={cambiarMinuto} ref={refMinuto}>
                        <option disabled value="-1">Minutos</option>
                        <ListarMinutos />
                    </select>
                </div>
            </div>
        </div>
    );
}

export default Hora;