import React from 'react';

const Botones = ({ ok, editable, onCancel, onOK, noCancel, noOK }) => {

    let textoOK = ok;
    let textoCancelar = editable ? "Cancelar" : "Cerrar";

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", overflow: "hidden", flexWrap: "wrap" }}>
            {
                (noCancel && editable) ?
                    null
                    :
                    <a onClick={onCancel} style={{ borderRadius: 0, backgroundColor: "unset", color: "#757575" }} className="waves-effect waves-dark btn z-depth-0">{textoCancelar}</a>
            }
            {
                editable
                    ?
                    noOK ?
                        null :
                        <a onClick={onOK} style={{ fontWeight: "500", backgroundColor: "#3f92ba", marginLeft: "0.5em" }} className="waves-effect waves-light btn z-depth-0">{textoOK}</a>
                    :
                    null
            }
        </div >
    );
}

export default Botones;