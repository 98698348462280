import React, { useState } from 'react';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';
import { getDatos, insertDatos, mostrarError, propiedadVacia, sleepear } from '../../../librerias/funciones';

const ModalAgregar = ({ agregar, cerrar }) => {

    const [actualizando, setActualizando] = useState(false)

    const agregarPuesto = async (_Datos) => {

        if (propiedadVacia(_Datos, [])) return;

        const form = new FormData();
        form.append("Puesto", _Datos.puesto)
        form.append("Estado", 0)

        setActualizando(true)

        let datos;

        while (true) {
            datos = await insertDatos(form, '/recruitment');
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        datos.Mensaje.Estado = parseInt(datos.Mensaje.Estado)
        datos.Mensaje.Cantidad = 0;

        agregar(datos.Mensaje)
    }

    return (
        <DynamicForm
            titulo="Agregar Puesto"
            editable={true}
            actualizando={actualizando}
            botonOK={{ titulo: "Agregar", press: agregarPuesto }}
            botonCancel={cerrar}
            secciones={
                [
                    {
                        seccion: "",
                        campos: [
                            {
                                campo: "Puesto",
                                identificador: "puesto",
                                inicial: "",
                                tipo: "text",
                                tamaño: "s12"
                            }
                        ]
                    }
                ]
            }
        />
    )
}

export default ModalAgregar;