import React, { useEffect } from 'react';
import { useState } from 'reactn';

const IndicadorPuesto = ({ titulo, cantidad, total }) => {
    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <p style={{ margin: 0, fontWeight: "500" }}>{(titulo == "" || titulo == null) ? "N/D" : titulo}</p>
                <p style={{ fontSize: "1.1em", marginTop: 0, marginBottom: 0, marginLeft: "1em", marginRight: "0.7em" }}>{cantidad}<span style={{ fontSize: "0.75em" }}>/{total}</span></p>
            </div>
            <div style={{ marginBottom: "0.75em" }} className="divider"></div>
        </>
    );
}

const ListarPuestos = ({ puestos, total }) => {
    return puestos.map((_Puesto, _Key) => {
        return <IndicadorPuesto key={_Key} titulo={_Puesto.titulo} cantidad={_Puesto.cantidad} total={total} />
    })
}

const contarPuestos = (personas) => {
    const puestos = [];

    personas.forEach(_Persona => {
        puestos.push(_Persona.Cargo)
    });

    const counts = {};
    for (let i = 0; i < puestos.length; i++) {
        const estado = puestos[i];
        counts[estado] = counts[estado] ? counts[estado] + 1 : 1;
    }

    const nuevoArray = [];

    for (const propiedad in counts) {
        nuevoArray.push({
            titulo: propiedad,
            cantidad: counts[propiedad]
        })
    }

    return nuevoArray;
}

const CardRoles = ({ personas }) => {

    const [puestos, setPuestos] = useState([]);

    const cargarDatos = () => {
        setPuestos(contarPuestos(personas))
    }

    useEffect(() => {
        cargarDatos();
    }, [personas])

    // setPuestos(contarPuestos(personas))

    return (
        <div className="col s12 m6" style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>
            <div className="miniSombra" style={{ overflow: "hidden", backgroundColor: "white", borderRadius: "0.5em", padding: "1.5em", width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <p style={{ fontFamily: "Montserrat", fontSize: "1.5em", fontWeight: "500", marginTop: 0, marginBottom: "0.5em" }}>Puestos</p>
                    <p style={{ paddingRight: "0.7em", fontFamily: "Montserrat", fontSize: "1.5em", fontWeight: "500", marginTop: 0, marginBottom: "0.5em" }}> <span style={{ fontSize: "0.7em" }}>Total:</span> <span style={{ fontWeight: "500" }}>{personas.length}</span></p>
                </div>
                <div style={{ flex: 1, overflow: "hidden" }}>
                    <div style={{ width: "100%", height: "100%", overflowY: "auto", maxHeight: "12em", paddingRight: "0.5em" }}>
                        <ListarPuestos puestos={puestos} total={personas.length} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardRoles;