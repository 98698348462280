import React, { useState } from 'react';

const castearBooleano = (_Bool) => {
    if (_Bool) return true;
    else return false
}

const Switch = ({ campo, datos }) => {
    const [valor, setValor] = useState(castearBooleano(campo.inicial))

    datos.current[campo.identificador] = valor;

    return (
        <div className={`col input-field ${campo.tamaño}`} style={{ margin: 0, marginTop: "0.5em" }}>
            <p style={{ textAlign: "center", margin: 0, color: "#757575", fontWeight: "400" }}>{campo.campo}</p>
            <center>
                <div className="switch" style={{ position: "relative", top: "0.6em", left: "-0.5em" }}>
                    <label>
                        <input checked={valor} onChange={(e) => { setValor(!valor); }} type="checkbox" />
                        <span className="lever" style={{ margin: 0 }}></span>
                    </label>
                </div>
            </center>
            {/* <input onChange={cambiarCampo} value={valor} className="DynamicForm_Entrada" style={{ boxSizing: "border-box", margin: 0, fontFamily: "Montserrat", fontSize: "0.95em" }} placeholder={campo.campo} type={campo.tipo} /> */}
        </div>
    )
}

export default Switch;