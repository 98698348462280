import React from 'react';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';

const VistaUsuario = ({ titulo, editable, actualizando, botonOK, botonCancel, secciones }) => {

    return (
        <div style={{ backgroundColor: "white", borderRadius: "0.5em" }}>
            <DynamicForm
                titulo={titulo}
                editable={editable}
                actualizando={actualizando}
                botonOK={botonOK}
                botonCancel={botonCancel}
                secciones={secciones}
            />
        </div>
    )
}

export default VistaUsuario;