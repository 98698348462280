import React, { useState } from 'react';

const Cheque = ({ campo, datos, actualizar }) => {
    const [valor, setValor] = useState(campo.inicial)

    const cambiarCampo = (e) => {
        const nuevoValor = !valor;
        setValor(nuevoValor)
        if (actualizar != null) actualizar(nuevoValor);

    }

    datos.current[campo.identificador] = valor;

    return (
        <div className={`col input-field ${campo.tamaño}`} style={{ margin: 0, marginTop: "0.25em", marginBottom: "0.25em", height: "100%" }}>
            <p style={{ margin: 0, color: "#757575", fontWeight: "400" }}>{campo.campo}</p>
            <div style={{ width: "100%", height: "3rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <label style={{ paddingTop: "0.5em" }}>
                    <input checked={valor} onChange={cambiarCampo} type="checkbox" style={{ position: "unset" }} />
                    <span></span>
                </label>
            </div>
        </div>
    )
}

export default Cheque;