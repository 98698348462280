import React, { useEffect, useRef } from 'react';
import { useGlobal } from 'reactn';
import { Paleta } from '../../librerias/sistema';
import './HeaderVinculo.css';
import M from 'materialize-css';
import { cerrarSesion, formatearNombre, generarVinculoFoto, mostrarError, sleepear } from '../../librerias/funciones';

import { useHistory } from 'react-router-dom'

const HeaderVinculo = (props) => {

    const dropRef = useRef();
    const history = useHistory();

    const [abrirInfo, setAbrirInfo] = useGlobal('abrirInfo');
    const [perfilUsuario, setPerfilUsuario] = useGlobal('perfilUsuario');
    const [modalAcerca, setModalAcerca] = useGlobal('abrirAcerca');
    const [urlFoto, setURLFoto] = useGlobal('urlFoto');

    const cerrarSide = () => {
        if (props.lugar == "side") props.cerrarSide();
    }

    const mostarPerfil = () => {
        cerrarSide();
        setAbrirInfo(true);
    }

    const mostrarAcerca = () => {
        cerrarSide();
        setModalAcerca(true)
    }

    useEffect(() => {
        M.Dropdown.init(dropRef.current, {});
    }, [])

    const logout = async () => {

        let datos;

        while (true) {
            datos = await cerrarSesion();
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Login) {
            mostrarError(datos.Mensaje);
            return false;
        }

        history.push("/");

        window.location.reload()
    }

    // if(perfilUsuario == null) return null;
    return (
        <div className="Headervinculo_header" style={{ width: "100%", paddingTop: "2em", paddingBottom: "1em" }}>
            <center>
                <img className="z-depth-1" src={`${generarVinculoFoto(perfilUsuario.Foto)}`} alt="" width="80em" height="80em" style={{ borderRadius: "40em", objectFit: "cover" }} />
                <p className="Sidevinculos_shadow" style={{ marginTop: "0", marginBottom: "0", color: "white", fontFamily: "Montserrat", fontWeight: 600, fontSize: "1.5em", color: "rgb(51, 52, 49)" }}>{formatearNombre(`${perfilUsuario.Nombre},${perfilUsuario.Apellido}`)}</p>
                <p className="Sidevinculos_shadow" style={{ marginTop: "0", marginBottom: "0.5em", color: Paleta.textoTerciario, fontSize: "1em", color: "rgba(53, 53, 53,0.8)" }}>{perfilUsuario.Puesto}</p>
                <div style={{ position: "relative", backgroundColor: "transparent" }}>
                    <a ref={dropRef} className={`dropdown-trigger`} data-target={`header-${props.lugar}`} style={{ height: "100%", paddingLeft: "1rem", paddingRight: "1rem", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <ion-icon name="chevron-down-outline" style={{ color: "rgba(53, 53, 53,0.8)", fontSize: "2em" }}></ion-icon>
                    </a>
                    <ul style={{ backgroundColor: "transparent", overflow: "visible" }} id={`header-${props.lugar}`} className='dropdown-content z-depth-0'>
                        <center className="Headervinculo_floatboton" style={{ width: "90%", backgroundColor: Paleta.textoTerciario, borderRadius: "1em", overflow: "hidden" }}>
                            <li><a onClick={mostarPerfil} style={{ fontSize: "1em", color: Paleta.textoSecundario, fontWeight: "400", display: "flex", flexDirection: "row", alignItems: "center" }} href="#!"><ion-icon name="person-outline" style={{ color: Paleta.textoPrimario, marginRight: "0.5em", fontSize: "1.5em" }}></ion-icon>Mi Perfil</a></li>
                            <li><a onClick={mostrarAcerca} style={{ fontSize: "1em", color: Paleta.textoSecundario, fontWeight: "400", display: "flex", flexDirection: "row", alignItems: "center" }} href="#!"><ion-icon name="information-circle-outline" style={{ color: Paleta.textoPrimario, marginRight: "0.5em", fontSize: "1.5em" }}></ion-icon>Acerca De</a></li>
                            <li><a onClick={logout} style={{ fontSize: "1em", color: Paleta.textoSecundario, fontWeight: "400", display: "flex", flexDirection: "row", alignItems: "center" }} href="#!"><ion-icon name="log-out-outline" style={{ color: Paleta.textoPrimario, marginRight: "0.5em", fontSize: "1.5em" }}></ion-icon>Cerrar Sesión</a></li>
                        </center>
                    </ul>
                </div>
            </center>
        </div>
    );
}

export default HeaderVinculo;