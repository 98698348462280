import React, { useState, useEffect, useRef } from 'react';
import M from 'materialize-css';
import { Meses } from '../../../librerias/funciones';

const ListarAños = ({ nulo }) => {
    const lista = [];
    if (nulo) lista.push(<option key={-1} value={`${-1}`}>N/D</option>)
    const añoActual = (new Date()).getFullYear() + 30;

    for (let index = añoActual; index >= 1900; index--) {
        lista.push(<option key={index} value={`${index}`}>{index}</option>)
    }

    return lista;
}

const ListarMeses = ({ nulo }) => {
    const lista = [];
    if (nulo) lista.push(<option key={-1} value={`${-1}`}>N/D</option>)
    for (let index = 0; index < Meses.length; index++) {
        lista.push(<option key={index} value={`${index}`}>{Meses[index]}</option>)
    }

    return lista;

}

const ListarDias = ({ mesActual, añoActual, nulo }) => {
    const lista = [];
    if (nulo) lista.push(<option key={-1} value={`${-1}`}>N/D</option>)
    const diasTotales = new Date(añoActual, mesActual + 1, 0).getDate();

    for (let index = 1; index <= diasTotales; index++) {
        lista.push(<option key={index} value={`${index}`}>{index}</option>)
    }

    return lista;
}

const objetoFecha = (_Dia, _Mes, _Año) => {
    return {
        dia: _Dia,
        mes: _Mes,
        año: _Año
    }
}

const fechaInicial = (_Fecha, _Nulo) => {

    if (_Fecha == null || _Fecha == "") {
        if (_Nulo) {

            return (
                {
                    dia: "-1",
                    mes: "-1",
                    año: "-1"
                }
            );
        }
        else {
            const fecha = new Date();
            return (
                {
                    dia: fecha.getDate(),
                    mes: fecha.getMonth(),
                    año: fecha.getFullYear()
                }
            );
        }
    }
    else {
        const fecha = _Fecha.split("-");

        let año = parseInt(fecha[0]);
        let mes = parseInt(fecha[1] - 1);
        let dia = parseInt(fecha[2]);

        return (
            {
                dia: dia,
                mes: mes,
                año: año
            }
        );
    }
}

const Fecha = ({ campo, contenedor, datos, actualizar, nopad }) => {

    const fechaActual = fechaInicial(campo.inicial, campo.nulo ? true : false);

    const [dia, setDia] = useState(fechaActual.dia)
    const [mes, setMes] = useState(fechaActual.mes)
    const [año, setAño] = useState(fechaActual.año)

    const [valor, setValor] = useState(objetoFecha(dia, mes, año))
    datos.current[campo.identificador] = valor;

    const refDia = useRef();
    const refMes = useRef();
    const refAño = useRef();

    const actualizarValor = (_Fecha) => {
        setValor(_Fecha)
        if (actualizar != null) actualizar(_Fecha);
    }

    const cambiarDia = (e) => {
        const diaSeleccionado = parseInt(e.target.value);
        setDia(diaSeleccionado)
        actualizarValor(objetoFecha(diaSeleccionado, mes, año))
    }
    const cambiarMes = (e) => {
        const mesSeleccionado = parseInt(e.target.value);
        setDia(1)
        setMes(mesSeleccionado)
        actualizarValor(objetoFecha(1, mesSeleccionado, año));
    }
    const cambiarAño = (e) => {
        const añoSeleccionado = parseInt(e.target.value);
        setDia(1)
        setAño(añoSeleccionado)
        actualizarValor(objetoFecha(1, mes, añoSeleccionado));
    }

    useEffect(() => {
        M.FormSelect.init(refDia.current, {
            dropdownOptions: {
                container: contenedor.current
            }
        })
        M.FormSelect.init(refMes.current, {
            dropdownOptions: {
                container: contenedor.current
            }
        })
        M.FormSelect.init(refAño.current, {
            dropdownOptions: {
                container: contenedor.current
            }
        })
    },
        [valor])

    return (
        <div className={`col input-field ${campo.tamaño}`} style={nopad ? { paddingLeft: 0, marginTop: "0.25em", marginBottom: "0.25em" } : { marginTop: "0.25em", marginBottom: "0.25em" }}>
            <p style={{ margin: 0, color: "#757575", fontWeight: "400" }}>{campo.campo}</p>
            <div className="" style={{ margin: 0, display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ width: "4.5em" }} className="">
                    <select value={año} onChange={cambiarAño} ref={refAño}>
                        <ListarAños nulo={campo.nulo} />
                    </select>
                </div>
                <div style={{ flex: 1, marginLeft: "0.5em", marginRight: "0.5em" }} className="">
                    <select value={mes} onChange={cambiarMes} ref={refMes}>
                        <ListarMeses nulo={campo.nulo} />
                    </select>
                </div>
                <div style={{ width: "4em" }} className="">
                    <select value={dia} onChange={cambiarDia} ref={refDia}>
                        <ListarDias mesActual={mes} añoActual={año} nulo={campo.nulo} />
                    </select>
                </div>
            </div>
        </div>
    );
}

export default Fecha;