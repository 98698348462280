import React, { useRef, useEffect } from 'react';
import { useState } from 'reactn';
import ConfirmarBorrar from '../../../componentes/ConfirmarBorrar/ConfirmarBorrar';
import EntradaUpload from '../../../componentes/EntradaUpload/EntradaUpload';
import { deleteDatos, generarVinculoDownload, getDatos, insertArchivo, mostrarError, mostrarOK, sleepear } from '../../../librerias/funciones';

const EntradaArchivos = ({ archivo, borrar }) => {

    const [vistaBorrar, setVistaBorrar] = useState(false);

    const descargarArchivo = () => {
        window.open(generarVinculoDownload(archivo.IDArchivo));
    }

    const borrarArchivo = (_Borrar) => {
        if (_Borrar) {
            borrar(archivo.IDArchivo, () => {
                setVistaBorrar(!vistaBorrar)
            })
        }
        else setVistaBorrar(!vistaBorrar)
    }

    return (
        vistaBorrar ?
            <ConfirmarBorrar titulo="Borrar Archivo" descripcion="¿En verdad desea borrar este archivo? Esta acción no se puede deshacer" onClick={borrarArchivo} />
            :
            <>
                <div className="botonFlatWrap" style={{ overflow: "hidden", width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between" }}>
                    <p style={{ margin: 0, flex: 1, wordBreak: "break-all" }}>{archivo.Nombre}</p>
                    <ion-icon onClick={descargarArchivo} style={{ cursor: "pointer", color: '#0099f2', fontSize: "1.4em", marginLeft: "1.5em" }} name="cloud-download-outline" />
                    <ion-icon onClick={() => { setVistaBorrar(true) }} className="botonFlat" style={{ cursor: "pointer", color: '#f35b26', fontSize: "1.4em", marginLeft: "0.5em" }} name="trash-outline" />
                </div >
                <div style={{ marginTop: "0.5em", marginBottom: "0.5em", opacity: "0.25" }} className="divider"></div>
            </>
    );
}

const ListarArchivos = ({ archivos, borrar }) => {
    return archivos.map((_Archivo) => {
        return <EntradaArchivos
            key={_Archivo.IDArchivo}
            archivo={_Archivo}
            borrar={borrar}
        />
    })
}

const CardArchivos = () => {

    const refBoton = useRef();
    const [subiendo, setSubiendo] = useState(false);
    const [archivos, setArchivos] = useState([]);

    const subirArchivo = async (_Archivo) => {

        const tamaño = _Archivo.size / 1048576;
        if (tamaño > 5) {
            mostrarError("El archivo es mayor a 5MB");
            return;
        }

        setSubiendo(true)

        const form = new FormData();
        form.append("file", _Archivo);

        let datos;

        while (true) {
            datos = await insertArchivo(form);
            if (datos != null) break;
            await sleepear(5000);
        }

        setSubiendo(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevosArchivos = [datos.Mensaje, ...archivos];
        setArchivos(nuevosArchivos);
        mostrarOK("Archivo subido con éxito");
    }

    const cargarDatos = async () => {

        let datos;

        while (true) {
            datos = await getDatos(`/myfiles`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setArchivos(datos.Mensaje.reverse())

    }

    const borrarArchivo = async (_Archivo, _Completado) => {

        let datos;

        while (true) {
            datos = await deleteDatos(_Archivo, `/file`);
            if (datos != null) break;
            await sleepear(5000);
        }

        _Completado();

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevosArchivos = [...archivos];
        for (let i = 0; i < nuevosArchivos.length; i++) {
            if (nuevosArchivos[i].IDArchivo == _Archivo) {
                nuevosArchivos.splice(i, 1);
                break;
            }
        }

        mostrarOK("Eliminado con éxito")
        setArchivos(nuevosArchivos)
    }

    useEffect(() => {
        cargarDatos();
    }, [])

    return (
        <div style={{ marginTop: "1em" }} className="col s12 Inicio_CardUtilidades">
            {subiendo ? null : <EntradaUpload boton={refBoton} subir={subirArchivo} />}
            <div className="miniSombra" style={{ backgroundColor: "white", height: "100%", borderRadius: "0.5em", padding: "1em" }}>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                    <p style={{ margin: 0, fontWeight: "500" }}>Archivos</p>
                    {subiendo ?
                        <div className="progress" style={{ backgroundColor: "rgba(162, 162, 162,0.25)", margin: 0, width: "4.3em" }}>
                            <div style={{ backgroundColor: "#3f92ba" }} className="indeterminate"></div>
                        </div>
                        :
                        <a ref={refBoton} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", borderRadius: "0.5em", textTransform: "none" }} className="Inicio_BotonSubir botonFlat btn-flat">Subir</a>}
                </div>
                <div style={{ paddingRight: "0.5em", margin: 0, marginTop: "1em", width: "100%", height: "14.875em", overflowY: "auto", color: "#333431" }} className=" Inicio_AreaNotas">
                    {/* <EntradaArchivos archivos={archivos} titulo="Curriculum_2020.pdf" /> */}
                    <ListarArchivos
                        archivos={archivos}
                        borrar={borrarArchivo}
                    />
                </div>
            </div>
        </div>
    );
}

export default CardArchivos;