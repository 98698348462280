import React, { useState, useEffect } from 'react';
import MiniLoaderWrap from '../../../../componentes/MiniLoaderWrap/MiniLoaderWrap';
import { getDatos, mostrarError, mostrarOK, sleepear, updateDatos } from '../../../../librerias/funciones';
import AdminEntradaPermiso from './AdminPermisoEntrada';

const Tab = ({ titulo, onClick, activa }) => {
    const tabactiva = titulo == activa ? true : false;

    return (
        <div className="noHighLight" onClick={() => { onClick(titulo) }} style={{ borderBottom: tabactiva ? "0.25em solid #4543eb" : "none", cursor: "pointer", backgroundColor: tabactiva ? "#e8e7fa" : "white", flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <p style={{ fontWeight: tabactiva ? "500" : "400", color: tabactiva ? "#4543eb" : "#5f6268" }}>{titulo}</p>
        </div>
    );
}

const ListarPendientes = ({ pendientes, cambiar }) => {
    if (pendientes.length == 0) return <p style={{ padding: "1em", color: "rgb(117, 117, 117)", fontFamily: "Montserrat", textAlign: "center" }}>No hay solicitudes de permiso</p>
    return pendientes.map((_Permiso) => {
        return <AdminEntradaPermiso
            key={_Permiso.IDPermiso}
            permiso={_Permiso}
            cambiar={cambiar}
            pendiente />
    })
}

const ListarProcesadas = ({ procesadas }) => {
    if (procesadas.length == 0) return <p style={{ padding: "1em", color: "rgb(117, 117, 117)", fontFamily: "Montserrat", textAlign: "center" }}>No se han procesado permisos</p>
    return procesadas.map((_Permiso) => {
        return <AdminEntradaPermiso
            key={_Permiso.IDPermiso}
            permiso={_Permiso} />
    })
}

const AdminPermisos = () => {

    const [activa, setActiva] = useState("Pendientes")
    const [pendientes, setPendientes] = useState([])
    const [procesadas, setProcesadas] = useState([])
    const [cargando, setCargando] = useState(true)

    const cambiarActiva = (_Activa) => { setActiva(_Activa) }

    const cargarDatos = async () => {

        setCargando(true)

        let datos;

        while (true) {
            datos = await getDatos('/permissionAll');
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevasPendientes = [];
        const nuevasProcesadas = [];

        datos.Mensaje.reverse().forEach(_Permiso => {
            if (_Permiso.Estado == "Pendiente") nuevasPendientes.push(_Permiso);
            else nuevasProcesadas.push(_Permiso)
        });

        setPendientes(nuevasPendientes)
        setProcesadas(nuevasProcesadas)

        setCargando(false)
    }

    useEffect(() => {
        cargarDatos();
    }, [])

    const cambiarEstado = async (_Permiso, _Estado, _Completado) => {

        const form = new FormData();
        form.append("Estado", _Estado)

        let datos;

        while (true) {
            datos = await updateDatos(form, _Permiso.IDPermiso, '/statePermission');
            if (datos != null) break;
            await sleepear(5000);
        }

        _Completado()

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevasPendientes = [...pendientes];
        let nuevasProcesadas;

        for (let i = 0; i < nuevasPendientes.length; i++) {
            if (nuevasPendientes[i].IDPermiso == _Permiso.IDPermiso) {
                const nuevo = nuevasPendientes[i];
                nuevo.Estado = _Estado ? "Aprobado" : "Denegado";
                nuevasProcesadas = [nuevo, ...procesadas];
                nuevasPendientes.splice(i, 1)
                break;
            }
        }

        mostrarOK("Procesado con éxito")
        setPendientes(nuevasPendientes)
        setProcesadas(nuevasProcesadas)

    }

    return (
        <MiniLoaderWrap cargando={cargando}>
            <p style={{ fontSize: "1.3em" }}>Permisos</p>

            <div style={{ marginTop: "0.5em", width: "100%", display: "flex", flexDirection: "row" }}>
                <Tab titulo="Pendientes" onClick={cambiarActiva} activa={activa} />
                <Tab titulo="Historial" onClick={cambiarActiva} activa={activa} />
            </div>
            <div className="divider"></div>

            {
                activa == "Pendientes" ?
                    <ListarPendientes pendientes={pendientes} cambiar={cambiarEstado} />
                    :
                    <ListarProcesadas procesadas={procesadas} />
            }
        </MiniLoaderWrap>
    )
}

export default AdminPermisos;