import React, { useEffect } from 'react';
import { useState } from 'reactn';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';
import FullModal from '../../../componentes/FullModal/FullModal';
import { updateBitacora, deleteBitacora, convertirHoraMinutos, formatearFecha, formatearFechaPHP, formatearHoraPHP, getBitacoras, getProyectosID, insertBitacora, mostrarError, mostrarOK, sleepear } from '../../../librerias/funciones';
import { df_EditarBitacora, df_NuevaBitacora } from '../../../librerias/plantillasforms';
import ConfirmarBorrar from './ConfirmarBorrar';
import WrapperHistorial from './WrapperHistorial';

const Titulo = (_props) => {
    return (
        <span style={{ verticalAlign: "middle", color: "rgb(167, 167, 167)", fontSize: _props.mini ? "0.75em" : "0.85em", marginRight: "0.5em" }}>{_props.texto}</span>
    );
}

const Texto = (_props) => {
    return (
        <span style={{ fontWeight: _props.bold ? 500 : 400, verticalAlign: "middle", color: "rgb(61, 62, 59)", fontSize: _props.mini ? "0.85em" : "1em", fontFamily: _props.swap ? "unset" : "Montserrat" }}>{_props.texto}</span>
    );
}

const Descripcion = (_props) => {
    return (
        <span>
            {_props.titulo == "" ? null : <Titulo estado={_props.estado} swap={_props.swap} mini={_props.mini} texto={_props.titulo} />}
            <Texto estado={_props.estado} bold={_props.bold} swap={_props.swap} mini={_props.mini} texto={_props.descripcion} />
        </span>
    );
}

const EntradaBitacora = _props => {

    const [estado, setEstado] = useState(_props.estado);
    const [borrar, setBorrar] = useState(false);
    const [subiendo, setSubiendo] = useState(false)

    const toglear = () => {
        const nuevoEstado = !estado;
        setSubiendo(true);
        setEstado(nuevoEstado)
        _props.editarEstado(_props.id, nuevoEstado)
    }

    const editarBitacora = () => {
        _props.editar({
            bitacora: _props.id,
            proyecto: _props.idproyecto,
            notas: _props.descripcion,
            dias: _props.dias
        })
    }

    const toglearBorrar = () => {
        setBorrar(true)
    }

    const borrarBitacora = (_Borrar) => {
        if (_Borrar) {
            _props.borrar(_props.id, () => {
                setBorrar(false)
            })
        }
        else {
            setBorrar(false)
        }
    }

    const contarInasistencias = () => {
        let contador = 0;
        _props.dias.forEach(_Dia => {
            if (_Dia.Inasistencia) contador++;
        });
        return `${contador}`
    }

    const primerDia = () => {
        if (_props.dias.length <= 0) return "N/D";
        return (formatearFecha(_props.dias[0].Fecha));
    }

    const ultimoDia = () => {
        if (_props.dias.length <= 0) return "N/D";
        return (formatearFecha(_props.dias[_props.dias.length - 1].Fecha));
    }

    return (
        <div className="row microSombra" style={{ borderLeft: "0.5em solid #f3a683", marginLeft: 0, marginRight: 0, marginTop: "1.3em", marginBottom: "1.3em", width: "100%", backgroundColor: "white", borderRadius: "0.5em", padding: "1em" }}>
            {!borrar ?
                <>
                    {
                        subiendo ?
                            <div style={{ padding: 0 }} className="col s12">
                                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                                    <div className="spinear btn-flat" style={{ padding: 0, cursor: "unset", display: "flex", justifyContent: "center", alignItems: "center" }} ><ion-icon style={{ color: 'rgb(162, 162, 162)', fontSize: "1.4em" }} name="sync-outline" /></div>
                                </div>
                            </div>
                            :
                            <div style={{ padding: 0 }} className="col s12">
                                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <div style={{ padding: 0 }} className="col s12">
                                        <div style={{ padding: 0 }} className="col s12">
                                            <Descripcion titulo="" descripcion={_props.proyecto} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div onClick={toglearBorrar} className="noHighLightWrapper botonFlatWrap">
                                            <ion-icon className="noHighLight" style={{ marginLeft: "0.5em", marginRight: "0.5em", cursor: "pointer", color: 'rgb(243, 91, 38)', fontSize: "1.5em" }} name="trash-outline" />
                                        </div>
                                        <div onClick={editarBitacora} className="noHighLightWrapper botonFlatWrap">
                                            <ion-icon className="noHighLight" style={{ cursor: "pointer", color: 'rgb(0, 153, 242)', fontSize: "1.5em" }} name="create-outline" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                    {/* <div style={{ padding: 0 }} className="col s12">
                        <Descripcion mini titulo="Notas: " descripcion={_props.descripcion} />
                    </div> */}
                    <div style={{ padding: 0 }} className="col s12 m4">
                        <Descripcion mini titulo="Inasistencias: " descripcion={contarInasistencias()} />
                    </div>
                    <div style={{ padding: 0 }} className="col s12 m4">
                        <Descripcion mini titulo="Desde: " descripcion={primerDia()} />
                    </div>
                    <div style={{ padding: 0 }} className="col s12 m4">
                        <Descripcion mini titulo="Hasta: " descripcion={ultimoDia()} />
                    </div>
                </>
                :
                <ConfirmarBorrar titulo="Borrar Bitácora" descripcion="¿Seguro que desea borrar la bitácora? Esta acción no se puede deshacer." onClick={borrarBitacora} />
            }
        </div>
    );
}

const ListarBitacoras = ({ bitacoras, borrar, editar }) => {
    return (
        bitacoras.map((_Bitacora) => {
            return (
                <EntradaBitacora
                    key={_Bitacora.Bitacora.IDBitacora}
                    id={_Bitacora.Bitacora.IDBitacora}
                    descripcion={_Bitacora.Bitacora.Notas}
                    proyecto={_Bitacora.Bitacora.Proyecto}
                    idproyecto={_Bitacora.Bitacora.ProyectoID}
                    dias={_Bitacora.Dias}
                    borrar={borrar}
                    editar={editar}
                />
            )
        })
    )
}

const HistorialBitacoras = () => {

    const [cargando, setCargando] = useState(true);
    const [bitacoras, setBitacoras] = useState([]);
    const [proyectos, setProyectos] = useState([])

    // Modal

    const [estadoModal, setEstadoModal] = useState(false);
    const [editable, setEditable] = useState(true);
    const [titulo, setTitulo] = useState("Agregar");
    const [botonOK, setBotonOK] = useState("Agregar");
    const [plantillaForm, setPlantillaForm] = useState([]);
    const [actualizandoDatos, setActualizandoDatos] = useState(false);

    const abrirModal = () => {
        setEstadoModal(true);
    }

    const cerrarModal = () => {
        setEstadoModal(false);
        setActualizandoDatos(false)
        setPlantillaForm([])
    }

    const abrirAgregarBitacora = () => {
        setPlantillaForm(df_NuevaBitacora(proyectos));
        setTitulo("Agregar Bitácora");
        setBotonOK("Agregar")
        setEditable(true);
        abrirModal();
    }

    const abrirEditarBitacora = (_Datos) => {
        setPlantillaForm(df_EditarBitacora(proyectos, _Datos));
        setTitulo("Editar Bitácora");
        setBotonOK("Guardar")
        setEditable(true);
        abrirModal();
    }
    // Fin Modal

    const crearBitacora = async ({ dias, notas, proyecto }) => {
        if (proyecto == "-1") {
            mostrarError("Seleccione un proyecto");
            return;
        }

        if (dias.length == 0) {
            mostrarError("Seleccione al menos un día")
            return;
        }

        setActualizandoDatos(true)

        const diasFormateados = [];

        for (let i = 0; i < dias.length; i++) {
            diasFormateados.push({
                Fecha: dias[i].fecha,
                Minutos: convertirHoraMinutos(dias[i].hora),
                Inasistencia: dias[i].inasistencia
            })
        }

        const form = new FormData();
        form.append("Proyecto", proyecto);
        form.append("Notas", notas);
        form.append("Dias", JSON.stringify(diasFormateados));

        let datos;

        while (true) {
            datos = await insertBitacora(form);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            setActualizandoDatos(false);
            return;
        }

        const nuevasBitacoras = [datos.Mensaje, ...bitacoras];
        setBitacoras(nuevasBitacoras);

        cerrarModal();
        mostrarOK("Creada con éxito");
    }

    const editarBitacora = async ({ bitacora, notas, dias, proyecto }) => {
        if (proyecto == "-1") {
            mostrarError("Seleccione un proyecto");
            return;
        }

        if (dias.length == 0) {
            mostrarError("Seleccione al menos un día")
            return;
        }

        setActualizandoDatos(true)

        const diasFormateados = [];

        for (let i = 0; i < dias.length; i++) {
            diasFormateados.push({
                Fecha: dias[i].fecha,
                Minutos: convertirHoraMinutos(dias[i].hora),
                Inasistencia: dias[i].inasistencia
            })
        }

        const form = new FormData();
        form.append("Proyecto", proyecto);
        form.append("Notas", notas);
        form.append("Dias", JSON.stringify(diasFormateados));

        let datos;

        while (true) {
            datos = await updateBitacora(form, bitacora);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            setActualizandoDatos(false);
            return;
        }

        const nuevasBitacoras = [...bitacoras];
        for (let i = 0; i < nuevasBitacoras.length; i++) {
            if (nuevasBitacoras[i].Bitacora.IDBitacora == bitacora) {
                nuevasBitacoras[i] = datos.Mensaje;
                break;
            }
        }

        setBitacoras(nuevasBitacoras);

        cerrarModal();
        mostrarOK("Actualizado con éxito");
    }

    const pressOK = async (_Datos) => {
        switch (_Datos._modo) {
            case "Agregar Bitácora":
                crearBitacora(_Datos)
                break;
            case "Editar Bitácora":
                editarBitacora(_Datos)
                break;
            default:
                break;
        }
    }

    const pressCancel = () => {
        cerrarModal();
    }

    const cargarBitacoras = async () => {
        let datos;

        while (true) {
            datos = await getBitacoras();
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const listabitacoras = { ...datos }

        while (true) {
            datos = await getProyectosID();
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        const listaproyectos = { ...datos }

        setProyectos(listaproyectos.Mensaje)
        setBitacoras(listabitacoras.Mensaje.reverse())

        setCargando(false);
    }

    const eliminarBitacora = async (_ID, _Error) => {
        let datos;

        while (true) {
            datos = await deleteBitacora(_ID);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            _Error();
            mostrarError(datos.Mensaje);
            return;
        }

        const nuevasBitacoras = [...bitacoras];
        for (let i = 0; i < nuevasBitacoras.length; i++) {
            if (nuevasBitacoras[i].Bitacora.IDBitacora == _ID) {
                nuevasBitacoras.splice(i, 1);
                break;
            }
        }

        setBitacoras(nuevasBitacoras);
        mostrarOK("Eliminado con éxito");
    }

    useEffect(() => {
        cargarBitacoras();
    }, [])

    return (
        <WrapperHistorial cargando={cargando} boton="Agregar" onClick={abrirAgregarBitacora}>
            <ListarBitacoras bitacoras={bitacoras} borrar={eliminarBitacora} editar={abrirEditarBitacora} />

            <FullModal
                abierto={estadoModal}
                cerrar={cerrarModal}>
                <div className="" style={{ backgroundColor: "white", borderRadius: "0.5em" }}>
                    <DynamicForm
                        titulo={titulo}
                        editable={editable}
                        actualizando={actualizandoDatos}
                        botonOK={{ titulo: botonOK, press: pressOK }}
                        botonCancel={pressCancel}
                        secciones={plantillaForm}
                    />
                </div>
            </FullModal>
        </WrapperHistorial>
    );
}

export default HistorialBitacoras;