import M from 'materialize-css';

const Meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const determinarHost = () => {
    if (window.location.hostname.indexOf("localhost") != -1) return "http://localhost:8000";
    else if (window.location.hostname.indexOf("192") != -1) return "http://192.168.1.112:8000";
    else if (window.location.hostname.indexOf("pscaritassanmarcos") != -1) return "https://apiquazar.pscaritassanmarcos.org";
    // else if (window.location.hostname.indexOf("caritassm") != -1) return "https://apicsm.capistudioapps.com";
    // else if (window.location.hostname.indexOf("caritasxl") != -1) return "https://apicxl.capistudioapps.com";
    // else if (window.location.hostname.indexOf("adipo") != -1) return "https://apiadipo.capistudioapps.com";
    // else if (window.location.hostname.indexOf("quazar") != -1) return "https://apiquazar.capistudioapps.com";
    // return "https://apiquazar.capistudioapps.com";
}

const Servidor = determinarHost();

const tiempoEspera = 60000;

// Permisos de renderizado
const permitirVer = (_Rol, _Admitidos) => {
    if (_Rol == "" || _Rol == null) return false;
    if (_Admitidos.length == 0) return true;
    for (let i = 0; i < _Admitidos.length; i++) {
        if (_Rol == _Admitidos[i]) return true;
    }
    return false;
}

// Toasts
const mostrarError = (_Mensaje) => {
    M.toast({ html: _Mensaje, classes: "mensajeError white-text" })
}

const mostrarOK = (_Mensaje) => {
    M.toast({ html: _Mensaje, classes: "green darken-2 white-text" })
}

// Herramientas
const generarVinculoCV = (_CV) => {
    return `${Servidor}/getCV/${_CV}`;
}
const generarVinculoCVR = (_ID, _CV) => {
    return `${Servidor}/participation/getCVR/${_ID}/${_CV}`;
}

const generarVinculoReportes = (_context) => {
    return `${Servidor}/reports/${_context}`;
}

const sleepear = (_Millis) => {
    return new Promise(async (resolve) => {
        setTimeout(() => {
            resolve();
        }, _Millis);
    })
}

const validarDigitos = (_Cadena) => {
    return /^\d+$/.test(_Cadena);
}

const validarCantidad = (_Cadena, _Tamaño) => {
    return ((_Cadena.length == _Tamaño))
}

const esFechaFutura = (_Fecha) => {
    const hoy = new Date().setHours(0, 0, 0, 0);
    const fecha = new Date(_Fecha.año, _Fecha.mes, _Fecha.dia).setHours(0, 0, 0, 0);
    return (fecha > hoy);
}

const generarVinculoDownload = (_ID) => {
    return `${Servidor}/file/${_ID}`;
}

const generarVinculoFoto = (_Nombre) => {
    if (_Nombre == null || _Nombre == "") _Nombre = "404";
    return `${Servidor}/getPhoto/${_Nombre}`
}

const formatearNulo = (_Texto) => {
    if (_Texto == null || _Texto == "") return "N/D";
    else return _Texto;
}

const fechaStringPHP = () => {
    const fecha = new Date();
    return `${fecha.getFullYear()}-${fecha.getMonth() + 1}-${fecha.getDate()}`;
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const propiedadVacia = (_Objeto, _Skip) => {
    for (const propiedad in _Objeto) {
        if (_Objeto[propiedad] == "" || _Objeto[propiedad] == null || _Objeto[propiedad] == "-1") {
            if (!_Skip.includes(propiedad)) {
                mostrarError(`Debe especificar ${capitalize(propiedad)}`);
                return true;
            }
        }
    }
    return false;
}

const propiedadVaciaForm = (_Objeto, _Propiedades) => {
    for (let i = 0; i < _Propiedades.length; i++) {
        const propiedad = _Propiedades[i];
        if (_Objeto[propiedad] == "" || _Objeto[propiedad] == null || _Objeto[propiedad] == "-1") {
            return true
        }
    }

    return false
}

const buscarEnPropiedades = (_Objeto, _Texto, _Skip) => {
    if (_Texto == null) _Texto = "";
    _Texto = _Texto.trim();
    if (_Texto == "") return true;
    for (const propiedad in _Objeto) {
        if (!_Skip.includes(propiedad)) {
            if (`${_Objeto[propiedad]}`.toLowerCase().indexOf(_Texto.toLowerCase()) != -1) {
                return true;
            }
        }
    }
    return false;
}

const abreviarMes = (_Mes) => {
    return Meses[_Mes - 1].substring(0, 3);
}

const formatearFecha = (_Fecha) => {
    if (_Fecha == null || _Fecha == "") return "N/D";
    const fecha = _Fecha.split('-');

    let contador = 0;
    fecha.forEach(_Numero => {
        contador += _Numero;
    });

    if (contador == 0) return "N/D";

    return `${fecha[2]} ${abreviarMes(fecha[1])}, ${fecha[0]}`
}

const formatearFechaPHP = (_Fecha) => {
    let dia = parseInt(_Fecha.dia);
    let mes = parseInt(_Fecha.mes);
    let año = parseInt(_Fecha.año);

    if (dia == -1 || mes == -1 || año == -1) {
        return null
    }

    mes += 1;

    dia = dia < 10 ? "0" + dia : dia;
    mes = mes < 10 ? "0" + mes : mes;

    return `${año}-${mes}-${dia}`
}

const convertirHoraMinutos = (_Hora) => {
    const horas = _Hora.split(":");
    return ((parseInt(horas[0]) * 60) + parseInt(horas[1]))
}

const convertirMinutosHora = (_Minutos) => {
    const minutosTotales = _Minutos;

    const horas = Math.floor(minutosTotales / 60);
    const minutos = minutosTotales % 60;

    return (`${horas < 10 ? `0${horas}` : horas}:${minutos < 10 ? `0${minutos}` : minutos}`);
}

const formatearHora = (_Hora) => {
    const hora = _Hora.split(":");
    return (`${hora[0]}:${hora[1]}`);
}

const formatearHoraPHP = (_Hora) => {
    let hora = parseInt(_Hora.hora);
    let minuto = parseInt(_Hora.minuto);

    hora = hora < 10 ? "0" + hora : hora;
    minuto = minuto < 10 ? "0" + minuto : minuto;

    return `${hora}:${minuto}:00`
}

// const fechaActualPHP = ()=>{
//     const fecha = new Date();
// }

const formatearNombre = (_Datos) => {
    if (_Datos == null || _Datos == "") return 'N/D';
    let nombreCliente = _Datos.split(',');
    nombreCliente[0] = nombreCliente[0].split(' ');
    nombreCliente[1] = nombreCliente[1].split(' ');

    if (nombreCliente[1][0].toUpperCase() == "DE") {
        nombreCliente = nombreCliente[0][0] + " " + nombreCliente[1][0] + " " + nombreCliente[1][1];
    }
    else {
        nombreCliente = nombreCliente[0][0] + " " + nombreCliente[1][0]
    }

    return nombreCliente;
}

// GET
const descargarGET = (_URL) => {
    return new Promise(async (Resolve) => {

        let timerOut = null;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', _URL);
        xhr.timeout = tiempoEspera;

        xhr.withCredentials = true;

        // xhr.onprogress = function (e) {
        //     if (_Actualizar != null) _Actualizar(e.loaded / e.total)
        // };
        xhr.ontimeout = function (e) {
            // alert("Tiempo Excedido")
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(null)
        };
        xhr.onload = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(xhr.response);
            // Resolve(false)
        };
        xhr.onerror = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(null);
        };
        try {
            xhr.send();
        } catch (error) {
            Resolve(null);
        }

        timerOut = setTimeout(() => {
            xhr.abort();
            // alert("Abortado");
            Resolve(null);

        }, tiempoEspera);
    });
}

// POST
const descargarPOST = (_URL, _FormData) => {
    return new Promise(async (Resolve) => {

        let timerOut = null;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', _URL);
        xhr.withCredentials = true;
        // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.timeout = tiempoEspera;
        xhr.ontimeout = () => {
            if (timerOut != null) clearTimeout(timerOut);
            // alert('Timeout')
            Resolve(null);
        };
        xhr.onload = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(xhr.response);
        };
        xhr.onerror = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(null);
        };
        xhr.send(_FormData);

        timerOut = setTimeout(() => {
            xhr.abort();
            // alert("Abortado");
            Resolve(null);

        }, tiempoEspera);
    });
}

// POST Archivo
const ArchivoPOST = (_URL, _FormData) => {
    return new Promise(async (Resolve) => {

        let timerOut = null;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', _URL);
        xhr.withCredentials = true;
        // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
        xhr.timeout = tiempoEspera;
        xhr.ontimeout = () => {
            if (timerOut != null) clearTimeout(timerOut);
            // alert('Timeout')
            Resolve(null);
        };
        xhr.onload = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(xhr.response);
        };
        xhr.onerror = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(null);
        };
        xhr.send(_FormData);

        timerOut = setTimeout(() => {
            xhr.abort();
            // alert("Abortado");
            Resolve(null);

        }, tiempoEspera);
    });
}

// DELETE
const descargarDELETE = (_URL) => {
    return new Promise(async (Resolve) => {

        let timerOut = null;
        const xhr = new XMLHttpRequest();
        xhr.open('DELETE', _URL);
        xhr.timeout = tiempoEspera;

        xhr.withCredentials = true;

        // xhr.onprogress = function (e) {
        //     if (_Actualizar != null) _Actualizar(e.loaded / e.total)
        // };
        xhr.ontimeout = function (e) {
            // alert("Tiempo Excedido")
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(null)
        };
        xhr.onload = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(xhr.response);
            // Resolve(false)
        };
        xhr.onerror = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(null);
        };
        try {
            xhr.send();
        } catch (error) {
            Resolve(null);
        }

        timerOut = setTimeout(() => {
            xhr.abort();
            // alert("Abortado");
            Resolve(null);

        }, tiempoEspera);
    });
}

// UPDATE
const descargarPUT = (_URL, _FormData) => {
    return new Promise(async (Resolve) => {

        _FormData.append("_method", "PUT")

        let timerOut = null;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', _URL);
        xhr.withCredentials = true;
        // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.timeout = tiempoEspera;
        xhr.ontimeout = () => {
            if (timerOut != null) clearTimeout(timerOut);
            // alert('Timeout')
            Resolve(null);
        };
        xhr.onload = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(xhr.response);
        };
        xhr.onerror = function () {
            if (timerOut != null) clearTimeout(timerOut);
            Resolve(null);
        };
        xhr.send(_FormData);

        timerOut = setTimeout(() => {
            xhr.abort();
            // alert("Abortado");
            Resolve(null);

        }, tiempoEspera);
    });
}

const cerrarSesion = async () => {
    const datos = await descargarDELETE(`${Servidor}/login`)
    return (convertirJSON(datos))
}

const convertirJSON = (_Datos) => {
    try {

        if (_Datos == "" || _Datos == null) return null;
        const objeto = JSON.parse(_Datos)
        return objeto;

    } catch (e) {
        return null;
    }
}

const comprobarError = (_Datos) => {
    if (_Datos == null) {
        mostrarError("No fue posible conectar al servidor")
    }
    else {
        if (_Datos.Error) {
            mostrarError(_Datos.Mensaje)
        }
    }
}

const obtenerPerfil = async () => {
    let datos = await descargarGET(`${Servidor}/user`)
    datos = convertirJSON(datos);
    return datos;
}

// Notas
const descargarNotas = async () => {
    let datos = await descargarGET(`${Servidor}/note`)
    datos = convertirJSON(datos);
    return datos;
}

const subirNotas = async (_Datos) => {
    let datos = await descargarPOST(`${Servidor}/note`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}

// Tareas
const descargarTareas = async () => {
    let datos = await descargarGET(`${Servidor}/task`)
    datos = convertirJSON(datos);
    return datos;
}

const nuevaTarea = async (_Datos) => {
    let datos = await descargarPOST(`${Servidor}/task`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}

const actualizarTarea = async (_Datos, _ID) => {
    let datos = await descargarPUT(`${Servidor}/task/${_ID}`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}

const actualizarEstadoTarea = async (_Datos, _ID) => {
    let datos = await descargarPUT(`${Servidor}/taskState/${_ID}`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}

const eliminarTarea = async (_ID) => {
    let datos = await descargarDELETE(`${Servidor}/task/${_ID}`)
    datos = convertirJSON(datos);
    return datos;
}

const getTareasAdminUser = async (_DPI) => {
    let datos = await descargarGET(`${Servidor}/taskByDpi/${_DPI}`)
    datos = convertirJSON(datos);
    return datos;
}
const insertTareaAdminUser = async (_Datos, _DPI) => {
    let datos = await descargarPOST(`${Servidor}/admin/task/${_DPI}`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}
const deleteTareaAdminUser = async (_ID) => {
    let datos = await descargarDELETE(`${Servidor}/admin/task/${_ID}`)
    datos = convertirJSON(datos);
    return datos;
}
const updateTareaAdminUser = async (_Datos, _ID) => {
    let datos = await descargarPUT(`${Servidor}/admin/task/${_ID}`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}

// Proyectos
const getProyectos = async () => {
    let datos = await descargarGET(`${Servidor}/project`)
    datos = convertirJSON(datos);
    return datos;
}
const getProyectosID = async () => {
    let datos = await descargarGET(`${Servidor}/allProject`)
    datos = convertirJSON(datos);
    return datos;
}
const getProyectosAdminUser = async (_DPI) => {
    let datos = await descargarGET(`${Servidor}/project/${_DPI}`)
    datos = convertirJSON(datos);
    return datos;
}

// Permisos
const getPermisos = async () => {
    let datos = await descargarGET(`${Servidor}/permission`)
    datos = convertirJSON(datos);
    return datos;
}
const insertPermisos = async (_Datos) => {
    let datos = await descargarPOST(`${Servidor}/permission`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}
const deletePermisos = async (_ID) => {
    let datos = await descargarDELETE(`${Servidor}/permission/${_ID}`)
    datos = convertirJSON(datos);
    return datos;
}
const updatePermisos = async (_Datos, _ID) => {
    let datos = await descargarPUT(`${Servidor}/permission/${_ID}`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}

// Bitacoras
const getBitacoras = async () => {
    let datos = await descargarGET(`${Servidor}/log`)
    datos = convertirJSON(datos);
    return datos;
}
const insertBitacora = async (_Datos) => {
    let datos = await descargarPOST(`${Servidor}/log`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}
const deleteBitacora = async (_ID) => {
    let datos = await descargarDELETE(`${Servidor}/log/${_ID}`)
    datos = convertirJSON(datos);
    return datos;
}
const updateBitacora = async (_Datos, _ID) => {
    let datos = await descargarPUT(`${Servidor}/log/${_ID}`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}

// Subir archivo
const insertArchivo = async (_Datos) => {
    let datos = await ArchivoPOST(`${Servidor}/file`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}

// Personas
const getPersonas = async () => {
    let datos = await descargarGET(`${Servidor}/person`)
    datos = convertirJSON(datos);
    return datos;
}
const getSelectsPersonas = async () => {
    let datos = await descargarGET(`${Servidor}/strongTables`)
    datos = convertirJSON(datos);
    return datos;
}
const insertPersona = async (_Datos) => {
    let datos = await descargarPOST(`${Servidor}/person`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}
const updatePersona = async (_Datos, _ID) => {
    let datos = await descargarPUT(`${Servidor}/person/${_ID}`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}

// Usuarios
const insertUsuario = async (_Datos, _DPI) => {
    let datos = await descargarPOST(`${Servidor}/user/${_DPI}`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}
const updateUsuario = async (_Datos, _DPI) => {
    let datos = await descargarPUT(`${Servidor}/user/${_DPI}`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}
const resetUsuario = async (_DPI) => {
    let datos = await descargarGET(`${Servidor}/setReset/${_DPI}`)
    datos = convertirJSON(datos);
    return datos;
}

// Bloques de aptitud
const getBloquesAptitud = async () => {
    let datos = await descargarGET(`${Servidor}/block`)
    datos = convertirJSON(datos);
    return datos;
}
const insertBloqueAptitud = async (_Datos) => {
    let datos = await descargarPOST(`${Servidor}/block`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}
const deleteBloqueAptitud = async (_ID) => {
    let datos = await descargarDELETE(`${Servidor}/block/${_ID}`)
    datos = convertirJSON(datos);
    return datos;
}
const updateBloqueAptitud = async (_Datos, _ID) => {
    let datos = await descargarPUT(`${Servidor}/block/${_ID}`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}
const insertAptitud = async (_Datos) => {
    let datos = await descargarPOST(`${Servidor}/aptitude`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}
const deleteAptitud = async (_ID) => {
    let datos = await descargarDELETE(`${Servidor}/aptitude/${_ID}`)
    datos = convertirJSON(datos);
    return datos;
}
const updateAptitud = async (_Datos, _ID) => {
    let datos = await descargarPUT(`${Servidor}/aptitude/${_ID}`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}

// Evaluación
const getBloquesEvaluacion = async () => {
    let datos = await descargarGET(`${Servidor}/form`)
    datos = convertirJSON(datos);
    return datos;
}

// Genéricas
const getDatos = async (_URL) => {
    let datos = await descargarGET(`${Servidor}${_URL}`)
    datos = convertirJSON(datos);
    return datos;
}
const insertDatos = async (_Datos, _URL) => {
    let datos = await descargarPOST(`${Servidor}${_URL}`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}
const deleteDatos = async (_ID, _URL) => {
    let datos = await descargarDELETE(`${Servidor}${_URL}/${_ID}`)
    datos = convertirJSON(datos);
    return datos;
}
const updateDatos = async (_Datos, _ID, _URL) => {
    let datos;
    if (_ID != null) datos = await descargarPUT(`${Servidor}${_URL}/${_ID}`, _Datos)
    else datos = await descargarPUT(`${Servidor}${_URL}`, _Datos)
    datos = convertirJSON(datos);
    return datos;
}

export {
    // Datos
    Meses,
    // Funciones
    sleepear,
    formatearFecha,
    formatearFechaPHP,
    formatearHora,
    formatearHoraPHP,
    propiedadVacia,
    formatearNombre,
    fechaStringPHP,
    convertirHoraMinutos,
    convertirMinutosHora,
    formatearNulo,
    buscarEnPropiedades,
    generarVinculoDownload,
    generarVinculoFoto,
    validarDigitos,
    validarCantidad,
    esFechaFutura,
    permitirVer,
    propiedadVaciaForm,

    // CRUD 
    insertDatos,
    deleteDatos,
    updateDatos,
    getDatos,
    cerrarSesion,

    // Notificaciones
    mostrarError,
    mostrarOK,

    // Modal Perfil
    obtenerPerfil,
    generarVinculoCV,
    generarVinculoCVR,

    //////////// INICIO ////////////
    descargarNotas,
    subirNotas,

    // Tareas
    descargarTareas,
    nuevaTarea,
    actualizarTarea,
    actualizarEstadoTarea,
    eliminarTarea,
    getTareasAdminUser,
    insertTareaAdminUser,
    deleteTareaAdminUser,
    updateTareaAdminUser,

    // Proyectos
    getProyectos,
    getProyectosID,
    getProyectosAdminUser,

    // Permisos
    getPermisos,
    insertPermisos,
    deletePermisos,
    updatePermisos,

    // Bitacoras
    getBitacoras,
    insertBitacora,
    deleteBitacora,
    updateBitacora,

    // Archivos
    insertArchivo,

    // Personas
    getPersonas,
    getSelectsPersonas,
    insertPersona,
    updatePersona,

    // Usuarios
    insertUsuario,
    updateUsuario,
    resetUsuario,

    // Bloques de aptitudes
    getBloquesAptitud,
    insertBloqueAptitud,
    deleteBloqueAptitud,
    updateBloqueAptitud,
    insertAptitud,
    deleteAptitud,
    updateAptitud,

    // Evaluacion
    getBloquesEvaluacion,

    //Reportes
    generarVinculoReportes
}