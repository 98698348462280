import React, { useState } from 'react';
import BarraIconos from '../../../../componentes/BarraIconos.js/BarraIconos';
import CircleLoader from '../../../../componentes/CircleLoader.js/CircleLoader';
import { formatearFecha, formatearHora, formatearNombre } from '../../../../librerias/funciones';

const TituloLicencia = (_props) => {
    return (
        <span style={{ verticalAlign: "middle", color: "rgb(167, 167, 167)", fontSize: _props.mini ? "0.75em" : "0.85em", marginRight: "0.5em" }}>{_props.texto}</span>
    );
}

const TextoLicencia = (_props) => {
    let color = "rgb(61, 62, 59)";
    if (_props.estado) {
        switch (_props.texto) {
            case "Pendiente":
                color = "rgb(112, 112, 112)";
                break;
            case "Aprobado":
                color = "#1abc9c";
                break;
            case "Denegado":
                color = "#ff6b6b";
                break;

            default:
                break;
        }
    }
    return (
        <span style={{ textAlign: "justify", verticalAlign: "middle", color: color, fontSize: _props.mini ? "0.85em" : "1em", fontFamily: _props.swap ? "unset" : "Montserrat" }}>{_props.texto}</span>
    );
}

const DescripcionLicencia = (_props) => {
    return (
        <span>
            <TituloLicencia estado={_props.estado} swap={_props.swap} mini={_props.mini} texto={_props.titulo} />
            <TextoLicencia estado={_props.estado} swap={_props.swap} mini={_props.mini} texto={_props.descripcion} />
        </span>
    );
}

const AdminEntradaPermiso = ({ permiso, pendiente, cambiar }) => {

    const [actualizando, setActualizando] = useState(false)

    let color;
    switch (permiso.Estado) {
        case "Pendiente":
            color = "rgb(112, 112, 112)";
            break;
        case "Aprobado":
            color = "#1abc9c";
            break;
        case "Denegado":
            color = "#ff6b6b";
            break;

        default:
            break;
    }

    const funcionCambiar = (_Estado) => {
        setActualizando(true)
        cambiar(permiso, _Estado, () => {
            setActualizando(false)
        })
    }

    return (
        <>
            <div className="col s12" style={{ marginTop: "1.3em", marginBottom: "1.3em", borderLeft: `0.5em solid ${color}`, width: "100%", backgroundColor: "white", padding: 0, paddingLeft: "1em" }}>
                <div className="row" style={{ margin: 0, padding: 0 }}>
                    {pendiente ?
                        <div style={{ padding: 0 }} className="col s12">
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                <DescripcionLicencia mini titulo="Nombre: " descripcion={formatearNombre(`${permiso.Nombre},${permiso.Apellido}`)} />
                                {actualizando ?
                                    <CircleLoader tamaño="1.1em" color="rgb(112, 112, 112)" />
                                    :
                                    <BarraIconos aceptar={() => { funcionCambiar(true) }} cancelar={() => { funcionCambiar(false) }} />
                                }
                            </div>
                        </div>
                        :
                        <div style={{ padding: 0 }} className="col s12">
                            <DescripcionLicencia mini titulo="Nombre: " descripcion={formatearNombre(`${permiso.Nombre},${permiso.Apellido}`)} />
                        </div>
                    }
                    <div style={{ padding: 0 }} className="col s12">
                        <DescripcionLicencia mini titulo="Motivo: " descripcion={permiso.Motivo} />
                    </div>
                    <div style={{ padding: 0 }} className="col s12 m6">
                        <DescripcionLicencia mini titulo="Desde: " descripcion={`${formatearFecha(permiso.FechaInicio)} - ${formatearHora(permiso.HoraInicio)}`} />
                    </div>
                    <div style={{ padding: 0 }} className="col s12 m6">
                        <DescripcionLicencia mini titulo="Hasta: " descripcion={`${formatearFecha(permiso.FechaFinal)} - ${formatearHora(permiso.HoraFinal)}`} />
                    </div>
                </div>
            </div>
            <div className="col s12">
                <div className="divider"></div>
            </div>
        </>
    );
}

export default AdminEntradaPermiso;