import React from 'react';
import { useState } from 'reactn';
import BarraIconos from '../../../componentes/BarraIconos.js/BarraIconos';
import Separador from '../../../componentes/Separador/Separador';
import { formatearNombre, generarVinculoFoto, permitirVer } from '../../../librerias/funciones';
import Bitacoras from './Bitacoras';

const EntradaPersona = ({ persona, bitacoras, rol }) => {
    return (
        <div style={{ marginTop: "0.5em", marginBottom: "0.5em", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
            <img className="z-depth-1" src={generarVinculoFoto(persona.Foto)} alt="" width="60em" height="60em" style={{ backgroundColor: "rgb(184, 183, 180)", borderRadius: "30em", marginRight: "2em", objectFit: "cover" }} />
            <div style={{ flex: 1 }}>
                <p style={{ margin: 0, fontWeight: "400", fontSize: "1.1em" }}>{formatearNombre(`${persona.Nombre},${persona.Apellido}`)}</p>
                <div style={{ width: "100%", flexWrap: "wrap", display: "flex", flexDirection: "row" }}>
                    <p style={{ margin: 0, color: "rgb(117, 117, 117)" }}><span style={{ fontSize: "0.85em" }}>Puesto:</span> <strong style={{ fontSize: "0.95em" }}>{persona.Puesto}</strong></p>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <p style={{ margin: 0, color: "rgb(117, 117, 117)" }}><span style={{ fontSize: "0.85em" }}>Tel.</span> <strong style={{ fontSize: "0.95em" }}>{persona.Telefono}</strong></p>
                    {permitirVer(rol, ["Administrador", "Coordinador de programa"]) &&
                        <>
                            <Separador tamaño="0.5em" orientacion="horizontal" />
                            <p style={{ margin: 0, color: "rgb(117, 117, 117)" }}><span style={{ fontSize: "0.85em" }}>Salario:</span> <strong style={{ fontSize: "0.95em" }}>Q{persona.Sueldo}</strong></p>
                        </>
                    }
                </div>
            </div>
            <Separador tamaño="0.5em" orientacion="horizontal" />
            <BarraIconos historial={() => { bitacoras(persona) }} />
            <Separador tamaño="0.5em" orientacion="horizontal" />
        </div>
    );
}

const ListarPersonas = ({ personas, bitacoras, rol }) => {
    if (personas.length == 0) {
        return (
            <p style={{ marginTop: 0, color: "rgb(117, 117, 117)", fontFamily: "Montserrat" }}>Aún no hay colaboradores registrados</p>
        )
    }
    return personas.map((_Persona) => {
        return (
            <EntradaPersona
                key={_Persona.IDPersona}
                persona={_Persona}
                bitacoras={bitacoras}
                rol={rol}
            />
        );
    })
}

const Detalles = ({ proyecto, rol }) => {

    const [persona, setPersona] = useState(null)
    const [mostrarBitacoras, setMostrarBitacoras] = useState(false)

    return (
        mostrarBitacoras ?
            <Bitacoras
                persona={persona}
                proyecto={proyecto}
                rol={rol}
                regresar={() => { setMostrarBitacoras(false) }} />
            :
            <>
                <p style={{ fontSize: "1.3em", marginTop: 0, marginBottom: "0.5em" }}>
                    Detalles
                </p>
                <p style={{ fontSize: "1.1em", marginTop: 0, marginBottom: "0.5em" }}>
                    {proyecto.Proyecto}
                </p>
                <p style={{ marginTop: 0, color: "rgb(117, 117, 117)", fontFamily: "Montserrat" }}>{proyecto.Descripcion}</p>

                <div className="divider"></div>

                <p style={{ fontSize: "1.3em" }}>
                    Colaboradores
            </p>

                <ListarPersonas
                    personas={proyecto.Personas ? proyecto.Personas : []}
                    rol={rol}
                    bitacoras={(_Persona) => {
                        setPersona(_Persona)
                        setMostrarBitacoras(true)
                    }} />
            </>
    )
}

export default Detalles;