import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { setGlobal } from 'reactn';
import { generarVinculoFoto } from './librerias/funciones';

setGlobal({
  LinkActivo: '',
  abrirInfo: false,
  abrirAcerca: false,
  dashSideAbierto: localStorage.getItem('estadoDash') == null ? true : JSON.parse(localStorage.getItem('estadoDash')),
  perfilUsuario: {},
  urlFoto: generarVinculoFoto("123456789")
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
