import React, { useState, useEffect } from 'react';
import BarraIconos from '../../../componentes/BarraIconos.js/BarraIconos';
import MiniLoaderWrap from '../../../componentes/MiniLoaderWrap/MiniLoaderWrap';
import Separador from '../../../componentes/Separador/Separador';
import { formatearFecha, formatearNombre, generarVinculoFoto, getDatos, mostrarError, permitirVer, sleepear } from '../../../librerias/funciones';
import Hojas from './Hojas';

const EntradaPersona = ({ persona, rol }) => {
    return (
        <div style={{ marginTop: "0.5em", marginBottom: "0.5em", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
            <img className="z-depth-1" src={generarVinculoFoto(persona.Foto)} alt="" width="60em" height="60em" style={{ backgroundColor: "rgb(184, 183, 180)", borderRadius: "30em", marginRight: "2em", objectFit: "cover" }} />
            <div style={{ flex: 1 }}>
                <p style={{ margin: 0, fontWeight: "400", fontSize: "1.1em" }}>{formatearNombre(`${persona.Nombre},${persona.Apellido}`)}</p>
                <div style={{ width: "100%", flexWrap: "wrap", display: "flex", flexDirection: "row" }}>
                    <p style={{ margin: 0, color: "rgb(117, 117, 117)" }}><span style={{ fontSize: "0.85em" }}>Puesto:</span> <strong style={{ fontSize: "0.95em" }}>{persona.Puesto}</strong></p>
                    <Separador tamaño="0.5em" orientacion="horizontal" />
                    <p style={{ margin: 0, color: "rgb(117, 117, 117)" }}><span style={{ fontSize: "0.85em" }}>Tel.</span> <strong style={{ fontSize: "0.95em" }}>{persona.Telefono}</strong></p>
                    {
                        permitirVer(rol, ["Administrador", "Coordinador de programa"]) &&
                        <>
                            <Separador tamaño="0.5em" orientacion="horizontal" />
                            <p style={{ margin: 0, color: "rgb(117, 117, 117)" }}><span style={{ fontSize: "0.85em" }}>Salario:</span> <strong style={{ fontSize: "0.95em" }}>Q{persona.Sueldo}</strong></p>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

const EntradaBitacora = ({ bitacora, dias }) => {
    return (
        <>
            <div style={{ paddingTop: "0.5em", paddingBottom: "0.5em", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                <p style={{ flex: 1, color: "rgb(117, 117, 117)", fontWeight: "500" }}>{`Semana del ${formatearFecha(bitacora.Dias[0].Fecha)} al ${formatearFecha(bitacora.Dias[bitacora.Dias.length - 1].Fecha)}`}</p>
                <Separador tamaño="0.5em" orientacion="horizontal" />
                <BarraIconos adelante={() => { dias(bitacora) }} />
                <Separador tamaño="0.5em" orientacion="horizontal" />
            </div>
            <div className="divider"></div>
        </>
    )
}

const ListarBitacoras = ({ bitacoras, dias }) => {
    if (bitacoras.length == 0) {
        return (
            <p style={{ marginTop: 0, color: "rgb(117, 117, 117)", fontFamily: "Montserrat" }}>Aún no hay hojas de tiempo registradas</p>
        )
    }
    return bitacoras.map((_Bitacora) => {
        return (
            <EntradaBitacora key={_Bitacora.Bitacora.IDBitacora} bitacora={_Bitacora} dias={dias} />
        );
    })
}

const Bitacoras = ({ persona, proyecto, regresar, rol }) => {

    const [bitacoras, setBitacoras] = useState([]);
    const [cargando, setCargando] = useState(true)
    const [mostrarDias, setMostrarDias] = useState(false);
    const [bitacora, setBitacora] = useState(null)

    const cargarDatos = async () => {
        setCargando(true)

        let datos;

        while (true) {
            datos = await getDatos(`/log/${persona.DPI}/${proyecto.ProyectoID}`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setBitacoras(datos.Mensaje.reverse())
        setCargando(false)
    }

    useEffect(() => {
        cargarDatos();
    }, [])

    return (
        mostrarDias ?
            <Hojas bitacora={bitacora} atras={() => { setMostrarDias(false) }} />
            :
            <>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ marginRight: "0.5em" }} onClick={regresar} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ cursor: "pointer", color: 'black', fontSize: "1.75em" }} name="arrow-back-outline" />
                    </div>
                    <p style={{ fontSize: "1.3em", marginTop: 0, marginBottom: "0.5em" }}>
                        Hojas de Tiempo
                </p>
                </div >

                <EntradaPersona persona={persona} rol={rol} />

                <p style={{ fontSize: "1.3em", marginTop: "0.5em", marginBottom: "0.5em" }}>
                    Historial
            </p>

                <MiniLoaderWrap cargando={cargando}>
                    <ListarBitacoras bitacoras={bitacoras} dias={(_Bitacora) => {
                        setBitacora(_Bitacora)
                        setMostrarDias(true)
                    }} />
                </MiniLoaderWrap>
            </>
    );
}

export default Bitacoras;