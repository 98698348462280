import React, { useState, useEffect } from 'react';
import ConfirmarBorrar from '../../../componentes/ConfirmarBorrar/ConfirmarBorrar';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';
import { getDatos, sleepear, insertAptitud, mostrarError, mostrarOK, updateAptitud, insertDatos } from '../../../librerias/funciones';
import { df_NuevaAptitud, } from '../../../librerias/plantillasforms';
import MiniLoaderWrap from '../../../componentes/MiniLoaderWrap/MiniLoaderWrap';

const paddingRotar = "1em";

const EntradaBloque = ({ bloque, cambiar }) => {

    const [valor, setValor] = useState(bloque.Estado)
    const [cargando, setCargando] = useState(false);

    const cambiarValor = () => {

        const nuevoValor = !valor;

        setCargando(true)

        cambiar(bloque, nuevoValor, (_Valor) => {
            setCargando(false)
            setValor(_Valor)
        })
    }

    return (
        <div className="Evaluaciones_Entrada" style={{ position: "relative", left: "-0.9em", alignItems: "center", paddingTop: "0.5em", paddingBottom: "0.5em", display: "flex", flexDirection: "row" }}>
            {
                cargando ?
                    <div className="spinear btn-flat" style={{ paddingLeft: paddingRotar, paddingRight: paddingRotar, cursor: "unset", display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <ion-icon style={{ color: 'rgb(162, 162, 162)', fontSize: "1.4em" }} name="sync-outline" />
                    </div>
                    :
                    <label style={{ paddingTop: "0.5em" }}>
                        <input checked={valor} onChange={cambiarValor} type="checkbox" style={{ position: "unset" }} />
                        <span></span>
                    </label>
            }
            <p style={{ margin: 0, color: valor ? "rgb(51, 52, 49)" : "#757575", fontWeight: "400", fontSize: "1em" }}>{bloque.Nombre}</p>
        </div>
    )
}

const ListarBloques = ({ bloques, cambiar }) => {
    return (
        [...bloques].reverse().map((_Bloque) => {
            return (
                <EntradaBloque
                    key={_Bloque.IDBloque}
                    bloque={_Bloque}
                    cambiar={cambiar}
                />
            )
        })
    );
}

const Evaluaciones = ({ evaluacion }) => {

    const [cargando, setCargando] = useState(true);
    const [bloques, setBloques] = useState([])

    const cargarDatos = async () => {

        let datos;

        while (true) {
            datos = await getDatos(`/form/${evaluacion.IDFormulario}`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setBloques(datos.Mensaje)
        setCargando(false);
    }

    const cambiarBloque = async (_Bloque, _Estado, _Completado) => {

        const form = new FormData();
        form.append("Accion", _Estado);
        form.append("Formulario", evaluacion.IDFormulario)
        form.append("Bloque", _Bloque.IDBloque)

        let datos;

        while (true) {
            datos = await insertDatos(form, `/formBlocks`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            _Completado(!_Estado);
            return;
        }

        _Completado(_Estado);

        const nuevosBloques = [...bloques];
        for (let i = 0; i < nuevosBloques.length; i++) {
            if (nuevosBloques[i].IDBloque == _Bloque.IDBloque) {
                nuevosBloques[i].Estado = _Estado ? 1 : 0;
                break;
            }

        }

        setBloques(nuevosBloques)
    }

    useEffect(() => {
        cargarDatos();
    }, [])

    return (
        <MiniLoaderWrap cargando={cargando}>
            <div className="row" style={{ margin: 0 }}>
                <div className="col s12">
                    <p style={{ fontSize: "1.3em" }}>Listado de Bloques</p>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                        <ListarBloques
                            bloques={bloques}
                            cambiar={cambiarBloque}
                        />
                    </div>
                </div>
            </div>
        </MiniLoaderWrap>
    )
}

export default Evaluaciones;