import React, { useState, useEffect } from 'react';
import { getDatos, mostrarError, sleepear } from '../../librerias/funciones';
import VistaLogin from '../../vistas/Login/Login';
import Restablecer from '../../vistas/Login/Restablecer';
import MiniLoaderWrap from '../MiniLoaderWrap/MiniLoaderWrap';
import LoadSplash from './Componentes/LoadSplash';

const Cargando = () => {
    return (
        <div style={{ width: "100%", flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{ width: "100%", padding: "1em", maxWidth: "15em" }}>
                <div className="progress" style={{ backgroundColor: "rgba(162, 162, 162,0.25)", margin: 0, width: "100%" }}>
                    <div style={{ backgroundColor: "#3f92ba" }} className="indeterminate"></div>
                </div>
            </div>
        </div>
    )
}

const LoadingScreen = ({ cargado }) => {

    const [vista, setVista] = useState("cargando")

    const cargarDatos = async () => {
        let datos;

        while (true) {
            datos = await getDatos('/login');
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            setVista('login')
            return;
        }

        if (datos.Login) {
            if (datos.Reset) setVista('restablecer')
            else setVista("splash")
        }
        else setVista("login")
    }

    useEffect(() => {
        cargarDatos();
    }, [])

    const SelectorVista = () => {
        switch (vista) {
            case "splash":
                return <LoadSplash cargado={cargado} login={() => { setVista("login") }} />
            case "login":
                return <VistaLogin restablecer={() => { setVista('restablecer') }} logear={() => { setVista("splash") }} />
            case "restablecer":
                return <Restablecer logear={() => { setVista('splash') }} />
            default:
                return <Cargando />;
        }
    }
    return (
        <SelectorVista />
    )
}

export default LoadingScreen;