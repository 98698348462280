import React, { useState } from 'react';

const convertirVacio = (_Texto) => {
    if (_Texto == null || _Texto == "") return "";
    else return _Texto;
}

const Area = ({ campo, datos }) => {

    const [valor, setValor] = useState(convertirVacio(campo.inicial))

    datos.current[campo.identificador] = valor;

    const cambiarCampo = (e) => {
        setValor(e.target.value)
    }

    return (
        <div className={`col input-field ${campo.tamaño}`} style={{ margin: 0, marginTop: "0.5em" }}>
            <p style={{ margin: 0, color: "#757575", fontWeight: "400" }}>{campo.campo}</p>
            <textarea onChange={cambiarCampo} value={valor} placeholder={campo.campo} style={{ height: "8em", maxHeight: "8em", overflowY: "auto", boxSizing: "border-box", margin: 0, fontFamily: "Montserrat", fontSize: "0.95em" }} className="materialize-textarea DynamicForm_Entrada"></textarea>
        </div>
    );
}

export default Area;