import React, { useRef, useEffect } from 'react';
import M from 'materialize-css';

const DropOpcion = ({ opcion, divisor, onClick }) => {
    return (
        <>
            <li><a onClick={onClick} style={{ fontSize: "1em", color: "rgb(51, 52, 49)" }} >{opcion}</a></li>
            {divisor && <div className="divider"></div>}
        </>
    )
}

// const ListarOpciones = ({ opciones }) => {
//     return opciones.map((_Opcion, _Key) => {
//         return <Opcion key={_Key} opcion={_Opcion} />
//     })
// }

const DropIcono = ({ contenedor, tipo, id, children }) => {

    const dropRef = useRef();

    useEffect(() => {
        M.Dropdown.init(dropRef.current, {
            constrainWidth: false,
            container: contenedor
        });
    }, []);

    return (
        <>
            <a ref={dropRef} className={`dropdown-trigger`} data-target={`${tipo}-${id}`} style={{ height: "100%", paddingLeft: "1rem", paddingRight: "1rem", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <ion-icon name="ellipsis-horizontal" style={{ color: "rgb(51, 52, 49)", fontSize: "1.5em" }}></ion-icon>
            </a>
            <ul style={{ backgroundColor: "transparent", overflow: "visible" }} id={`${tipo}-${id}`} className='dropdown-content z-depth-0'>
                <center className="Personas_floatopciones" style={{ width: "15em", backgroundColor: "white", borderRadius: "0.5em", overflow: "hidden" }}>
                    {children}
                </center>
            </ul>
        </>
    );
}

export { DropIcono, DropOpcion }