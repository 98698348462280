import React, { useRef, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Vinculos } from '../../librerias/vinculos';
import PP from '../../imagenes/pp.jpeg';

import "./SideVinculos.css";
import { Datos, Paleta } from '../../librerias/sistema';
import { useGlobal } from 'reactn';

import M from 'materialize-css';
import { useHistory } from 'react-router-dom'
import { cerrarSesion, formatearNombre, generarVinculoFoto, getDatos, mostrarError, permitirVer, sleepear } from '../../librerias/funciones';

// import Logo from '../../imagenes/logoCRS.png';

const VinculoSeparador = () => {
    return (
        <div style={{ width: "100%", height: "0.5em", backgroundColor: "rgba(0, 0, 0, 0.025)" }}></div>
    );
}

const ListaVinculos = (_props) => {

    const [sideAbierto, setSideAbierto] = useGlobal('dashSideAbierto');
    const perfilUsuario = useGlobal('perfilUsuario')[0];

    return (
        Vinculos.map((_Vinculo, _Key) => {

            let clase = "Sidevinculos_vinculo";
            if (_Vinculo[0] == _props.activo) clase = clase += " Sidevinculos_activo";

            let Texto = _Vinculo[0];
            if (_props.tipo == "dash" && sideAbierto == false) Texto = "";

            let tamañoTexto = "1em";
            if (_props.tipo == "dash") {
                if (!sideAbierto) tamañoTexto = "0em"
            }

            if (permitirVer(perfilUsuario.Rol, _Vinculo[3]))

                return (
                    <li className={clase} key={_Key}><Link onClick={_props.cerrar} to={_Vinculo[1]} style={{ paddingLeft: "2em", display: "flex", flexDirection: "row", alignItems: "center", color: Paleta.textoCuarto, fontWeight: "400" }}><ion-icon style={{ color: Paleta.textoPrimario, fontSize: "1.8em" }} name={_Vinculo[2]}></ion-icon><span className="animarDashTexto" style={{ fontSize: tamañoTexto, marginLeft: "1em" }}>{Texto}</span></Link></li>
                );
            return null;
        })
    );
}

const ContenedorLogo = () => {
    return (
        <center>
            {/* <img style={{ marginBottom: "1em", marginTop: "1em" }} src={Datos.Logo} alt="Logo" width="auto" height="60em" /> */}
        </center>
    );
}

const ItemsHeader = () => {
    const dropRef = useRef();
    const [abrirInfo, setAbrirInfo] = useGlobal('abrirInfo');
    const [perfilUsuario, setPerfilUsuario] = useGlobal('perfilUsuario');
    const [modalAcerca, setModalAcerca] = useGlobal('abrirAcerca');
    const [urlFoto, setURLFoto] = useGlobal('urlFoto');
    const history = useHistory();

    useEffect(() => {
        M.Dropdown.init(dropRef.current, { alignment: "right", constrainWidth: false });
    }, [])

    const mostarPerfil = () => {
        setAbrirInfo(true);
    }

    const mostrarAcerca = () => {
        setModalAcerca(true)
    }

    const logout = async () => {

        let datos;

        while (true) {
            datos = await cerrarSesion();
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Login) {
            mostrarError(datos.Mensaje);
            return false;
        }

        history.push("/");

        window.location.reload()
    }

    return (
        <>
            <img className="miniSombra" src={`${generarVinculoFoto(perfilUsuario.Foto)}`} alt="" width="40em" height="40em" style={{ objectFit: "cover", borderRadius: "20em", marginRight: "1em" }} />
            <p style={{ color: "rgb(51, 52, 49)", fontFamily: "Montserrat", fontWeight: 600, fontSize: "1.1em", margin: 0 }}>{formatearNombre(`${perfilUsuario.Nombre},${perfilUsuario.Apellido}`)}</p>
            <div style={{ position: "relative", backgroundColor: "transparent" }}>
                <a ref={dropRef} className={`dropdown-trigger`} data-target={`nav-header-float`} style={{ height: "100%", paddingLeft: "1rem", paddingRight: "1rem", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <ion-icon name="chevron-down-outline" style={{ color: "rgba(53, 53, 53,0.8)", fontSize: "2em" }}></ion-icon>
                </a>
                <ul style={{ backgroundColor: "transparent", overflow: "visible" }} id={`nav-header-float`} className='dropdown-content z-depth-0'>
                    <center className="Headervinculo_floatboton" style={{ width: "12em", backgroundColor: Paleta.textoTerciario, borderRadius: "1em", overflow: "hidden" }}>
                        <li><a onClick={mostarPerfil} style={{ fontSize: "1em", color: Paleta.textoSecundario, fontWeight: "400", display: "flex", flexDirection: "row", alignItems: "center" }} href="#!"><ion-icon name="person-outline" style={{ color: Paleta.textoPrimario, marginRight: "0.5em", fontSize: "1.5em" }}></ion-icon>Mi Perfil</a></li>
                        <li><a onClick={mostrarAcerca} style={{ fontSize: "1em", color: Paleta.textoSecundario, fontWeight: "400", display: "flex", flexDirection: "row", alignItems: "center" }} href="#!"><ion-icon name="information-circle-outline" style={{ color: Paleta.textoPrimario, marginRight: "0.5em", fontSize: "1.5em" }}></ion-icon>Acerca De</a></li>
                        <li><a onClick={logout} style={{ fontSize: "1em", color: Paleta.textoSecundario, fontWeight: "400", display: "flex", flexDirection: "row", alignItems: "center" }} href="#!"><ion-icon name="log-out-outline" style={{ color: Paleta.textoPrimario, marginRight: "0.5em", fontSize: "1.5em" }}></ion-icon>Cerrar Sesión</a></li>
                    </center>
                </ul>
            </div>
        </>
    );
}

const SideVinculos = (props) => {
    const [linkActivo, setLinkActivo] = useGlobal('LinkActivo');

    return (
        <ListaVinculos cerrar={props.cerrar} activo={linkActivo} tipo={props.tipo} />
    );
}

export {
    ItemsHeader,
    SideVinculos,
    VinculoSeparador,
    ContenedorLogo
};