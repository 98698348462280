import React from 'react';
import { convertirMinutosHora, formatearFecha } from '../../../librerias/funciones';

const Titulo = ({ campo, valor }) => {
    return (
        <div>
            <p style={{ margin: 0, color: "rgb(117, 117, 117)" }}>{campo}</p>
            <p style={{ margin: 0 }}>{valor}</p>
        </div>
    );
}

const EntradaDia = ({ dia }) => {
    return (
        <>
            <div style={{ paddingTop: "0.75em", paddingBottom: "0.75em", paddingRight: "1em", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
                <Titulo campo="Fecha" valor={formatearFecha(dia.Fecha)} />
                <Titulo campo="Horas" valor={convertirMinutosHora(dia.Minutos)} />
                <Titulo campo="Inasistencia" valor={dia.Inasistencia ? "Sí" : "No"} />
            </div>
            <div className="divider"></div>
        </>
    );
}

const ListarDias = ({ dias }) => {
    return dias.map((_Dia) => {
        return (
            <EntradaDia key={_Dia.IDDia} dia={_Dia} />
        )
    })
}

const Hojas = ({ bitacora, atras }) => {
    return (
        <>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ marginRight: "0.5em" }} onClick={atras} className="noHighLightWrapper botonFlatWrap">
                    <ion-icon className="noHighLight" style={{ cursor: "pointer", color: 'black', fontSize: "1.75em" }} name="arrow-back-outline" />
                </div>
                <p style={{ fontSize: "1.3em", marginTop: 0, marginBottom: "0.5em" }}>
                    Detalle de Semana
                </p>
            </div >
            <p style={{ marginBottom: 0, fontSize: "1.1em" }}>Notas</p>
            <p style={{ marginTop: 0, fontSize: "0.95em", color: "rgb(117, 117, 117)", fontFamily: "Montserrat" }}>{bitacora.Bitacora.Notas}</p>

            <p style={{ fontSize: "1.3em", marginTop: 0, marginBottom: 0 }}>
                Días
            </p>
            <ListarDias dias={bitacora.Dias} />
        </>
    )
}

export default Hojas;