import React, { useState, useEffect } from 'react';
import { mostrarError, obtenerPerfil, sleepear } from '../../../librerias/funciones';

const LoadSplash = ({ cargado, login }) => {

    const [opacidad, setOpacidad] = useState(1);

    const cargarDatos = async () => {

        let datos;

        while (true) {
            datos = await obtenerPerfil();
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError("Hubo un error accediendo al sistema");
            return;
        }

        if (datos.Login == false) {
            login();
            return;
        }

        setOpacidad(0)

        cargado(datos.Mensaje);
        // setTimeout(() => {
        //     cargado(datos.Mensaje);
        // }, 1000);

    }

    useEffect(() => {
        setTimeout(() => {
            cargarDatos();
        }, 1000);
    }, [])

    return (
        <div className="animarOpacidad" style={{ opacity: opacidad, width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

            <div style={{ marginBottom: "1em" }}>
                <img src="/img/quazar13.png" alt="" width="180em" height="auto" />
            </div>

            <div className="progress" style={{ backgroundColor: "rgba(162, 162, 162,0.25)", margin: 0, width: "12em" }}>
                <div style={{ backgroundColor: "#3f92ba" }} className="indeterminate"></div>
            </div>
            <p style={{ margin: 0, marginTop: "1.2em", fontFamily: "Montserrat", fontSize: "1.1em", fontWeight: "500", color: "#5f6268" }}>
                Cargando Panel de Control
            </p>
        </div>
    )
}

export default LoadSplash;