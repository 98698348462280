import React from 'react';
import TituloPagina from '../TituloPagina/TituloPagina';
import "./LoadWrap.css";

const Lineal = () => {
    return (
        <div className="progress" style={{ backgroundColor: "rgba(162, 162, 162,0.25)", margin: 0, width: "100%", maxWidth: "12em" }}>
            <div style={{ backgroundColor: "#3f92ba" }} className="indeterminate"></div>
        </div>
    );
}

const Circular = () => {
    return (
        <div className="preloader-wrapper big active">
            <div className="spinner-layer" style={{ borderColor: "#3f92ba" }}>
                <div className="circle-clipper left">
                    <div className="circle"></div>
                </div><div className="gap-patch">
                    <div className="circle"></div>
                </div><div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
            </div>
        </div>
    );
}

const Audio = () => {
    return (
        <div className="LoadWrap_Audio">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}

const Windows = () => {
    return (
        <div className="LoadWrap_Windows">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}

const LoadWrap = (props) => {
    return (
        <>
            <TituloPagina titulo={props.titulo} />
            {
                props.cargando ?
                    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        {/* <Audio /> */}
                        {/* <Windows /> */}
                        <Lineal />
                        {/* <Circular /> */}
                    </div>
                    :
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", flexWrap: "wrap" }}>
                        <div className="container DashWrap_Contenedor">
                            {props.children}
                        </div>
                    </div>
            }
        </>

    );
}

export default LoadWrap;