import { useState } from 'react';

const Identificador = ({ campo, datos }) => {
    const [valor, setValor] = useState(campo.inicial)

    datos.current[campo.identificador] = valor;

    return (
        null
    )
}

export default Identificador;