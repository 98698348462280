import React, { useRef } from 'react';
import './DynamicForm.css';
import Secciones from './Componentes/Secciones';
import Botones from './Componentes/Botones';

const DynamicForm = (props) => {

    const contenedor = useRef();
    const datos = useRef({});

    const pressOK = () => {
        datos.current._modo = props.titulo;
        props.botonOK.press(datos.current);
    }

    return (
        <div ref={contenedor} className="row" style={{ margin: 0 }}>
            <div className="col s12" style={{ margin: 0 }}>
                <p style={{ margin: 0, marginBottom: "0.25em", fontSize: "1.3em" }}>
                    {props.titulo}
                </p>
            </div>
            <Secciones secciones={props.secciones} editable={props.editable} contenedor={contenedor} datos={datos} onChange={props.onChange} />
            <div style={{ margin: 0, marginTop: "1.5em", marginBottom: "0.25em" }} className="col s12">
                {props.actualizando
                    ?
                    <div className="progress" style={{ backgroundColor: "rgba(162, 162, 162,0.25)" }}>
                        <div style={{ backgroundColor: "#3f92ba" }} className="indeterminate"></div>
                    </div>
                    :
                    <Botones
                        ok={props.botonOK.titulo}
                        editable={props.editable}
                        onCancel={props.botonCancel}
                        onOK={pressOK}
                        noCancel={props.noCancel}
                        noOK={props.noOK}
                    />
                }
            </div>
        </div>
    );
}

export default DynamicForm;