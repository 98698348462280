import React from 'react';

const obtenerPorcentaje = (_Cantidad, _Total) => {
    return ((_Cantidad / _Total) * 100).toFixed(2)
}

const BarraEstadistica = ({ titulo, total, cantidad }) => {
    return (
        <>
            <p style={{ fontWeight: "500", marginBottom: 0, marginTop: 0 }}>{titulo}</p>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <div style={{ margin: 0, backgroundColor: "#d8d8d8", height: "0.6em", borderRadius: "1em" }} className="progress">
                    <div className={`determinate`} style={{ backgroundColor: "#e17055", width: `${obtenerPorcentaje(cantidad, total)}%` }}></div>
                </div>
                <p style={{ fontSize: "1.1em", marginTop: 0, marginBottom: 0, marginLeft: "1em" }}>{cantidad}<span style={{ fontSize: "0.75em" }}>/{total}</span></p>
            </div>
        </>
    );
}

const contarEstados = (personas) => {
    const estados = [];
    personas.forEach(_Persona => {
        estados.push(_Persona.Estado)
    });

    const counts = {};
    for (let i = 0; i < estados.length; i++) {
        const estado = estados[i];
        counts[estado] = counts[estado] ? counts[estado] + 1 : 1;
    }

    const nuevoArray = [];

    for (const propiedad in counts) {
        nuevoArray.push({
            titulo: propiedad,
            cantidad: counts[propiedad]
        })
    }

    return nuevoArray;
}

const ListarEstados = ({ personas }) => {
    return contarEstados(personas).map((_Estado, _Key) => {
        return (
            <BarraEstadistica key={_Key} titulo={_Estado.titulo} total={personas.length} cantidad={_Estado.cantidad} />
        );
    })
}

const CardEstados = ({ personas }) => {

    return (
        <div className="col s12 m6" style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>
            <div className="miniSombra" style={{ backgroundColor: "white", borderRadius: "0.5em", padding: "1.5em", width: "100%", height: "100%" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <p style={{ fontFamily: "Montserrat", fontSize: "1.5em", fontWeight: "500", marginTop: 0, marginBottom: "0.5em" }}>Personas</p>
                    <p style={{ fontFamily: "Montserrat", fontSize: "1.5em", fontWeight: "500", marginTop: 0, marginBottom: "0.5em" }}> <span style={{ fontSize: "0.7em" }}>Total:</span> <span style={{ fontWeight: "500" }}>{personas.length}</span></p>
                </div>
                <ListarEstados personas={personas} />
            </div>
        </div>
    );
}

export default CardEstados;