import React, { useState } from 'react';
import ConfirmarBorrar from '../../../componentes/ConfirmarBorrar/ConfirmarBorrar';
import DynamicForm from '../../../componentes/DynamicForm/DynamicForm';
import { deleteAptitud, propiedadVacia, sleepear, insertAptitud, mostrarError, mostrarOK, updateAptitud, permitirVer } from '../../../librerias/funciones';
import { df_NuevaAptitud, } from '../../../librerias/plantillasforms';

const VistaDetalle = ({ aptitud, borrar, editar, rol }) => {
    return (
        <div style={{ paddingTop: "0.5em", paddingBottom: "0.5em", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <p style={{ flex: 1, margin: 0, fontWeight: "400", fontSize: "1em" }}>{aptitud}</p>
            {
                permitirVer(rol, ["Administrador", "Coordinador de programa"]) &&
                <>
                    <div onClick={borrar} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ marginLeft: "0.5em", marginRight: "0.5em", cursor: "pointer", color: 'rgb(243, 91, 38)', fontSize: "1.5em" }} name="trash-outline" />
                    </div>
                    <div onClick={editar} className="noHighLightWrapper botonFlatWrap">
                        <ion-icon className="noHighLight" style={{ cursor: "pointer", color: 'rgb(0, 153, 242)', fontSize: "1.5em" }} name="create-outline" />
                    </div>
                </>
            }
        </div >
    );
}

const VistaEditar = ({ dato, id, editar, completado }) => {
    const [valor, setValor] = useState(dato)
    const [subiendo, setSubiendo] = useState(false)

    const funcionEditar = () => {
        setSubiendo(true)
        editar(valor, () => {
            setSubiendo(false)
            completado()
        })
    }

    const cambiarValor = (e) => {
        const nuevoValor = { ...valor }
        nuevoValor.Aptitud = e.target.value;
        setValor(nuevoValor)
    }

    return (
        <div style={{ paddingTop: "0.5em", paddingBottom: "0.5em", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>

            <div className='row' style={{ margin: 0, width: "100%", marginBottom: "0.5em" }}>
                <div className={`col input-field s12`} style={{ margin: 0, padding: 0 }}>
                    <p style={{ margin: 0, color: "#757575", fontWeight: "400" }}>Aptitud</p>
                    <input value={valor.Aptitud} onChange={cambiarValor} className="DynamicForm_Entrada" style={{ boxSizing: "border-box", margin: 0, fontFamily: "Montserrat", fontSize: "0.95em" }} placeholder="Aptitud" type="text" />
                </div>

                <div className={`col input-field s12`} style={{ margin: 0, marginTop: "1em", padding: 0 }}>
                    {subiendo ?
                        <div className="progress" style={{ backgroundColor: "rgba(162, 162, 162,0.25)", margin: 0, width: "100%" }}>
                            <div style={{ backgroundColor: "#3f92ba" }} className="indeterminate"></div>
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", overflow: "hidden", flexWrap: "wrap" }}>
                            <a onClick={funcionEditar} style={{ fontWeight: "500", backgroundColor: "#3f92ba", marginLeft: "0.5em" }} className="waves-effect waves-light btn z-depth-0">Guardar</a>
                        </div >
                    }
                </div>
            </div>
        </div>
    );
}

const VistaBorrar = ({ borrar }) => {
    return (
        <div className="row" style={{ margin: 0, paddingBottom: "1em" }}>
            <ConfirmarBorrar titulo="Borrar Aptitud" descripcion="¿Seguro desea borrar la aptitud? Esta acción no se puede deshacer" onClick={borrar} />
        </div>
    );
}

const EntradaAptitud = ({ aptitud, borrar, editar, rol }) => {

    const [vista, setVista] = useState("Detalle")

    const funcionBorrar = (_Borrar) => {
        if (_Borrar) {
            borrar(aptitud, () => {
                setVista("Detalle")
            })
        }
        else setVista("Detalle")
    }

    const SelectorVista = () => {
        switch (vista) {
            case "Detalle":
                return (<VistaDetalle
                    rol={rol}
                    aptitud={aptitud.Aptitud}
                    borrar={() => { setVista("Borrar") }}
                    editar={() => { setVista("Editar") }}
                />)
            case "Borrar":
                return (<VistaBorrar
                    borrar={funcionBorrar}
                />)
            case "Editar":
                return (<VistaEditar
                    dato={aptitud}
                    editar={editar}
                    completado={() => { setVista("Detalle") }}
                />)
            default:
                return null;
        }
    }

    return (
        <div style={{ width: "100%", borderBottom: "1px solid rgb(218, 220, 224)" }}>
            <SelectorVista />
        </div>
    );
}

const ListarAptitudes = ({ aptitudes, borrar, editar, rol }) => {
    return (
        [...aptitudes].reverse().map((_Aptitud) => {
            return (
                <EntradaAptitud rol={rol} key={_Aptitud.IDAptitud} aptitud={_Aptitud} borrar={borrar} editar={editar} />
            )
        })
    );
}

const Aptitudes = ({ bloque, agregar, borrar, editar, rol }) => {

    const [estadoAgregar, setEstadoAgregar] = useState(false)
    const [actualizando, setActualizando] = useState(false)

    const agregarAptitud = async (_Datos) => {
        if (propiedadVacia(_Datos, [])) return;

        setActualizando(true)

        const form = new FormData();
        form.append('Aptitud', _Datos.aptitud)
        form.append('Bloque', bloque.IDBloque)

        let datos;

        while (true) {
            datos = await insertAptitud(form);
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        mostrarOK("Aptitud agregada")
        setEstadoAgregar(false)

        datos.Mensaje.Bloque = parseInt(datos.Mensaje.Bloque)
        agregar(datos.Mensaje)

    }

    const editarAptitud = async (_Aptitud, _Completado) => {

        if (propiedadVacia(_Aptitud, [])) {
            _Completado();
            return;
        }

        const form = new FormData();
        form.append('Aptitud', _Aptitud.Aptitud)

        let datos;

        while (true) {
            datos = await updateAptitud(form, _Aptitud.IDAptitud);
            if (datos != null) break;
            await sleepear(5000);
        }

        _Completado()

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        mostrarOK("Aptitud actualizada")

        editar(_Aptitud)

    }

    const eliminarAptitud = async (_Aptitud, _Cancelar) => {

        let datos;

        while (true) {
            datos = await deleteAptitud(_Aptitud.IDAptitud);
            if (datos != null) break;
            await sleepear(5000);
        }

        setActualizando(false)

        if (datos.Error) {
            _Cancelar()
            mostrarError(datos.Mensaje);
            return;
        }

        mostrarOK("Aptitud eliminada")
        borrar(_Aptitud)

    }

    const pressOK = async (_Datos) => {
        switch (_Datos._modo) {
            case 'Agregar Aptitud':
                agregarAptitud(_Datos)
                break;

            default:
                break;
        }
    }

    return (
        <>
            {permitirVer(rol, ["Administrador", "Coordinador de programa"]) &&
                <div style={{ marginBottom: "1em" }}>
                    {
                        estadoAgregar ?
                            <DynamicForm
                                titulo="Agregar Aptitud"
                                editable={true}
                                actualizando={actualizando}
                                botonOK=
                                {
                                    {
                                        titulo: "Agregar",
                                        press: pressOK
                                    }
                                }
                                botonCancel={() => { setEstadoAgregar(false) }}
                                secciones={df_NuevaAptitud()}
                            />
                            :
                            <div className="row" style={{ margin: 0 }}>
                                <div className="col s12">
                                    <p style={{ fontSize: "1.3em", marginTop: 0, marginBottom: "0.5em" }}>Nueva Aptitud</p>
                                    <a onClick={() => { setEstadoAgregar(true) }} className="noHighLight botonFlat" style={{ cursor: "pointer", display: "flex", alignItems: 'center' }}>
                                        <ion-icon style={{ fontSize: "1.5em", marginRight: "0.5em" }} name="add-circle-outline"></ion-icon>
                                Agregar</a>
                                </div>
                            </div>
                    }
                </div>
            }

            <div className="row" style={{ margin: 0 }}>
                <div className="col s12">
                    <p style={{ fontSize: "1.3em", marginTop: 0 }}>Listado de Aptitudes</p>
                    <ListarAptitudes rol={rol} aptitudes={bloque.Aptitudes} borrar={eliminarAptitud} editar={editarAptitud} />
                </div>
            </div>
        </>
    )
}

export default Aptitudes;