import React, { useState } from 'react';
import LoadWrap from '../../componentes/LoadWrap/LoadWrap';
import HistorialTareas from './Componentes/HistorialTareas';
import HistorialBitacoras from './Componentes/HistorialBitacoras';
import HistorialProyectos from './Componentes/HistorialProyectos';
import './Inicio.css';
import HistorialPermisos from './Componentes/HistorialPermisos';
import CardNotas from './Componentes/CardNotas';
import CardArchivos from './Componentes/CardArchivos';
import { useEffect } from 'reactn';

const CardUtilidades = () => {
    return (
        <div className="col s12 l4 push-l8" style={{ padding: 0 }}>
            <div className="row igualCol" style={{ padding: 0, marginBottom: 0 }}>
                <CardNotas />
                <CardArchivos />
            </div>
        </div>
    );
}

const SelectorHistorial = (_props) => {

    const TabsHistorial = () => {
        return (
            _props.vistas.map((_Vista, _Key) => {
                const activo = _props.activo == _Key;
                const colores = activo ? { back: "white", color: "#333431", peso: "500" } : { back: "#efefef", color: "#707070", peso: "400" };
                const padding = "1em";

                const cambiarIndice = () => {
                    _props.cambiar(_Key);
                }

                return (
                    <div onClick={cambiarIndice} key={_Key} style={{ display: "inline-block", fontFamily: "Montserrat", fontWeight: colores.peso, cursor: "pointer", paddingLeft: padding, paddingRight: padding, paddingTop: "0.25em", paddingBottom: "0.25em", backgroundColor: colores.back, color: colores.color, borderRadius: "0.25em" }}>
                        {_Vista}
                    </div>
                );
            })
        );
    }

    return (
        <div className="NoScrollBar" style={{ whiteSpace: "nowrap", paddingTop: "0.3em", paddingBottom: "0.3em", overflowX: "auto", width: "fit-content", maxWidth: "100%", backgroundColor: "#efefef", paddingLeft: "0.25em", paddingRight: "0.25em", borderRadius: "0.25em" }}>
            <TabsHistorial />
        </div>
    );
}

// const VistaHistorial = () => {

//     switch (_props.vistas[indiceActivo]) {
//         case "Tareas":
//             return <HistorialTareas />
//         case "Proyectos":
//             return <HistorialProyectos />
//         case "Permisos":
//             return <HistorialPermisos />
//         case "Hojas de Tiempo":
//             return <HistorialBitacoras />
//         default:
//             return null;
//     }
// }

const VistaHistorial = ({ vistas, activo }) => {

    switch (vistas[activo]) {
        case "Tareas":
            return <HistorialTareas />
        case "Proyectos":
            return <HistorialProyectos />
        case "Permisos":
            return <HistorialPermisos />
        case "Hojas de Tiempo":
            return <HistorialBitacoras />
        default:
            return null;
    }
}

const CardHistorial = (_props) => {

    const [indiceActivo, setIndiceActivo] = useState(null);
    // localStorage.getItem('inicioActivo') == null ? 0 : JSON.parse(localStorage.getItem('inicioActivo'))

    const cambiarIndice = (_Indice) => {
        localStorage.setItem('inicioActivo', JSON.stringify(_Indice))
        setIndiceActivo(_Indice);
    }

    useEffect(() => {
        setIndiceActivo(localStorage.getItem('inicioActivo') == null ? 0 : JSON.parse(localStorage.getItem('inicioActivo')))
    }, [])

    return (
        <div className="col s12 l8 pull-l4 Inicio_ContenedorHistorial" style={{ height: "42em" }} >
            <div className="miniSombra" style={{ display: "flex", flexDirection: "column", overflow: "hidden", padding: "1em", height: "100%", backgroundColor: "white", borderRadius: "0.5em" }}>
                <SelectorHistorial activo={indiceActivo} vistas={_props.vistas} cambiar={cambiarIndice} />
                <VistaHistorial vistas={_props.vistas} activo={indiceActivo} />
            </div>
        </div >
    );
}

const Inicio = () => {

    return (
        <LoadWrap cargando={false} titulo="Inicio">

            <div className="row igualCol" >
                <CardUtilidades />
                <CardHistorial
                    vistas={["Tareas", "Proyectos", "Permisos", "Hojas de Tiempo"]}
                />
            </div>

        </LoadWrap>
    );
}

export default Inicio;