import React from 'react';

const TituloBoton = (props) => {
    return (
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
            <p style={{ marginTop: 0, marginBottom: 0, marginRight: "1em", color: "#333431", fontFamily: "Montserrat", fontSize: "2em", fontWeight: "500" }}>{props.titulo}</p>
            {
                props.esconder &&
                <a onClick={props.onClick} style={{ marginTop: "0.5em", marginBottom: "0.5em", display: "block", backgroundColor: "#0190ee", borderRadius: "2em" }} className="waves-effect waves-light btn"><i style={{ marginRight: "0.25em" }} className="material-icons left">add</i>{props.boton}</a>
            }
        </div>
    );
}

export default TituloBoton;