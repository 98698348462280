import React from 'react';

const WrapperHistorial = _props => {
    return (
        <>
            {_props.boton ?
                _props.cargando ?
                    null :
                    <a onClick={_props.onClick} className="botonFlat" style={{ color: "rgb(0, 153, 242)", marginTop: "1em", display: "block", cursor: "pointer" }}>{_props.boton}</a>
                :
                null}
            <div style={{ paddingLeft: "1em", paddingRight: "1em", flex: "1", overflowY: "auto", width: "100%", backgroundColor: "#f5f5f5", marginTop: "1em" }}>
                {_props.cargando ?
                    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div className="preloader-wrapper active">
                            <div className="spinner-layer" style={{ borderColor: "#3f92ba" }}>
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div><div className="gap-patch">
                                    <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    _props.children
                }
            </div>
        </>
    );
}

export default WrapperHistorial;