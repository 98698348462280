import React, { useEffect, useRef } from 'react';
import M from 'materialize-css';

import styles from './BarraNavegacion.module.css';

import { Datos, Paleta } from '../../librerias/sistema';

import { SideVinculos, VinculoSeparador, ContenedorLogo, ItemsHeader } from '../SideVinculos/SideVinculos';
import HeaderVinculo from '../HeaderVinculo/HeaderVinculo';

import { useGlobal } from 'reactn';

const BarraNavegacion = () => {

    const [linkActivo, setLinkActivo] = useGlobal('LinkActivo');
    const [sideAbierto, setSideAbierto] = useGlobal('dashSideAbierto');
    const instSide = useRef();

    const cerrarSide = () => {
        instSide.current.close();
    }

    useEffect(() => {
        instSide.current = M.Sidenav.init(document.querySelector('.sidenav'), {});

        return (() => {
            document.querySelectorAll(".sidenav-overlay").forEach(element => {
                element.click();
            });
        })
    }, []);

    const toglearSide = () => {
        localStorage.setItem('estadoDash', JSON.stringify(!sideAbierto));
        setSideAbierto(!sideAbierto);
    }

    return (
        <>
            <nav className={`white z-depth-0 ${styles.miBarra}`}>
                <div className="nav-wrapper">
                    <div className="left hide-on-med-and-down" style={{ marginLeft: "1.85em" }}>
                        <a onClick={toglearSide} style={{ color: Paleta.textoSecundario, cursor: "pointer" }} ><i style={{ fontSize: "2em" }} className="material-icons left botonFlat">menu</i></a>
                    </div>
                    <a style={{ fontWeight: "500", fontSize: "1.5em", color: Paleta.textoSecundario, paddingRight: "1.5rem", paddingLeft: "1.5em", fontFamily: "Montserrat" }} className={`brand-logo center ${styles.miTitulo}`}>{linkActivo}</a>
                    <a style={{ color: Paleta.textoSecundario, cursor: "pointer" }} data-target="mobile-demo" className="sidenav-trigger botonFlat"><i className="material-icons">menu</i></a>
                    <div className={`right hide-on-med-and-down`} style={{ height: "100%", paddingLeft: "1rem", paddingRight: "1rem", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <ItemsHeader />
                    </div>


                </div>
            </nav>

            <ul className="sidenav" id="mobile-demo" style={{ backgroundColor: Paleta.colorPrimario, zIndex: "9999" }}>
                <HeaderVinculo cerrarSide={cerrarSide} lugar="side" />
                {/* <VinculoSeparador /> */}
                <SideVinculos cerrar={cerrarSide} tipo="side" />
                {/* <VinculoSeparador /> */}
                {/* <ContenedorLogo /> */}
            </ul>
        </>
    );
}

export default BarraNavegacion;