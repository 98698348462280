import React, { useEffect, useState } from 'react';
import MiniLoaderWrap from '../../../../componentes/MiniLoaderWrap/MiniLoaderWrap';
import { getDatos, mostrarError, sleepear } from '../../../../librerias/funciones';
import EntradaPermiso from './EntradaPermiso';

const ListarPermisos = ({ permisos }) => {
    if (permisos.length == 0) {
        return (
            <p style={{ color: "rgb(117, 117, 117)", fontFamily: "Montserrat" }}>No tiene permisos registrados</p>
        );
    }
    return permisos.map((_Permiso, _Key) => {
        return (
            <EntradaPermiso key={_Permiso.IDPermiso} datos={_Permiso} />
        );
    })
}

const ModalPermisos = ({ dpi }) => {

    const [cargando, setCargando] = useState(true)
    const [permisos, setPermisos] = useState([])

    const cargarDatos = async () => {

        if (dpi == null) return;

        setCargando(true);

        let datos;

        while (true) {
            datos = await getDatos(`/admin/permission/${dpi}`);
            if (datos != null) break;
            await sleepear(5000);
        }

        setCargando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setPermisos(datos.Mensaje.reverse())

    }

    useEffect(() => {
        cargarDatos();
    }, [dpi])

    return (
        <MiniLoaderWrap cargando={cargando}>
            <div className="row" style={{ margin: 0 }}>
                <div className="col s12" style={{ fontSize: "1.3em", padding: 0 }}>Listado de Permisos</div>
                <ListarPermisos permisos={permisos} />
            </div>
        </MiniLoaderWrap>
    );
}

export default ModalPermisos;