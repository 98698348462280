import React, { useEffect, useState, useRef } from 'react';
import DynamicForm from '../../componentes/DynamicForm/DynamicForm';
import FullModal from '../../componentes/FullModal/FullModal';
import LoadWrap from '../../componentes/LoadWrap/LoadWrap';
import { mostrarError, propiedadVacia, sleepear, getDatos, generarVinculoReportes,propiedadVaciaForm } from '../../librerias/funciones';
import { Datos } from '../../librerias/sistema';


const Reportes = () => {

    const [cargando, setCargando] = useState(true)
    const [estadoModal, setEstadoModal] = useState(false)
    const [actualizando, setActualizando] = useState(false)
    const [tipo, setTipo] = useState(0)
    const [proyectos, setProyectos] = useState([])
    const [personas, setPersonas] = useState([])
    const [formularios, setFormularios] = useState([])
    const [parametros, setParametros] = useState({})


    const cargarDatosFormulario = async () => {
        let datos
        while (true) {
            datos = await getDatos('/form');
            if (datos != null) break;
            await sleepear(5000);
        }
        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return false;
        }

        let temp = [];
        datos.Mensaje.forEach(e => {
            temp.push({ 'opcion': e.Descripcion, 'id': e.IDFormulario });
        });
        setFormularios(temp)

        return true
    }

    const cargarDatosProyecto = async () => {
        let datos;

        while (true) {
            datos = await getDatos('/admin/project');
            if (datos != null) break;
            await sleepear(5000);
        }
        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return false;
        }

        let temp = [];
        datos.Data.forEach(e => {
            temp.push({ 'opcion': e.Proyecto, 'id': e.ProyectoID });
        });
        setProyectos(temp)

        return true;
    }

    const cargarDatosPersonas = async () => {
        let datos;

        while (true) {
            datos = await getDatos('/personWeak');
            if (datos != null) break;
            await sleepear(5000);
        }
        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return false;
        }

        let temp = []
        datos.Mensaje.forEach(e => {
            temp.push({ 'opcion': e.opcion, 'id': e.id });
        });
        setPersonas(temp)

        return true;
    }

    const cargarDatos = async () => {

        setCargando(true)

        const resultados = await Promise.all([
            cargarDatosPersonas(),
            cargarDatosProyecto(),
            cargarDatosFormulario(),
        ])

        for (let i = 0; i < resultados.length; i++) {
            if (resultados[i] == false) return;
        }

        setCargando(false)
    }

    useEffect(() => {
        cargarDatos();
    }, [])

    const cerrarModal = () => {
        setEstadoModal(false);
    }

    const generarReporte = () => {
        switch (parseInt(tipo)) {
            case 1:
                if(propiedadVaciaForm(parametros,["dpi"])){
                    mostrarError("No seleccionó todos los datos")
                    return
                }
                window.open(generarVinculoReportes('logs/' + parametros.dpi))
                break
            case 2:
                if(propiedadVaciaForm(parametros,["dpi","formulario"])){
                    mostrarError("No seleccionó todos los datos")
                    return
                }
                window.open(generarVinculoReportes('evaluation/' + parametros.dpi + '/' + parametros.formulario))
                break
            case 3:
                if(propiedadVaciaForm(parametros,["formulario"])){
                    mostrarError("No seleccionó todos los datos")
                    return
                }
                window.open(generarVinculoReportes('evaluationSummary/' + parametros.formulario))
                break
            case 4:
                if(propiedadVaciaForm(parametros,["proyecto"])){
                    mostrarError("No seleccionó todos los datos")
                    return
                }
                window.open(generarVinculoReportes('project/' + parametros.proyecto))
                break
            case 5:
                window.open(generarVinculoReportes('noActivePerson/'))
                break
            case 6:
                if(propiedadVaciaForm(parametros,["dpi","formulario"])){
                    mostrarError("No seleccionó todos los datos")
                    return
                }
                window.open(generarVinculoReportes('allEvaluation/' + parametros.dpi + '/' + parametros.formulario))
                break
            default:

                break
        }
    }

    const obtenerPersona = (_Dato) => {
        let temp = parametros;
        temp.dpi = _Dato
        setParametros(temp)
    }

    const obtenerFormluario = (_Dato) => {
        let temp = parametros;
        temp.formulario = _Dato
        setParametros(temp)
    }

    const obtenerProyecto = (_Dato) => {
        let temp = parametros;
        temp.proyecto = _Dato
        setParametros(temp)
    }


    return (
        <LoadWrap cargando={cargando} titulo="Reportes">

            <div style={{ paddingLeft: "0.75rem", paddingRight: "0.75rem" }}>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", marginBottom: "2em" }}>
                    <p style={{ marginTop: 0, marginBottom: 0, marginRight: "1em", color: "#333431", fontFamily: "Montserrat", fontSize: "2em", fontWeight: "500" }}>Generación de reportes</p>
                    {/* <a onClick={() => { }} style={{ marginTop: "0.5em", marginBottom: "0.5em", display: "block", backgroundColor: "#0190ee", borderRadius: "2em" }} className="waves-effect waves-light btn"><i style={{ marginRight: "0.25em" }} className="material-icons left">add</i>Agregar</a> */}
                    {/* abrirModal("Vista") */}
                </div>

                {/* <div className="miniSombra" style={{ padding: "0.75em", margin: 0, marginTop: "1em", backgroundColor: "white", borderRadius: "0.5em" }}>
                    <ListarPuestos />
                </div> */}

                <div className="miniSombra" style={{ padding: "1em", backgroundColor: "white", borderRadius: "0.3em" }}>
                    <DynamicForm
                        titulo="Seleccione un reporte"
                        editable={true}
                        actualizando={actualizando}
                        botonOK={{ titulo: "Generar", press: generarReporte }}
                        noCancel
                        noOK
                        secciones={
                            [
                                {
                                    seccion: "",
                                    campos: [
                                        {
                                            campo: "Reporte",
                                            identificador: "reporte",
                                            inicial: "-1",
                                            default: "Elegir reporte",
                                            tipo: "select",
                                            opciones: [
                                                { id: 1, opcion: 'Resumen laboral' },
                                                { id: 2, opcion: 'Resumen individual de evaluaciones' },
                                                { id: 3, opcion: 'Consolidado de evaluaciones' },
                                                { id: 4, opcion: 'Reporte de proyecto' },
                                                { id: 5, opcion: 'Reporte de excolaboradores' },
                                                { id: 6, opcion: 'Evaluaciones de un colaborador' },

                                            ],
                                            onChange: (a) => { setTipo(a) },
                                            tamaño: "s12"
                                        },
                                    ]
                                }
                            ]
                        }
                    />
                    {
                        tipo == 4 ?
                            <DynamicForm
                                titulo="Seleccione un proyecto"
                                editable={true}
                                actualizando={actualizando}
                                botonOK={{ titulo: "Generar", press: generarReporte }}
                                noCancel
                                noOK
                                secciones={
                                    [
                                        {
                                            seccion: "",
                                            campos: [
                                                {
                                                    campo: "Proyecto",
                                                    identificador: "proyecto",
                                                    inicial: "-1",
                                                    default: "Elegir Proyecto",
                                                    tipo: "select",
                                                    opciones: proyectos,
                                                    onChange: (a) => { obtenerProyecto(a) },
                                                    tamaño: "s12"
                                                },
                                            ]
                                        }
                                    ]
                                }
                            /> : <></>
                    }
                    {
                        tipo == 1 || tipo == 2 || tipo == 6 ?
                            <DynamicForm
                                titulo="Seleccione una persona"
                                editable={true}
                                actualizando={actualizando}
                                botonOK={{ titulo: "Generar", press: generarReporte }}
                                noCancel
                                noOK
                                secciones={
                                    [
                                        {
                                            seccion: "",
                                            campos: [
                                                {
                                                    campo: "Persona",
                                                    identificador: "dpi",
                                                    inicial: "-1",
                                                    default: "Elegir persona",
                                                    tipo: "select",
                                                    opciones: personas,
                                                    onChange: (a) => { obtenerPersona(a) },
                                                    tamaño: "s12"
                                                },
                                            ]
                                        }
                                    ]
                                }
                            /> : <></>
                    }
                    {
                        tipo == 2 || tipo == 3 || tipo == 6 ?
                            <DynamicForm
                                titulo="Seleccione un Formulario"
                                editable={true}
                                actualizando={actualizando}
                                botonOK={{ titulo: "Generar", press: generarReporte }}
                                noCancel
                                noOK
                                secciones={
                                    [
                                        {
                                            seccion: "",
                                            campos: [
                                                {
                                                    campo: "Formulario",
                                                    identificador: "formluario",
                                                    inicial: "-1",
                                                    default: "Elegir Formulario",
                                                    tipo: "select",
                                                    opciones: formularios,
                                                    onChange: (a) => { obtenerFormluario(a) },
                                                    tamaño: "s12"
                                                },
                                            ]
                                        }
                                    ]
                                }
                            /> : <></>
                    }
                    <a onClick={generarReporte} style={{ fontWeight: "500", backgroundColor: "#3f92ba", marginLeft: "0.5em" }} className="waves-effect waves-light btn z-depth-0">Generar Reporte</a>
                </div>

            </div>

            <FullModal
                abierto={estadoModal}
                cerrar={cerrarModal}
            >
                {/* <SelectorVista vista={vista} /> */}
            </FullModal>

        </LoadWrap>
    );
}

export default Reportes;