import React, { useState, useEffect } from 'react';
import BarraIconos from '../../../../componentes/BarraIconos.js/BarraIconos';
import MensajeNuHay from '../../../../componentes/MensajeNuHay/MensajeNuHay';
import MiniLoaderWrap from '../../../../componentes/MiniLoaderWrap/MiniLoaderWrap';
import Separador from '../../../../componentes/Separador/Separador';
import { deleteDatos, formatearNombre, generarVinculoFoto, getDatos, insertDatos, mostrarError, sleepear } from '../../../../librerias/funciones';

const EntradaPersona = ({ persona, asignado, cambiar }) => {

    const [valor, setValor] = useState(persona.Asignado)
    const [actualizando, setActualizando] = useState(false)

    const cambiarValor = () => {

        if (actualizando) return;
        setActualizando(true)

        const nuevoValor = !valor;
        setValor(nuevoValor)

        cambiar(persona, nuevoValor, (_OK) => {
            setActualizando(false);
            if (_OK) setValor(nuevoValor)
            else setValor(!nuevoValor)
        })
    }

    return (
        <div style={{ marginTop: "0.5em", marginBottom: "0.5em", display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
            <img className="z-depth-1" src={generarVinculoFoto(persona.Foto)} alt="" width="60em" height="60em" style={{ backgroundColor: "rgb(184, 183, 180)", borderRadius: "30em", marginRight: "2em", objectFit: "cover" }} />
            <div style={{ flex: 1 }}>
                <p style={{ margin: 0, fontWeight: "400", fontSize: "1.1em" }}>{formatearNombre(`${persona.Nombre},${persona.Apellido}`)}</p>
            </div>
            {asignado ?
                !persona.Estado ?
                    <label style={{ paddingTop: "0.5em" }}>
                        <input checked={valor} onChange={cambiarValor} disabled={actualizando ? "disabled" : ""} type="checkbox" style={{ position: "unset" }} />
                        <span></span>
                    </label>
                    :
                    <ion-icon style={{ color: '#10ac84', fontSize: "1.7em", marginRight: "0.58em" }} name="checkbox-outline" />
                :
                null
            }
        </div>
    );
}

const ListarPersonas = ({ personas, asignado, mensaje, cambiar }) => {
    if (personas.length == 0) return <MensajeNuHay mensaje={mensaje} />

    return personas.map((_Persona) => {
        return (
            <EntradaPersona key={_Persona.IDPersona} persona={_Persona} asignado={asignado} cambiar={cambiar} />
        )
    })
}

const Evaluados = ({ bloque, evaluador, regresar }) => {

    const [personas, setPersonas] = useState([])
    const [cargando, setCargando] = useState(false)

    const cargarDatos = async () => {

        setCargando(true)

        let datos;

        while (true) {
            datos = await getDatos(`/evaluation/${bloque.IDFormulario}/${evaluador.IDPersona}`);
            if (datos != null) break;
            await sleepear(5000);
        }

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setPersonas(datos.Mensaje)

        setCargando(false)
    }

    useEffect(() => {
        cargarDatos();
    }, [])

    const asignarPersona = async (_Persona, _Valor, _Completado) => {

        if (_Valor) {
            const form = new FormData();
            form.append("Formulario", bloque.IDFormulario)
            form.append("Evaluador", evaluador.IDPersona)
            form.append("Evaluado", _Persona.IDPersona)

            let datos;

            while (true) {
                datos = await insertDatos(form, `/evaluation`);
                if (datos != null) break;
                await sleepear(5000);
            }

            if (datos.Error) {
                _Completado(false)
                mostrarError(datos.Mensaje);
                return;
            }

            const nuevasPersonas = [...personas];
            for (let i = 0; i < nuevasPersonas.length; i++) {
                if (nuevasPersonas[i].IDPersona == _Persona.IDPersona) {
                    nuevasPersonas[i].IDEvaluacion = datos.Mensaje.IDEvaluacion
                    break
                }
            }

            setPersonas(nuevasPersonas)

            _Completado(true);
        }

        else {
            let datos;

            while (true) {
                datos = await deleteDatos(_Persona.IDEvaluacion, `/evaluation`);
                if (datos != null) break;
                await sleepear(5000);
            }

            if (datos.Error) {
                _Completado(false)
                mostrarError(datos.Mensaje);
                return;
            }

            _Completado(true)
        }
    }

    return (
        <MiniLoaderWrap cargando={cargando}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ marginRight: "0.5em" }} onClick={regresar} className="noHighLightWrapper botonFlatWrap">
                    <ion-icon className="noHighLight" style={{ cursor: "pointer", color: 'black', fontSize: "1.75em" }} name="arrow-back-outline" />
                </div>
                <p style={{ fontSize: "1.3em", margin: 0 }}>
                    Asignar Evaluados
                </p>
            </div >
            <p style={{ fontSize: "1.3em" }}>Evaluador</p>
            <EntradaPersona persona={evaluador} />
            <p style={{ fontSize: "1.3em" }}>Evaluados</p>
            <ListarPersonas personas={personas} asignado={true} cambiar={asignarPersona} mensaje="No hay personas asignadas" />
        </MiniLoaderWrap>
    );
}

export default Evaluados;