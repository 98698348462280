import React from 'react';
import { formatearNulo } from '../../../librerias/funciones';

const Detalle = ({ campo }) => {
    return (
        <div className={`col input-field s12 m4`} style={{ margin: 0, marginTop: "0.5em" }}>
            <p style={{ margin: 0, color: "#757575", fontWeight: "400" }}>{campo.campo}</p>
            <p style={{ margin: 0, fontFamily: "Montserrat" }}>{formatearNulo(campo.inicial)}</p>
        </div>
    )
}

export default Detalle;