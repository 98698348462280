import React, { useState } from 'react';

import { useGlobal } from 'reactn';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import DashWrap from './componentes/DashWrap/DashWrap';
import TituloPagina from './componentes/TituloPagina/TituloPagina';
import Personas from './vistas/Personas/Personas';
import Inicio from './vistas/Inicio/Inicio';
import Evaluaciones from './vistas/Evaluaciones/Evaluaciones';
import Reclutamiento from './vistas/Reclutamiento/Reclutamiento';
import ModalPerfil from './componentes/ModalPerfil/ModalPerfil';
import LoadingScreen from './componentes/LoadingScreen/LoadingScreen';
import Proyectos from './vistas/Proyectos/Proyectos';
import Reportes from './vistas/Reportes/Reportes';
import ModalAcerca from './componentes/ModalAcerca/ModalAcerca';
import Vista404 from './vistas/404/Vista404';
import { Vinculos } from './librerias/vinculos';
import { permitirVer } from './librerias/funciones';

const Vistas = {
  '/': Inicio,
  '/personas': Personas,
  '/evaluaciones': Evaluaciones,
  '/reclutamiento': Reclutamiento,
  '/proyectos': Proyectos,
  '/reportes': Reportes
}

const ListarPaginas = () => {
  const perfilUsuario = useGlobal('perfilUsuario')[0];

  return Vinculos.map((_Vinculo, _Key) => {

    if (permitirVer(perfilUsuario.Rol, _Vinculo[3]))
      return (
        <Route
          key={_Key}
          path={_Vinculo[1]}
          exact
          component={Vistas[_Vinculo[1]]}
        />
      )

    return <Route
      key={_Key}
      path={_Vinculo[1]}
      exact
      component={Vista404}
    />;
  })
}

function App() {

  const [cargando, setCargando] = useState(true)
  const [opacidad, setOpacidad] = useState(0);

  const [perfilUsuario, setPerfilUsuario] = useGlobal('perfilUsuario');

  const cambiarCargando = (_Datos) => {
    setCargando(false);
    setOpacidad(1)
    setPerfilUsuario(_Datos)
  }

  return (
    cargando ?
      <LoadingScreen cargado={(cambiarCargando)} />
      :
      <div className="animarOpacidad" style={{ opacity: opacidad, display: "flex", height: "100vh", flexDirection: "column", overflow: "hidden" }}>
        <BrowserRouter>
          <DashWrap>
            {/* Inicio */}

            <Switch>
              {/* <Route
                path='/'
                exact
                component={Inicio}
              />

              <Route
                path='/personas'
                exact
                component={Personas}
              />

              <Route
                path='/evaluaciones'
                exact
                component={Evaluaciones}
              />

              <Route
                path='/reclutamiento'
                exact
                component={Reclutamiento}
              />

              <Route
                path='/proyectos'
                exact
                component={Proyectos}
              />

              <Route
                path='/reportes'
                exact
                component={Reportes}
              /> */}

              <ListarPaginas />

              <Route render={Vista404} />

            </Switch>
          </DashWrap>
        </BrowserRouter>

        <ModalPerfil />
        <ModalAcerca />

      </div>
  );
}

export default App;
