import React, { useEffect, useState } from 'react';
import BarraIconos from '../../../componentes/BarraIconos.js/BarraIconos';
import MiniLoaderWrap from '../../../componentes/MiniLoaderWrap/MiniLoaderWrap';
import Separador from '../../../componentes/Separador/Separador';
import { generarVinculoDownload, getDatos, mostrarError, sleepear } from '../../../librerias/funciones';

const EntradaArchivo = ({ archivo, descargar }) => {
    return (
        <>
            <div style={{ paddingTop: "0.5em", paddingBottom: "0.5em", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                <p style={{ flex: 1, color: "rgb(117, 117, 117)", fontWeight: "400" }}>{archivo.Nombre}</p>
                <Separador tamaño="0.5em" orientacion="horizontal" />
                <BarraIconos descargar={() => { descargar(archivo) }} />
                <Separador tamaño="0.5em" orientacion="horizontal" />
            </div>
            <div className="divider"></div>
        </>
    );
}

const ListarArchivos = ({ archivos, descargar }) => {
    if (archivos.length == 0) {
        return (
            <p style={{ color: "rgb(117, 117, 117)", fontFamily: "Montserrat" }}>No tiene archivos registrados</p>
        );
    }
    return archivos.map((_Archivo, _Key) => {
        return <EntradaArchivo
            key={_Archivo.IDArchivo}
            archivo={_Archivo}
            descargar={descargar}
        />
    })
}

const ModalArchivos = ({ dpi }) => {

    const [cargando, setCargando] = useState(true)
    const [archivos, setArchivos] = useState([])

    const cargarDatos = async () => {

        if (dpi == null) return;

        setCargando(true);

        let datos;

        while (true) {
            datos = await getDatos(`/files/${dpi}`);
            if (datos != null) break;
            await sleepear(5000);
        }

        setCargando(false)

        if (datos.Error) {
            mostrarError(datos.Mensaje);
            return;
        }

        setArchivos(datos.Mensaje.reverse())
    }

    const descargarArchivo = (_Archivo) => {
        window.open(generarVinculoDownload(_Archivo.IDArchivo))
    }

    useEffect(() => {
        cargarDatos();
    }, [dpi])

    return (
        <MiniLoaderWrap cargando={cargando}>
            <div className="row" style={{ margin: 0 }}>
                <div className="col s12" style={{ fontSize: "1.3em", padding: 0 }}>Listado de Archivos</div>
                <ListarArchivos
                    archivos={archivos}
                    descargar={descargarArchivo}
                />
            </div>
        </MiniLoaderWrap>
    );
}

export default ModalArchivos;