import { convertirMinutosHora, formatearFecha } from "./funciones"

// Tareas
const df_NuevaTarea = () => {
    return (
        [
            {
                seccion: "",
                campos: [
                    {
                        campo: "Tarea",
                        identificador: "tarea",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12"
                    },
                    {
                        campo: "Desde",
                        identificador: "desde",
                        inicial: "",
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    ,
                    {
                        campo: "Hasta",
                        identificador: "hasta",
                        inicial: "",
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Descripción",
                        identificador: "descripcion",
                        inicial: "",
                        tipo: "area",
                        tamaño: "s12"
                    }
                ]
            }
        ]
    )
}

const df_EditarTarea = (_Datos) => {
    return (
        [
            {
                seccion: "",
                campos: [
                    {
                        identificador: "id",
                        inicial: _Datos.id,
                        tipo: "identificador",
                    },
                    {
                        identificador: "estado",
                        inicial: _Datos.estado,
                        tipo: "identificador",
                    },
                    {
                        campo: "Tarea",
                        identificador: "tarea",
                        inicial: _Datos.tarea,
                        tipo: "text",
                        tamaño: "s12"
                    },
                    {
                        campo: "Desde",
                        identificador: "desde",
                        inicial: _Datos.desde,
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Hasta",
                        identificador: "hasta",
                        inicial: _Datos.hasta,
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Descripción",
                        identificador: "descripcion",
                        inicial: _Datos.descripcion,
                        tipo: "area",
                        tamaño: "s12"
                    }
                ]
            }
        ]
    )
}

const df_NuevoPermiso = () => {
    return (
        [
            {
                seccion: "",
                campos: [
                    {
                        campo: "Desde",
                        identificador: "desde",
                        inicial: "",
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Iniciando",
                        identificador: "iniciando",
                        inicial: "",
                        tipo: "hora",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Hasta",
                        identificador: "hasta",
                        inicial: "",
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Finalizando",
                        identificador: "finalizando",
                        inicial: "",
                        tipo: "hora",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Motivo",
                        identificador: "motivo",
                        inicial: "",
                        tipo: "area",
                        tamaño: "s12"
                    }
                ]
            }
        ]
    );
}

const df_EditarPermiso = ({ id, desde, hasta, iniciando, finalizando, motivo }) => {
    return (
        [
            {
                seccion: "",
                campos: [
                    {
                        identificador: "id",
                        inicial: id,
                        tipo: "identificador",
                    },
                    {
                        campo: "Desde",
                        identificador: "desde",
                        inicial: desde,
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Iniciando",
                        identificador: "iniciando",
                        inicial: iniciando,
                        tipo: "hora",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Hasta",
                        identificador: "hasta",
                        inicial: hasta,
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Finalizando",
                        identificador: "finalizando",
                        inicial: finalizando,
                        tipo: "hora",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Motivo",
                        identificador: "motivo",
                        inicial: motivo,
                        tipo: "area",
                        tamaño: "s12"
                    }
                ]
            }
        ]
    );
}


const df_NuevaBitacora = (_Proyectos) => {
    return (
        [
            {
                seccion: "",
                campos: [
                    {
                        campo: "Proyecto",
                        identificador: "proyecto",
                        inicial: "-1",
                        default: "Elegir Proyecto",
                        tipo: "select",
                        opciones: _Proyectos,
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Días",
                        identificador: "dias",
                        inicial: [],
                        tipo: "dias",
                        tamaño: "s12"
                    },
                    {
                        campo: "Notas",
                        identificador: "notas",
                        inicial: "",
                        tipo: "area",
                        tamaño: "s12"
                    }
                ]
            }
        ]
    );
}

const df_EditarBitacora = (_Proyectos, _Datos) => {

    const nuevosDias = [];
    _Datos.dias.forEach(_Dia => {
        nuevosDias.push({
            fecha: _Dia.Fecha,
            hora: convertirMinutosHora(_Dia.Minutos),
            inasistencia: _Dia.Inasistencia
        })
    });

    return (
        [
            {
                seccion: "",
                campos: [
                    {
                        identificador: "bitacora",
                        inicial: _Datos.bitacora,
                        tipo: "identificador",
                    },
                    {
                        campo: "Proyecto",
                        identificador: "proyecto",
                        inicial: _Datos.proyecto,
                        default: "Elegir Proyecto",
                        tipo: "select",
                        opciones: _Proyectos,
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Días",
                        identificador: "dias",
                        inicial: nuevosDias,
                        tipo: "dias",
                        tamaño: "s12"
                    },
                    {
                        campo: "Notas",
                        identificador: "notas",
                        inicial: _Datos.notas,
                        tipo: "area",
                        tamaño: "s12"
                    }
                ]
            }
        ]
    );
}

const df_NuevaPersona = (_Select) => {
    return (
        [
            {
                seccion: "",
                campos: [
                    {
                        campo: "Nombres",
                        identificador: "nombre",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Apellidos",
                        identificador: "apellido",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "DPI",
                        identificador: "dpi",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "NIT",
                        identificador: "nit",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Profesión",
                        identificador: "profesion",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Puesto",
                        identificador: "puesto",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Licencia",
                        identificador: "licencia",
                        inicial: "",
                        tipo: "area",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Cuenta Bancaria",
                        identificador: "banco",
                        inicial: "",
                        tipo: "area",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Teléfono",
                        identificador: "telefono",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Ingreso",
                        identificador: "ingreso",
                        inicial: "",
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Estado",
                        identificador: "estado",
                        inicial: "-1",
                        default: "Estado",
                        tipo: "select",
                        opciones: _Select.Estados,
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Nacimiento",
                        identificador: "nacimiento",
                        inicial: "",
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Correo",
                        identificador: "correo",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Dirección",
                        identificador: "direccion",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Sexo",
                        identificador: "sexo",
                        inicial: "-1",
                        default: "Sexo",
                        tipo: "select",
                        opciones: _Select.Generos,
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Móvil",
                        identificador: "movil",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "No. IGSS",
                        identificador: "igss",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Estado Civil",
                        identificador: "civil",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Madre",
                        identificador: "madre",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Padre",
                        identificador: "padre",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Cónyuge",
                        identificador: "conyuge",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Sangre",
                        identificador: "sangre",
                        inicial: "-1",
                        default: "Sangre",
                        tipo: "select",
                        opciones: _Select.Sangre,
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Enfermedades",
                        identificador: "enfermedades",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Medicamentos Alérgicos",
                        identificador: "medicamentos",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Contacto de Emergencia",
                        identificador: "emergencia",
                        inicial: "",
                        tipo: "area",
                        tamaño: "s12 m6"
                    },
                ]
            }
        ]
    )
}

const df_EditarPersona = (_Datos, _Select) => {
    return (
        [
            {
                seccion: "",
                campos: [
                    {
                        identificador: "_dpi",
                        inicial: _Datos.DPI,
                        tipo: "identificador",
                    },
                    {
                        identificador: "persona",
                        inicial: _Datos.IDPersona,
                        tipo: "identificador",
                    },
                    {
                        campo: "Nombres",
                        identificador: "nombre",
                        inicial: _Datos.Nombre,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Apellidos",
                        identificador: "apellido",
                        inicial: _Datos.Apellido,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "DPI",
                        identificador: "dpi",
                        inicial: _Datos.DPI,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "NIT",
                        identificador: "nit",
                        inicial: _Datos.NIT,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Profesión",
                        identificador: "profesion",
                        inicial: _Datos.ProfesionDiversificado,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Puesto",
                        identificador: "puesto",
                        inicial: _Datos.Cargo,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Licencia",
                        identificador: "licencia",
                        inicial: _Datos.Licencia,
                        tipo: "area",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Cuenta Bancaria",
                        identificador: "banco",
                        inicial: _Datos.NoCuentaBanco,
                        tipo: "area",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Teléfono",
                        identificador: "telefono",
                        inicial: _Datos.Telefono,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Estado",
                        identificador: "estado",
                        inicial: _Datos.IDEstado,
                        default: "Estado",
                        tipo: "select",
                        opciones: _Select.Estados,
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Ingreso",
                        identificador: "ingreso",
                        inicial: _Datos.Ingreso,
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Egreso",
                        identificador: "egreso",
                        inicial: _Datos.Egreso,
                        tipo: "fecha",
                        tamaño: "s12 m6",
                        nulo: true
                    },
                    {
                        campo: "Nacimiento",
                        identificador: "nacimiento",
                        inicial: _Datos.FechaNac,
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Correo",
                        identificador: "correo",
                        inicial: _Datos.Correo,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Dirección",
                        identificador: "direccion",
                        inicial: _Datos.Direccion,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Sexo",
                        identificador: "sexo",
                        inicial: _Datos.IDSexo,
                        default: "Estado",
                        tipo: "select",
                        opciones: _Select.Generos,
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Móvil",
                        identificador: "movil",
                        inicial: _Datos.TelefonoMovil,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "No. IGSS",
                        identificador: "igss",
                        inicial: _Datos.NoAfiliacionIgss,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Estado Civil",
                        identificador: "civil",
                        inicial: _Datos.EstadoCivil,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Madre",
                        identificador: "madre",
                        inicial: _Datos.NombreMadre,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Padre",
                        identificador: "padre",
                        inicial: _Datos.NombrePadre,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Cónyuge",
                        identificador: "conyuge",
                        inicial: _Datos.NombreConyuge,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Sangre",
                        identificador: "sangre",
                        inicial: _Datos.IDTipoSangre,
                        default: "Sangre",
                        tipo: "select",
                        opciones: _Select.Sangre,
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Enfermedades",
                        identificador: "enfermedades",
                        inicial: _Datos.Enfermedades,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Medicamentos Alérgicos",
                        identificador: "medicamentos",
                        inicial: _Datos.MedicamentosAlergico,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Contacto de Emergencia",
                        identificador: "emergencia",
                        inicial: _Datos.TelefonoEmergencia,
                        tipo: "area",
                        tamaño: "s12 m6"
                    },
                ]
            }
        ]
    )
}

const df_DetallePersona = (_Datos) => {
    return (
        [
            {
                seccion: "",
                campos: [
                    {
                        campo: "Nombres",
                        identificador: "nombre",
                        inicial: _Datos.Nombre,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Apellidos",
                        identificador: "apellido",
                        inicial: _Datos.Apellido,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "DPI",
                        identificador: "dpi",
                        inicial: _Datos.DPI,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "NIT",
                        identificador: "nit",
                        inicial: _Datos.NIT,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Profesión",
                        identificador: "profesion",
                        inicial: _Datos.ProfesionDiversificado,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Puesto",
                        identificador: "puesto",
                        inicial: _Datos.Cargo,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Teléfono",
                        identificador: "telefono",
                        inicial: _Datos.Telefono,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Ingreso",
                        identificador: "ingreso",
                        inicial: formatearFecha(_Datos.Ingreso),
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Egreso",
                        identificador: "egreso",
                        inicial: formatearFecha(_Datos.Egreso),
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Estado",
                        identificador: "estado",
                        inicial: _Datos.Estado,
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Nacimiento",
                        identificador: "nacimiento",
                        inicial: formatearFecha(_Datos.FechaNac),
                        tipo: "fecha",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Correo",
                        identificador: "correo",
                        inicial: _Datos.Correo,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Dirección",
                        identificador: "direccion",
                        inicial: _Datos.Direccion,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Sexo",
                        identificador: "sexo",
                        inicial: _Datos.Sexo,
                        default: "Estado",
                        tipo: "select",
                        opciones: [{ opcion: "Masculino", id: 1 }, { opcion: "Femenino", id: 2 }],
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Móvil",
                        identificador: "movil",
                        inicial: _Datos.TelefonoMovil,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "IGSS",
                        identificador: "igss",
                        inicial: _Datos.NoAfiliacionIgss,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Licencia",
                        identificador: "licencia",
                        inicial: _Datos.Licencia,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Estado Civil",
                        identificador: "civil",
                        inicial: _Datos.EstadoCivil,
                        default: "Estado Civil",
                        tipo: "select",
                        opciones: [{ opcion: "Soltero", id: 1 }, { opcion: "Casado", id: 2 }],
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Madre",
                        identificador: "madre",
                        inicial: _Datos.NombreMadre,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Padre",
                        identificador: "padre",
                        inicial: _Datos.NombrePadre,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Cónyuge",
                        identificador: "conyuge",
                        inicial: _Datos.NombreConyuge,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Contacto de Emergencia",
                        identificador: "emergencia",
                        inicial: _Datos.TelefonoEmergencia,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Sangre",
                        identificador: "sangre",
                        inicial: _Datos.Tipo,
                        default: "Sangre",
                        tipo: "select",
                        opciones: [{ opcion: "A", id: 1 }, { opcion: "ORH+", id: 2 }],
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Enfermedades",
                        identificador: "enfermedades",
                        inicial: _Datos.Enfermedades,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Medicamentos Alérgicos",
                        identificador: "medicamentos",
                        inicial: _Datos.MedicamentosAlergico,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                    {
                        campo: "Cuenta Bancaria",
                        identificador: "banco",
                        inicial: _Datos.NoCuentaBanco,
                        tipo: "text",
                        tamaño: "s12 m6"
                    },
                ]
            }
        ]
    )
}

// Usuarios
const df_NuevoUsuario = (_DPI, _Roles) => {
    return (
        [
            {
                seccion: "",
                campos: [
                    {
                        identificador: "dpi",
                        inicial: _DPI,
                        tipo: "identificador",
                    },
                    {
                        campo: "Usuario",
                        identificador: "usuario",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12"
                    },
                    {
                        campo: "Rol",
                        identificador: "rol",
                        inicial: "-1",
                        default: "Elegir Rol",
                        tipo: "select",
                        opciones: _Roles,
                        tamaño: "s12"
                    },
                    // {
                    //     campo: "Habilitado",
                    //     identificador: "activo",
                    //     inicial: true,
                    //     tipo: "switch",
                    //     tamaño: "s2"
                    // }
                ]
            }
        ]
    )
}
const df_EditarUsuario = (_DPI, _Usuario, _Estado, _Rol, _Roles) => {
    return (
        [
            {
                seccion: "",
                campos: [
                    {
                        identificador: "dpi",
                        inicial: _DPI,
                        tipo: "identificador",
                    },
                    {
                        campo: "Usuario",
                        identificador: "usuario",
                        inicial: _Usuario,
                        tipo: "text",
                        tamaño: "s9"
                    },
                    {
                        campo: "Habilitado",
                        identificador: "activo",
                        inicial: _Estado,
                        tipo: "switch",
                        tamaño: "s3"
                    },
                    {
                        campo: "Rol",
                        identificador: "rol",
                        inicial: _Rol,
                        default: "Elegir Rol",
                        tipo: "select",
                        opciones: _Roles,
                        tamaño: "s12"
                    },
                ]
            }
        ]
    )
}

// Bloques de aptitudes
const df_NuevoBloque = (_Campo, _Identificador) => {
    return (
        [
            {
                seccion: "",
                campos: [
                    // {
                    //     identificador: "dpi",
                    //     inicial: _DPI,
                    //     tipo: "identificador",
                    // },
                    {
                        campo: _Campo,
                        identificador: _Identificador,
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12"
                    },
                    // {
                    //     campo: "Habilitado",
                    //     identificador: "activo",
                    //     inicial: true,
                    //     tipo: "switch",
                    //     tamaño: "s2"
                    // }
                ]
            }
        ]
    )
}
const df_EditarBloqueAptitud = (_Bloque) => {
    return (
        [
            {
                seccion: "",
                campos: [
                    {
                        identificador: "id",
                        inicial: _Bloque.IDBloque,
                        tipo: "identificador",
                    },
                    {
                        campo: "Bloque",
                        identificador: "bloque",
                        inicial: _Bloque.Nombre,
                        tipo: "text",
                        tamaño: "s12"
                    },
                    // {
                    //     campo: "Habilitado",
                    //     identificador: "activo",
                    //     inicial: true,
                    //     tipo: "switch",
                    //     tamaño: "s2"
                    // }
                ]
            }
        ]
    )
}
const df_NuevaAptitud = () => {
    return (
        [
            {
                seccion: "",
                campos: [
                    // {
                    //     identificador: "dpi",
                    //     inicial: _DPI,
                    //     tipo: "identificador",
                    // },
                    {
                        campo: "Aptitud",
                        identificador: "aptitud",
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12"
                    },
                    // {
                    //     campo: "Habilitado",
                    //     identificador: "activo",
                    //     inicial: true,
                    //     tipo: "switch",
                    //     tamaño: "s2"
                    // }
                ]
            }
        ]
    )
}

// Evaluaciones
const df_EditarBloque = (_Campo, _Identificador) => {
    return (
        [
            {
                seccion: "",
                campos: [
                    // {
                    //     identificador: "dpi",
                    //     inicial: _DPI,
                    //     tipo: "identificador",
                    // },
                    {
                        campo: _Campo,
                        identificador: _Identificador,
                        inicial: "",
                        tipo: "text",
                        tamaño: "s12"
                    },
                    // {
                    //     campo: "Habilitado",
                    //     identificador: "activo",
                    //     inicial: true,
                    //     tipo: "switch",
                    //     tamaño: "s2"
                    // }
                ]
            }
        ]
    )
}

const df_EditarEvaluacion = (_ID, _Descripcion, _Estado) => {
    return (
        [
            {
                seccion: "",
                campos: [
                    {
                        identificador: "id",
                        inicial: _ID,
                        tipo: "identificador",
                    },
                    {
                        campo: "Descripción",
                        identificador: "descripcion",
                        inicial: _Descripcion,
                        tipo: "text",
                        tamaño: "s9"
                    },
                    {
                        campo: "Habilitado",
                        identificador: "activo",
                        inicial: _Estado,
                        tipo: "switch",
                        tamaño: "s3"
                    }
                ]
            }
        ]
    )
}

export {
    // Tareas
    df_EditarTarea,
    df_NuevaTarea,

    // Permisos
    df_NuevoPermiso,
    df_EditarPermiso,
    df_NuevaBitacora,
    df_EditarBitacora,

    // Personas
    df_NuevaPersona,
    df_DetallePersona,
    df_EditarPersona,

    // Usuarios
    df_NuevoUsuario,
    df_EditarUsuario,

    // Bloques de aptitudes
    df_NuevoBloque,
    df_EditarBloqueAptitud,
    df_NuevaAptitud,

    // Evaluacion
    df_EditarBloque,
    df_EditarEvaluacion
}