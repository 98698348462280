import React, { useState, useRef, useEffect } from 'react';
import { fechaStringPHP, formatearFechaPHP, formatearHoraPHP } from '../../../librerias/funciones';
import Cheque from './Cheque';
import Fecha from './Fecha';
import Hora from './Hora';

const EntradaDia = ({ dia, contenedor, indice, eliminar, actualizar, campoFecha, campoHora, campoCheque }) => {

    const datosDia = useRef({});

    const actualizarDia = () => {
        const nuevosDatos = {
            hora: typeof datosDia.current.hora == "object" ? formatearHoraPHP(datosDia.current.hora) : datosDia.current.hora,
            fecha: typeof datosDia.current.fecha == "object" ? formatearFechaPHP(datosDia.current.fecha) : datosDia.current.fecha,
            inasistencia: datosDia.current.inasistencia,
            _id: indice
        }
        actualizar(indice, nuevosDatos);
    }

    const actualizarFecha = (_Datos) => {
        datosDia.current.fecha = _Datos;
        actualizarDia();
    }
    const actualizarHora = (_Datos) => {
        datosDia.current.hora = _Datos;
        actualizarDia();
    }

    const actualizarInasistencia = (_Datos) => {
        datosDia.current.inasistencia = _Datos;
        actualizarDia();
    }

    const eliminarDia = () => {
        eliminar(indice);
    }

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
                <div style={{ margin: 0, padding: 0 }} className="row">
                    <Fecha nopad actualizar={actualizarFecha} campo={campoFecha} contenedor={contenedor} datos={datosDia} />
                    <Hora nopad actualizar={actualizarHora} campo={campoHora} contenedor={contenedor} datos={datosDia} />
                    <Cheque actualizar={actualizarInasistencia} campo={campoCheque} datos={datosDia} />
                </div>
            </div>
            <div onClick={eliminarDia} className="noHighLightWrapper botonFlatWrap">
                <ion-icon className="noHighLight" style={{ marginTop: "1.1em", marginLeft: "0.25em", cursor: "pointer", color: 'rgb(243, 91, 38)', fontSize: "1.5em" }} name="trash-outline" />
            </div>
        </div>
    );
}

const ListarDias = ({ dias, contenedor, eliminar, actualizar }) => {
    return (
        dias.map((_Dia, _Key) => {
            const campoFecha = {
                inicial: _Dia.fecha,
                tamaño: "s12 m6",
                campo: "Fecha",
                identificador: "fecha"
            }
            const campoHora = {
                inicial: _Dia.hora,
                tamaño: "s8 m4",
                campo: "Horas Trabajadas",
                identificador: "hora"
            }
            const campoCheque = {
                inicial: _Dia.inasistencia,
                tamaño: "s4 m2",
                campo: "Inasistencia",
                identificador: "inasistencia"
            }
            return (
                <EntradaDia key={_Dia._id} campoCheque={campoCheque} campoFecha={campoFecha} campoHora={campoHora} eliminar={eliminar} actualizar={actualizar} indice={_Dia._id} dia={_Dia} contenedor={contenedor} />
            )
        })
    )
}

const Dias = ({ campo, datos, contenedor }) => {
    const [valor, setValor] = useState([])
    datos.current[campo.identificador] = valor;
    const [id, setID] = useState(0);

    useEffect(() => {
        const nuevoValor = campo.inicial;
        for (let i = 0; i < nuevoValor.length; i++) {
            nuevoValor[i]._id = i;
        }
        setID(campo.inicial.length - 1);
        setValor(nuevoValor)
    }, [])

    const agregarDia = () => {
        const nuevoValor = [...valor];
        const nuevoID = id + 1;
        nuevoValor.push({ fecha: fechaStringPHP(), hora: "00:00", inasistencia: false, _id: nuevoID });
        setValor(nuevoValor)
        setID(nuevoID);
    }

    const actualizarDia = (_Indice, _Datos) => {
        const nuevoValor = [...valor];

        for (let i = 0; i < nuevoValor.length; i++) {
            if (nuevoValor[i]._id == _Indice) {
                nuevoValor[i] = _Datos;
                break;
            }
        }

        setValor(nuevoValor);
    }

    const eliminarDia = (_Indice) => {
        const nuevoValor = [...valor];

        let indiceEliminar = 0;
        for (let i = 0; i < nuevoValor.length; i++) {
            if (nuevoValor[i]._id == _Indice) {
                indiceEliminar = i;
                break;
            }
        }

        nuevoValor.splice(indiceEliminar, 1);
        setValor(nuevoValor)
    }

    return (
        <div className={`col ${campo.tamaño}`} style={{ margin: 0 }}>
            <div className="row" style={{ margin: 0 }}>
                <div className="col s12" style={{ margin: 0, padding: 0 }}>
                    <p style={{ margin: 0, color: "#757575", fontWeight: "400" }}>{campo.campo}</p>
                    <ListarDias eliminar={eliminarDia} actualizar={actualizarDia} dias={valor} contenedor={contenedor} datos={datos} />
                    <a onClick={agregarDia} className="botonFlat" style={{ display: "inline-block", cursor: "pointer", marginTop: "0.5em" }}>Agregar</a>
                </div>
            </div>
        </div>
    );
}

export default Dias;